// @flow

import * as React from 'react';
import type { FieldRenderProps } from 'react-final-form';

import styles from './InputCheckbox.scss';

type Props = {
  className?: string,
  children: React.Node,
} & FieldRenderProps;

class InputCheckbox extends React.Component<Props> {
  static defaultProps = {
    input: {},
    meta: {},
    children: null,
  };

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(e.target.checked ? 1 : 0);
    }
  };

  render() {
    const { input, meta } = this.props;
    const touched = meta && meta.touched ? meta.touched : false;
    const error = meta && meta.error ? meta.error : false;
    const name = input && input.name ? input.name : '';

    const value = (this.props.input && this.props.input.value) || false;
    return (
      <div>
        <label className={`checkbox ${styles.checkbox}`} htmlFor={name}>
          <input
            name={name}
            id={name}
            type="checkbox"
            className={styles.input}
            onChange={this.onChange}
            checked={value}
          />
          <span className={styles.indicator} />
          <React.Fragment>{this.props.children}</React.Fragment>
        </label>
        {touched && error && <div className="has-text-danger has-text-weight-normal">{error}</div>}
      </div>
    );
  }
}

export default InputCheckbox;
