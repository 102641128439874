import React from 'react';
import PropTypes from 'prop-types';
import { AsyncCreatable } from 'react-select';
import 'react-select/dist/react-select.css';

class WastonSelectCreatableField extends React.Component {
  constructor(props) {
    super(props);

    this.customOptions = {};
  }

  getOptions = input =>
    new Promise((resolve, reject) => {
      this.props.fetchAction(input.trim(), resolve, reject);
    }).then(data => ({ options: [...data.options, ...Object.values(this.customOptions)], complete: true }));

  handleChange = selectedOption => {
    const { label, value, className } = selectedOption || {};

    if (!selectedOption) {
      this.props.input.onChange(null);
    }

    if (!className) {
      return;
    }

    this.customOptions[label] = { label, value };
    this.props.input.onChange(value);
  };

  handleCreatableOptionUnique = e => {
    const { options, option, valueKey, labelKey } = e;
    return !options.some(item => item[labelKey].toLowerCase() === option[valueKey].toLowerCase().trim());
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error },
    } = this.props;

    return (
      <React.Fragment>
        <AsyncCreatable
          name={input.name}
          value={input.value}
          loadOptions={this.getOptions}
          onChange={this.handleChange}
          placeholder={placeholder}
          isOptionUnique={this.handleCreatableOptionUnique}
          cache={false}
          autoload={false}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
        />
        {touched && error && <span className="has-text-danger has-text-weight-normal">{error}</span>}
      </React.Fragment>
    );
  }
}

WastonSelectCreatableField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  fetchAction: PropTypes.func.isRequired,
};

export default WastonSelectCreatableField;
