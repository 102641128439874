/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Async } from 'react-select';

import InputText from 'components/form/inputText';

import styles from './SpecialistsFieldArray.scss';

class SpecialistsFieldArray extends React.Component {
  getOptions = input =>
    new Promise((resolve, reject) => {
      this.props.fetchAction(input, resolve, reject);
    });

  handleChange = selectedOption => {
    if (!selectedOption) return;

    this.props.fields.push({
      specialistId: selectedOption.value,
      name: selectedOption.label,
    });
  };

  render() {
    const { fields } = this.props;

    return (
      <React.Fragment>
        <Async loadOptions={this.getOptions} onChange={this.handleChange} placeholder="Search specialist by name" />
        <ul className={styles.practiceList}>
          {fields.length > 0 && (
            <React.Fragment>
              <li className={styles.header}>
                <div className={styles.position}>Specialist ID</div>
                <div className={styles.name}>Name</div>
                <div className={styles.calendar}>Calendar Id</div>
              </li>
              {fields.map((field, index) => (
                <li key={index}>
                  <div className={styles.position}>{fields.value[index].specialistId}</div>
                  <div className={styles.name}>{fields.value[index].name}</div>
                  <div className={styles.calendar}>
                    <Field component={InputText} name={`${field}.clicrdvCalendarId`} label="Calendar Id" />
                  </div>
                  <button type="button" className="btn-link" onClick={() => fields.remove(index)}>
                    X
                  </button>
                </li>
              ))}
            </React.Fragment>
          )}
        </ul>
      </React.Fragment>
    );
  }
}

SpecialistsFieldArray.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
  }),
  fetchAction: PropTypes.func.isRequired,
};

export default SpecialistsFieldArray;
