// @flow

import config from 'config';
import type { Appointment } from 'types';

const APP_ID = config.name;

export const constants = {
  LOGGED_USER: 'LOGGED_USER',
  POSTER: 'POSTER',
  APPOINTMENT: 'APPOINTMENT',
};

export const setItem = (key: string, data: any) => {
  localStorage.setItem(APP_ID + key, JSON.stringify(data));
};

export const getItem = (key: string, defaultVal: any): any => {
  try {
    // $FlowFixMe
    return JSON.parse(localStorage.getItem(APP_ID + key)) || defaultVal;
  } catch (e) {
    return defaultVal || false;
  }
};

// $FlowFixMe
export const deleteItem = key => {
  localStorage.removeItem(APP_ID + key);
};

export const getUser = () => getItem(constants.LOGGED_USER, {});
// $FlowFixMe
export const setUser = data => setItem(constants.LOGGED_USER, data);
export const deleteUser = () => deleteItem(constants.LOGGED_USER);

export const getAppointment = (): Appointment => getItem(constants.APPOINTMENT, false);
export const setAppointment = (appointment: Appointment) => setItem(constants.APPOINTMENT, appointment);
export const deleteAppointment = () => deleteItem(constants.APPOINTMENT);

export const getPoster = () => getItem(constants.POSTER, {});
// $FlowFixMe
export const savePoster = data => setItem(constants.POSTER, data);
