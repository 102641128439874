// @flow

export { default as searchBar } from 'modules/index/reducer';
export { default as searchResults } from 'modules/searchView/reducer';
export { default as profileView } from 'modules/specialist/reducer';
export { default as practiceView } from 'modules/practice/reducer';
export { default as patient } from 'modules/patient/reducer';
export { default as user } from 'modules/user/reducer';
export { default as common } from 'modules/common/reducer';
export { default as blog } from 'modules/visitor/blog/blogView/reducer';
export { default as blogDetail } from 'modules/visitor/blog/blogDetailView/reducer';
export { default as admin } from 'modules/admin/reducer';
export { default as specialization } from 'modules/specializations/reducer';
export { default as bookingCalendar } from 'modules/bookingCalendar/reducer';
export { default as modal } from 'modules/modal/reducer';
