export default [
  {
    exact: true,
    path: '/',
    component: 'indexView',
  },
  {
    path: '/specialist/:town/:specialization/:slug',
    component: 'specialistView',
  },
  {
    exact: true,
    path: '/specialists/:town/:specialization/:language?',
    component: 'searchView',
  },
  {
    exact: true,
    path: '/specialists',
    component: 'searchView',
  },
  { path: '/press', component: 'pressView' },
  { path: '/calendar', component: 'calendarView' },
  {
    path: '/join-us',
    component: 'joinUsView',
  },
  { path: '/about-us', component: 'aboutUsView' },
  {
    path: '/contact-us',
    component: 'contactUsView',
  },
  { path: '/blog', component: 'blogView' },
  { path: '/blog/:slug', component: 'blogDetailView' },
  {
    path: '/practice/:town/:borough/:slug',
    component: 'practiceView',
  },
  {
    path: '/patient/signup',
    component: 'patientSignupView',
  },
  {
    path: '/patient/login',
    component: 'patientLoginView',
  },
  {
    path: '/patient/reason',
    component: 'patientReasonSelectView',
  },
  {
    path: '/patient/info',
    component: 'patientInfoView',
  },
  {
    path: '/patient/account',
    component: 'patientAccountView',
  },
  {
    path: '/patient/confirm',
    component: 'patientBookingConfirmView',
  },
  {
    path: '/patient/identification',
    component: 'patientIdentificationView',
  },
  /* admin routes */
  {
    path: '/admin/*',
    component: 'adminView',
  },

  /* not found */
  { component: 'notFoundView' },
];
