// @flow

import * as React from 'react';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import ModalCard from 'components/modalCard';
import SpecializationForm from '../specializationForm';

const CreateSpecializationModal = () => (
  <ModalCard title={i18n._(t`Create Specialization`)}>
    <SpecializationForm />
  </ModalCard>
);

CreateSpecializationModal.propTypes = {};

export default CreateSpecializationModal;
