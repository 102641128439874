// @flow

import React from 'react';
import type { FieldRenderProps } from 'react-final-form';

type Props = {
  ...FieldRenderProps,
  label: string,
  type?: string,
  className: string,
  disabled?: boolean,
  readonly: boolean,
};

const InputText = (props: Props) => {
  const { input, label, type, className, readonly, disabled, meta } = props;

  const dirty = meta ? meta.dirty : false;
  const touched = meta ? meta.touched : false;
  const error = meta ? meta.error : false;
  const submitError = meta ? meta.submitError : false;
  const dirtySinceLastSubmit = meta ? meta.dirtySinceLastSubmit : false;

  return (
    <React.Fragment>
      <input
        {...input}
        placeholder={label}
        type={type || 'text'}
        className={`input ${className} ${
          (touched || dirty || readonly) && (error || submitError) && !dirtySinceLastSubmit ? 'is-danger' : ''
        }`}
        readOnly={readonly}
        disabled={disabled}
      />
      {(touched || dirty || readonly) &&
        (error || submitError) &&
        !dirtySinceLastSubmit && <span className="has-text-danger has-text-weight-normal">{error || submitError}</span>}
    </React.Fragment>
  );
};

InputText.defaultProps = {
  className: '',
};

export default InputText;
