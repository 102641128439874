// @flow

import type { ExtractReturn } from 'types';

export const types = {
  BLOG_FETCH: 'BLOG_FETCH',
  BLOG_DETAIL_FETCH: 'BLOG_DETAIL_FETCH',
};

const getBlogs = (keyword: ?string = '', page: ?number = 1) => ({
  type: `${types.BLOG_FETCH}_REQUEST`,
  keyword,
  page,
});

export type GetBlogsActionFlowType = ExtractReturn<typeof getBlogs>;

export type BlogViewActionsFlowType = GetBlogsActionFlowType;

export const actions = {
  getBlogs,
};
