// @flow

import { i18n } from './i18n';

// replace is used because safari doesn't support yyyy-mm-dd
const createDate = (date: string): Date => new Date(Date.parse(date.replace(/-/g, '/')));
export const parseDate = (date: Date | string): Date => {
  let parsed;
  if (!date || date === null) {
    parsed = new Date();
  } else if (typeof date === 'string') {
    parsed = createDate(date);
  } else if (date instanceof Date) {
    parsed = date;
  } else {
    parsed = new Date();
  }

  return parsed;
};

const make2Digits = (number: number): string => `0${number}`.slice(-2);

export const mediumDate = (date: Date | string): string => {
  const dateObject = parseDate(date);
  return i18n.date(dateObject, {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  });
};

export const longDate = (date: Date | string): string => {
  const dateObject = parseDate(date);
  return i18n.date(dateObject, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const time = (date: Date | string): string => {
  const dateObject = parseDate(date);

  return `${make2Digits(dateObject.getHours())}:${make2Digits(dateObject.getMinutes())}`;
};

export const addDay = (date: Date | string, days: number = 0): Date => {
  const parsed = parseDate(date);
  return new Date(parsed.getFullYear(), parsed.getMonth(), parsed.getDate() + days);
};

export const isoDate = (date: Date | string): string => {
  const parsed = parseDate(date);
  return `${parsed.getFullYear()}-${make2Digits(parsed.getMonth() + 1)}-${make2Digits(parsed.getDate())}`;
};

export const utcToLondonISO = (utcTime: string): string => {
  const myTime = new Date(utcTime);
  const options = {
    timeZone: 'Europe/London',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const shiftedTime = new Date(new Intl.DateTimeFormat([], options).format(myTime));
  const isoStr = `${shiftedTime.getFullYear()}-${make2Digits(shiftedTime.getMonth() + 1)}-${make2Digits(
    shiftedTime.getDate()
  )}T${make2Digits(shiftedTime.getHours())}:${make2Digits(shiftedTime.getMinutes())}:${make2Digits(
    shiftedTime.getSeconds()
  )}`;
  return isoStr;
};

export const mysqlDate = (date: Date): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
