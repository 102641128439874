import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Tooltip.scss';

const Tooltip = props => {
  const { position, text, className } = props;
  return (
    <div className={classnames(styles.container, className)}>
      {props.children}
      <span className={classnames(styles.tooltip, styles[position])}>{text}</span>
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'top',
  className: '',
};

Tooltip.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Tooltip;
