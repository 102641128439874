/* eslint flowtype/no-types-missing-file-annotation: 0 */
import * as axios from 'axios';

import { getUser } from 'utils/storage';

// General API constants
export const URL = `${process.env.API_HOST}/api/`;
export const BLOG_URL = 'https://blog.findoc.co.uk';
export const AUTH_HEADER = 'Authorization';

const requestConfig = {
  headers: {
    'Content-Type': 'text/plain',
  },
};

const requestConfigJSON = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const requestConfigFormData = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

function setAuthHeaders(hdr = {}) {
  const headers = hdr;
  const user = getUser();

  if (user.token) {
    headers[AUTH_HEADER] = user.token;
  }

  return headers;
}

//
// API GET
//
export function get(endpoint, query = {}) {
  const config = requestConfig;

  setAuthHeaders(config.headers);

  const promise = new Promise((resolve, reject) => {
    let urlQuery = '';

    if (query) {
      urlQuery = Object.keys(query)
        .filter(key => key && query[key] !== '')
        .map(key => {
          let result = '';
          if (typeof query[key] === 'string') {
            result = `${key}=${encodeURIComponent(query[key])}`;
          } else if (Array.isArray(query[key])) {
            result = query[key].map(value => `${key}=${encodeURIComponent(value)}`).join('&');
          }
          return result;
        })
        .join('&');
      if (urlQuery !== '') {
        urlQuery = `?${urlQuery}`;
      }
    }

    const requestUrl = URL + endpoint + urlQuery;
    axios
      .get(requestUrl, config)
      .then(response => {
        const data = response;
        resolve(data);
      })
      .catch(response => {
        reject(response);
      });
  });

  return promise;
}

//
// API POST
//
export function post(endpoint, body) {
  let config = requestConfig;
  let requestBody = body;

  if (body instanceof FormData) {
    config = Object.assign(config, requestConfigFormData);
  } else if (typeof body === 'object') {
    config = Object.assign(config, requestConfigJSON);
    requestBody = JSON.stringify(body);
  }

  setAuthHeaders(config.headers);

  const promise = new Promise((resolve, reject) => {
    axios
      .post(URL + endpoint, requestBody, config)
      .then(response => {
        const data: any = response;
        resolve(data);
      })
      .catch(response => {
        reject(response);
      });
  });

  return promise;
}

//
// API PATCH
//
export function patch(endpoint, body) {
  let config = requestConfig;
  let requestBody = body;

  if (body instanceof FormData) {
    config = Object.assign(config, requestConfigFormData);
  } else if (typeof body === 'object') {
    config = Object.assign(config, requestConfigJSON);
    requestBody = JSON.stringify(body);
  }

  setAuthHeaders(config.headers);

  const promise = new Promise((resolve, reject) => {
    axios
      .patch(URL + endpoint, requestBody, config)
      .then(response => {
        const data: any = response;
        resolve(data);
      })
      .catch(response => {
        reject(response);
      });
  });

  return promise;
}

//
// API DELETE
//
export function del(endpoint) {
  const config = requestConfig;

  setAuthHeaders(config.headers);

  const promise = new Promise((resolve, reject) => {
    axios
      .delete(URL + endpoint, config)
      .then(response => {
        const data: any = response;
        resolve(data);
      })
      .catch(response => {
        reject(response);
      });
  });

  return promise;
}
