import React from 'react';
import { withGoogleMap } from 'react-google-maps';

import MapWithASearchBox from './MapWithASearchBox';

const MapWithASearchBoxWithHoc = withGoogleMap(MapWithASearchBox);
const MapWithASearchBoxWrapper = props => (
  <MapWithASearchBoxWithHoc
    {...props}
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ height: '400px' }} />}
    mapElement={<div style={{ height: '100%' }} />}
  />
);

export default MapWithASearchBoxWrapper;
