// @flow

import type { ExtractReturn } from 'types';

export const types = {
  SPECIALIST_FETCH: 'SPECIALIST_FETCH',
  SPECIALIST_PROFILE_EDIT: 'SPECIALIST_PROFILE_EDIT',
  SPECIALIST_PROFILE_SAVE: 'SPECIALIST_PROFILE_SAVE',
  SPECIALIST_REVIEW_FETCH: 'SPECIALIST_REVIEW_FETCH',
  SPECIALIST_LANGUAGES_FETCH: 'SPECIALIST_LANGUAGES_FETCH',
  SPECIALIST_LANGUAGES_SAVE: 'SPECIALIST_LANGUAGES_SAVE',
  SPECIALIST_SERVICES_SAVE: 'SPECIALIST_SERVICES_SAVE',
  SPECIALIST_UPDATE_PROFILE_PICTURE: 'SPECIALIST_UPDATE_PROFILE_PICTURE',
  SPECIALIST_SUBMIT_REVIEW: 'SPECIALIST_SUBMIT_REVIEW',
  SPECIALIST_PRACTICE_CHANGE_ACTIVE_TAB: 'SPECIALIST_PRACTICE_CHANGE_ACTIVE_TAB',
};

export type ReviewForm = {|
  overallRating: number,
  bedsideMannerRating: number,
  waitTimeRating: number,
  name: string,
  email: string,
  text: string,
|};

const fetchSpecialist = (slug: string, specialization: string) => ({
  type: `${types.SPECIALIST_FETCH}_REQUEST`,
  slug,
  specialization,
});

// $FlowFixMe
const updateSpecialistProfile = (id, value) => ({
  type: `${types.SPECIALIST_PROFILE_SAVE}_REQUEST`,
  id,
  value,
});

const loadLanguages = () => ({
  type: `${types.SPECIALIST_LANGUAGES_FETCH}_REQUEST`,
});

// $FlowFixMe
const updateSpecialistLanguages = (id, languages, resolve, reject) => ({
  type: `${types.SPECIALIST_LANGUAGES_SAVE}_REQUEST`,
  id,
  languages,
  resolve,
  reject,
});

// $FlowFixMe
const updateSpecialistServices = (specialistId, specialistPracticeId, formObj) => ({
  type: `${types.SPECIALIST_SERVICES_SAVE}_REQUEST`,
  specialistId,
  specialistPracticeId,
  formObj,
});

// $FlowFixMe
const updateProfilePicture = (specialistId, pictureData) => ({
  type: `${types.SPECIALIST_UPDATE_PROFILE_PICTURE}_REQUEST`,
  specialistId,
  pictureData,
});

const submitReview = (specialistId: number, formObj: ReviewForm) => ({
  type: `${types.SPECIALIST_SUBMIT_REVIEW}_REQUEST`,
  specialistId,
  formObj,
});

// $FlowFixMe
const specialistPracticeChangeActiveTab = activeTab => ({
  type: types.SPECIALIST_PRACTICE_CHANGE_ACTIVE_TAB,
  payload: {
    activeTab,
  },
});

export type FetchSpecialistActionFlowType = ExtractReturn<typeof fetchSpecialist>;
export type SubmitReviewAction = ExtractReturn<typeof submitReview>;
export type LoadLanguagesActionFlowType = ExtractReturn<typeof loadLanguages>;

export type SpecialistActions = FetchSpecialistActionFlowType | SubmitReviewAction | LoadLanguagesActionFlowType;

export const actions = {
  fetchSpecialist,
  updateSpecialistProfile,
  loadLanguages,
  updateSpecialistLanguages,
  updateSpecialistServices,
  updateProfilePicture,
  submitReview,
  specialistPracticeChangeActiveTab,
};
