// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';

import styles from './ModalCard.scss';

type Props = {
  title: string,
  children: Node,
};
class ModalCard extends PureComponent<Props> {
  render() {
    const { title, children } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={`is-hidden-mobile ${styles.side}`} />
        <div className={`${styles.contentWrapper} has-text-left`}>
          <h2 className="has-text-weight-light has-text-primary is-size-2 is-size-3-mobile">{title}</h2>
          <div className="is-divider" />
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default ModalCard;
