import React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Link } from 'react-router-dom';

import config from 'config';
import styles from './LinkSection.scss';

const LinkSection = () => {
  const onFooterHeadClick = e => {
    e.currentTarget.parentNode.classList.toggle('open');
  };

  return (
    <I18n>
      {({ i18n }) => (
        <div className={styles.container}>
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className={styles.cont}>
                  <div
                    className={`has-text-primary-dark ${styles.sectionTitle}`}
                    role="button"
                    tabIndex={0}
                    onClick={onFooterHeadClick}
                  >
                    <i className="fa is-hidden-desktop fa-angle-right mr-10" />
                    <span>{config.name}</span>
                  </div>
                  <ul className="fontfamily-source">
                    <li>
                      <Link to="/" className="has-text-primary">
                        <Trans>Home</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us" className="has-text-primary">
                        <Trans>About us</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/join-us" className="has-text-primary">
                        <Trans>Join us</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/press" className="has-text-primary">
                        <Trans>Press</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" className="has-text-primary">
                        <Trans>Contact Us</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" className="has-text-primary">
                        <Trans>Blog</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className={styles.cont}>
                  <div
                    className={`is-tex-primary ${styles.sectionTitle}`}
                    role="button"
                    tabIndex={0}
                    onClick={onFooterHeadClick}
                  >
                    <i className="fa is-hidden-desktop fa-angle-right mr-10" />
                    <span>
                      <Trans>Popular searches</Trans>
                    </span>
                  </div>
                  <ul className="fontfamily-source">
                    <li>
                      <Link to={i18n._(t`/specialists/london/dentist`)} className="has-text-primary">
                        <Trans>Dentists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/osteopath`)} className="has-text-primary">
                        <Trans>Osteopaths in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/homeopath`)} className="has-text-primary">
                        <Trans>Homeopaths in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/gynaecologist`)} className="has-text-primary">
                        <Trans>Gynaecologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/physiotherapist`)} className="has-text-primary">
                        <Trans>Physiotherapists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/psychologist`)} className="has-text-primary">
                        <Trans>Psychologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/cardiologist`)} className="has-text-primary">
                        <Trans>Cardiologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/dermatologist`)} className="has-text-primary">
                        <Trans>Dermatologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/paediatrician`)} className="has-text-primary">
                        <Trans>Paediatricians in London</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className={styles.cont}>
                  <div className={styles.sectionTitle} role="button" tabIndex={0} onClick={onFooterHeadClick}>
                    <i className="fa is-hidden-desktop fa-angle-right mr-10" />
                    <span>
                      <Trans>Popular french searches</Trans>
                    </span>
                  </div>
                  <ul className="fontfamily-source">
                    <li>
                      <Link to={i18n._(t`/specialists/london/dentist/french`)} className="has-text-primary">
                        <Trans>French Dentists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/osteopath/french`)} className="has-text-primary">
                        <Trans>French Osteopaths in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/gp/french`)} className="has-text-primary">
                        <Trans>French GPs (General practitioners) in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/physiotherapist/french`)} className="has-text-primary">
                        <Trans>French Physiotherapists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/paediatrician/french`)} className="has-text-primary">
                        <Trans>French Paediatricians in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/dermatologist/french`)} className="has-text-primary">
                        <Trans>French Dermatologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/psychologist/french`)} className="has-text-primary">
                        <Trans>French Psychologists in London</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to={i18n._(t`/specialists/london/gynaecologist/french`)} className="has-text-primary">
                        <Trans>French Gynaecologists in London</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </I18n>
  );
};

export default LinkSection;
