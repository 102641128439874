// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import { actions as commonActions } from 'modules/common/actions';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import type { CreateSpecializationRequestActionFlowType } from 'modules/specializations/actions';

import { types, actions } from './actions';

function* fetchSpecializationsRequest(): Saga<void> {
  try {
    console.warn('before request');
    const response = yield call(api.get, `interest?filters={"order":"name%20qASC"}`);
    yield put({ type: types.FETCH_SPECIALIZATIONS_SUCCESS, payload: response.data });
  } catch (error) {
    console.error(`fetchSpecializationsRequest failed ${error}`);
    // logException(error, `fetchSpecializationsRequest failed ${erro}`);
  }
}

// $FlowFixMe
export function* searchSpecializationsRequest({ key, resolve }): Saga<void> {
  try {
    const filter = {
      fields: { id: true, name: true },
      where: {
        and: [{ name: { like: `${encodeURIComponent(key)}%25` } }],
      },
    };
    const { data } = yield call(api.get, `interests?filter=${JSON.stringify(filter)}`);
    const options = data.map(item => ({
      value: item.id,
      label: item.name,
    }));

    resolve({ options });
  } catch (error) {
    logException(error, `searchSpecializationsRequest failed ${error}`);
  }
}

export function* createSpecializationRequest(action: CreateSpecializationRequestActionFlowType): Saga<void> {
  try {
    const { specialization: name } = action.payload;
    const { data } = yield call(api.post, 'interests', { name });

    yield put(actions.createSpecializationSuccess(data));
    yield put(commonActions.toastSuccess(i18n._(t`Creating a new specialization was successful.`)));
  } catch (error) {
    logException(error, `createSpecializationRequest failed ${error}`);
    yield put(commonActions.toastDanger(i18n._(t`Sorry, creating a new specialization failed.`)));
  }
}

// Saga Helper
export default function* watchAdminSpecializationsActionRequests(): Saga<void> {
  yield takeLatest(types.SEARCH_SPECIALIZATION_REQUEST, searchSpecializationsRequest);
  yield takeLatest(types.CREATE_SPECIALIZATION_REQUEST, createSpecializationRequest);
  yield takeLatest(types.FETCH_SPECIALIZATIONS_REQUEST, fetchSpecializationsRequest);
}
