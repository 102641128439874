// @flow

import Cookie from 'js-cookie';
import config from 'config';

const locale = Cookie.get('locale') || config.language.default || 'en';

// Neded because HMR doesn't work with dynamic import for languages
let app;
if (process.env.NODE_ENV === 'development') {
  app = require('client/development').default;
} else {
  app = require('client/production').default;
}
app(locale);
