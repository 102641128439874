import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import { types } from './actions';

export function* sendFormRequest({ formObj, successCallback, successMsg }) {
  try {
    yield call(axios.post, '/back-end/email-form', formObj);

    yield call(successCallback);
    yield put(modalActions.hideModal());
    yield put(commonActions.toastSuccess(successMsg));
  } catch (error) {
    yield put(modalActions.hideModal());
    yield put(commonActions.toastDanger(i18n._(t`Sorry, submitting the form has failed`)));
    logException(error, `sendContactRequest failed ${error}`);
  }
}

// Saga Helper
export default function* watchVisitorActionRequests() {
  yield takeLatest(`${types.VISITOR_SEND_FORM}_REQUEST`, sendFormRequest);
}
