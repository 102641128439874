// @flow

import type { ReduxStateFlowType } from 'types';

import { modalTypes } from './actions';
import type { ModalTypesFlowType, ModalActionFlowType } from './actions';

export type ModalStoreFlowType = {|
  modalType: ?ModalTypesFlowType,
  modalProps: any,
|};

export const initialState = {
  modalType: null,
  modalProps: {},
};

export default (state: ModalStoreFlowType = initialState, action: ModalActionFlowType) => {
  switch (action.type) {
    case modalTypes.SHOW_MODAL:
      return {
        ...state,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };

    case modalTypes.MODAL_HIDE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export const selectors = {
  getModal: (state: ReduxStateFlowType) => state.modal,
};
