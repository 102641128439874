import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { get as axiosGet } from 'axios';

import * as api from 'client/api/api';
import { actions as commonActions } from 'modules/common/actions';
import { actions as bookingCalendarActions } from 'modules/bookingCalendar/actions';
import logException from 'utils/logException';
import { fromDashed } from 'utils/string';
import { types } from './actions';
import { selectors } from './reducer';

const GENDER_MAPPING = {
  male: 'M',
  female: 'F',
};
const getGenderFilter = gender => (gender ? `&filter[gender]=${GENDER_MAPPING[gender]}` : '');
const getLanguageFilter = language => (language ? `&filter[language]=${language}` : '');
const getSpecializationFilter = specialization => {
  if (specialization && specialization !== 'all-specialities') {
    return `&filter[specialization]=${encodeURIComponent(fromDashed(specialization))}`;
  }
  return '';
};
const getNameFilter = name => (name ? `&filter[name]=${name}` : '');
const getLatitudeFilter = latitude => {
  if (latitude) {
    return `&filter[latitude]=${latitude}`;
  }
  return '';
};
const getLongitudeFilter = longitude => {
  if (longitude) {
    return `&filter[longitude]=${longitude}`;
  }
  return '';
};

export function* fetchLanguagesRequest({ routerParams }) {
  try {
    const gender = routerParams.gender || null;
    const name = routerParams.name || '';
    const specialization = routerParams.specialization || '';
    const url = `specialists/getLanguages?${getGenderFilter(gender)}${getSpecializationFilter(
      specialization
    )}${getNameFilter(name)}`;

    const res = yield call(api.get, url);

    yield put({ type: types.LANGUAGES_FETCHED, payload: res.data });
  } catch (error) {
    logException(error, `fetchLanguagesRequest failed ${error}`);
  }
}

export function* fetchGendersRequest({ routerParams }) {
  try {
    const language = routerParams.language || null;
    const name = routerParams.name || '';
    const specialization = routerParams.specialization || '';
    const url = `specialists/getGenders?${getLanguageFilter(language)}${getSpecializationFilter(
      specialization
    )}${getNameFilter(name)}`;

    const res = yield call(api.get, url);

    yield put({ type: types.GENDERS_FETCHED, payload: res.data });
  } catch (error) {
    logException(error, `fetchGendersRequest failed ${error}`);
  }
}

export function* fetchSearchResultDetailsRequest({ routerParams }) {
  try {
    let url = routerParams.town ? `${routerParams.town}/` : '';
    url += routerParams.specialization ? `${routerParams.specialization}/` : '';
    url += routerParams.language ? `${routerParams.language}` : '';

    const res = yield call(api.get, `searchResultDetails?filter[where][url]=${url}`);

    yield put({ type: types.SEARCH_RESULT_DETAILS_FETCHED, payload: res.data[0] });
  } catch (error) {
    logException(error, `fetchSearchResultDetailsRequest failed ${error}`);
  }
}

export function* fetchCountRequest({ routerParams }) {
  try {
    const gender = routerParams.gender || null;
    const language = routerParams.language || '';
    const name = routerParams.name || '';
    const specialization = routerParams.specialization || '';
    const specialistGetCountUrl = `specialists/getSpecialistCount?${getGenderFilter(gender)}${getLanguageFilter(
      language
    )}${getSpecializationFilter(specialization)}${getNameFilter(name)}`;

    const res = yield call(api.get, specialistGetCountUrl);
    const specialistCount = res.data.count;

    // get practices if neither gender nor language is defined
    let practiceCount = 0;
    if (!gender && !language) {
      const practiceRes = yield call(
        api.get,
        `practices/getPracticeCount?${getSpecializationFilter(specialization)}${getNameFilter(name)}`
      );
      practiceCount = practiceRes.data.count;
    }

    yield put({
      type: types.SEARCH_COUNT_FETCHED,
      payload: {
        specialistCount,
        practiceCount,
      },
    });
  } catch (error) {
    logException(error, `fetchCountRequest failed ${error}`);
  }
}

export function* fetchResultsRequest({ routerParams, latitude, longitude }) {
  yield put(commonActions.showSpinner());

  // get count first
  yield* fetchCountRequest({ routerParams });

  try {
    const page = routerParams.page || 1;
    const gender = routerParams.gender || null;
    const language = routerParams.language || null;
    const sortBy = routerParams.sortBy || '';
    const name = routerParams.name || '';
    const specialization = routerParams.specialization || '';

    const searchStore = yield select(selectors.getSearchResults);
    const { pageSize, specialistCount, practiceCount } = searchStore;
    const skip = (page - 1) * pageSize;

    // load specialists
    let specialists = [];
    if (skip === 0 || skip < specialistCount) {
      const url = `specialists/getSpecialistList?filter[sortBy]=${sortBy}&filter[include]=practices&filter[limit]=${pageSize}&filter[skip]=${skip}${getLatitudeFilter(
        latitude
      )}${getLongitudeFilter(longitude)}${getGenderFilter(gender)}${getLanguageFilter(
        language
      )}${getSpecializationFilter(specialization)}${getNameFilter(name)}`;

      const res = yield call(api.get, url);
      specialists = res.data;
    }

    // load practices if full page size of specialists are not loaded and practice exists
    let practices = [];
    if (specialists.length < pageSize && practiceCount) {
      const practiceSkip = specialists.length ? 0 : skip - specialistCount;
      const practiceToLoad = specialists.length ? pageSize - specialists.length : pageSize;
      const practiceURL = `practices/getPracticeList?filter[limit]=${practiceToLoad}&filter[skip]=${practiceSkip}${getLatitudeFilter(
        latitude
      )}${getLongitudeFilter(longitude)}${getSpecializationFilter(specialization)}${getNameFilter(name)}`;

      const practiceRes = yield call(api.get, practiceURL);
      practices = practiceRes.data;
    }

    // dispatch event
    yield put({
      type: types.SEARCH_RESULT_FETCHED,
      payload: {
        specialists,
        practices,
      },
    });

    // get timeslots
    for (let i = 0; i < specialists.length; i += 1) {
      const { clicrdvGroupId, clicrdvCalendarId, apiType } = specialists[i];
      // TODO: for clinikoApi interventionId must be specified
      const interventionId = '';
      if (clicrdvGroupId && clicrdvCalendarId) {
        yield put(
          bookingCalendarActions.fetchAvailableTimeSlots(
            apiType,
            clicrdvGroupId,
            [clicrdvCalendarId],
            interventionId,
            false,
            clicrdvCalendarId
          )
        );
      }
    }
  } catch (error) {
    logException(error, `fetchResultsRequest failed ${error}`);
  }
  yield put(commonActions.hideSpinner());
}

export function* fetchResultLocationRequest({ routerParams }) {
  yield put(commonActions.resetSpinner());
  yield put(commonActions.showSpinner());
  try {
    const { location } = routerParams;
    let latitude = '';
    let longitude = '';

    if (location) {
      const { data } = yield call(
        axiosGet,
        `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&region=uk&key=AIzaSyAvbCffcViS9xJJcrsGQQoLntwHvKbPxn8`
      );
      // response = yield call(response.json);
      if (data.results.length) {
        latitude = data.results[0].geometry.location.lat;
        longitude = data.results[0].geometry.location.lng;
        yield fork(fetchResultsRequest, { routerParams, latitude, longitude });
      } else {
        yield put({
          type: types.SEARCH_RESULT_FETCHED,
          payload: [],
        });
      }
    } else {
      yield fork(fetchResultsRequest, { routerParams });
    }
  } catch (error) {
    logException(error, `fetchResultLocationRequest failed ${error}`);
  }
  yield put(commonActions.hideSpinner());
}

export function* fetchResultDataRequest({ routerParams }) {
  yield fork(fetchLanguagesRequest, { routerParams });
  yield fork(fetchGendersRequest, { routerParams });
  yield fork(fetchResultLocationRequest, { routerParams });
  yield fork(fetchSearchResultDetailsRequest, { routerParams });
}

// Saga Helper
export default function* watchSearchActionRequests() {
  yield takeLatest(`${types.LANGUAGES_FETCHED}_REQUEST`, fetchLanguagesRequest);
  yield takeLatest(`${types.GENDERS_FETCHED}_REQUEST`, fetchGendersRequest);
  yield takeLatest(`${types.SEARCH_RESULT_DETAILS_FETCHED}_REQUEST`, fetchSearchResultDetailsRequest);
  yield takeLatest(`${types.SEARCH_COUNT_FETCHED}_REQUEST`, fetchCountRequest);
  yield takeLatest(`${types.SEARCH_RESULT_FETCHED}_REQUEST`, fetchResultsRequest);
  yield takeLatest(`${types.SEARCH_FETCH_RESULT_LOCATION}_REQUEST`, fetchResultLocationRequest);
  yield takeLatest(`${types.SEARCH_FETCH_RESULT_DATA}_REQUEST`, fetchResultDataRequest);
}
