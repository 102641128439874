import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'client/api/api';
import logException from 'utils/logException';
import { types } from './actions';

function* fetchSpecialistsRequest({ name }) {
  try {
    const sName = name.replace('&', '%26');
    const query = {
      where: {
        or: [
          {
            firstName: { like: `${sName}%25` },
          },
          {
            lastName: { like: `${sName}%25` },
          },
        ],
      },
    };

    if (name.indexOf(' ') >= 0) {
      const [firstName, lastName] = sName.split(' ');
      query.where.or = query.where.or.concat([
        {
          and: [{ firstName: { like: `${firstName}%25` } }, { lastName: { like: `${lastName}%25` } }],
        },
        {
          firstName: { like: `${firstName}-${lastName}%25` },
        },
        {
          firstName: { like: `${firstName} ${lastName}%25` },
        },
        {
          firstName: { like: `${sName}%25` },
        },
        {
          lastName: { like: `${sName}%25` },
        },
      ]);
    }

    query.include = {
      relation: 'practices',
      scope: {
        fields: ['town', 'borough'],
      },
    };
    // There is a bug in loopback
    // if only required fields are specified no fields from practices relation are returned
    query.fields = {
      name: false,
      phoneNumber: false,
      position: false,
      profile: false,
      profilePicture: false,
      rating: false,
      userId: false,
      website: false,
    };

    const response = yield call(api.get, `specialists?filter=${JSON.stringify(query)}`);
    yield put({ type: types.SEARCH_FETCH_SPECIALISTS, payload: response.data });
  } catch (error) {
    logException(error, `updateSpecialistProfile failed ${error}`);
  }
}

function* fetchPracticesRequest({ name }) {
  try {
    // make search available without inputting 'the '
    const query = {
      where: {
        or: [
          {
            name: { like: `${encodeURIComponent(name)}%25` },
          },
          {
            name: { like: `${encodeURIComponent(`the ${name}`)}%25` },
          },
        ],
      },
      fields: {
        name: true,
        town: true,
        borough: true,
        slug: true,
      },
    };

    // handle ' and' same with ' &'
    if (name.indexOf(' and') !== -1) {
      query.where.or.push({
        name: { like: `${encodeURIComponent(name.replace(' and', ' &'))}%25` },
      });
    }

    const response = yield call(api.get, `practices/?filter=${JSON.stringify(query)}`);
    yield put({ type: types.SEARCH_FETCH_PRACTICES, payload: response.data });
  } catch (error) {
    logException(error, `updateSpecialistProfile failed ${error}`);
  }
}

// Saga Helper
export default function* watchSpecialistActionRequests() {
  yield takeLatest(`${types.SEARCH_FETCH_SPECIALISTS}_REQUEST`, fetchSpecialistsRequest);
  yield takeEvery(`${types.SEARCH_FETCH_PRACTICES}_REQUEST`, fetchPracticesRequest);
}
