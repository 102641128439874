import { all, fork } from 'redux-saga/effects';

import watchIndexActionRequests from 'modules/index/saga';
import watchBookingCalendarActions from 'modules/bookingCalendar/saga';
import watchSpecialistActions from 'modules/specialist/saga';
import watchPatientActionRequests from 'modules/patient/saga';
import watchUserActionRequests from 'modules/user/saga';
import watchVisitorActionRequests from 'modules/visitor/saga';
import watchPracticeActionRequests from 'modules/practice/saga';
import watchSearchActionRequests from 'modules/searchView/saga';
import watchBlogActionRequests from 'modules/visitor/blog/blogView/saga';
import watchBlogDetailActionRequests from 'modules/visitor/blog/blogDetailView/saga';
import watchAdminActionRequests from 'modules/admin/saga';

export default function* rootSaga() {
  yield all([
    fork(watchIndexActionRequests),
    fork(watchSpecialistActions),
    fork(watchBookingCalendarActions),
    fork(watchPatientActionRequests),
    fork(watchUserActionRequests),
    fork(watchVisitorActionRequests),
    fork(watchPracticeActionRequests),
    fork(watchSearchActionRequests),
    fork(watchBlogActionRequests),
    fork(watchBlogDetailActionRequests),
    fork(watchAdminActionRequests),
  ]);
}
