import { types } from './actions';

export const initialState = {
  availableTimeSlots: {},
  availableReasons: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.AVAILABLE_TIME_SLOTS_FETCHED:
      return {
        ...state,
        availableTimeSlots: { ...state.availableTimeSlots, ...payload },
      };

    case types.SPECIALIST_BOOKING_REASON_LIST_FETCHED:
      return {
        ...state,
        availableReasons: { ...state.availableReasons, ...payload },
      };

    default:
      return state;
  }
};

export const selectors = {
  getAvailableCalendarTimeslots: (state, specialistPractice) => {
    const { availableTimeSlots } = state.bookingCalendar;
    if (
      specialistPractice &&
      specialistPractice.clicrdvCalendarId &&
      availableTimeSlots[specialistPractice.clicrdvCalendarId]
    ) {
      return availableTimeSlots[specialistPractice.clicrdvCalendarId];
    }
    return {};
  },
  getAvailableCalendarReasons: (state, specialistPractice) => {
    const { availableReasons } = state.bookingCalendar;
    if (
      specialistPractice &&
      specialistPractice.clicrdvCalendarId &&
      availableReasons[specialistPractice.clicrdvCalendarId]
    ) {
      return availableReasons[specialistPractice.clicrdvCalendarId];
    }
    return [];
  },
  getAvailableTimeslots: state => state.bookingCalendar.availableTimeSlots,
  getAvailableReasons: state => state.bookingCalendar.availableReasons,
};
