// @flow

import type { UserStoreState, ExtractReturn } from 'types';

export const roles = {
  EMPTY: 0,
  PATIENT: 2,
  PRACTICE: 4,
  SPECIALIST: 8,
  ADMIN: 16,
  LOGIN: 31,
};

export const types = {
  FETCH_USER_REQUEST: 'USER/FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'USER/FETCH_USER_SUCCESS',
  LOGIN_USER_REQUEST: 'USER/LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'USER/LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR: 'USER/LOGIN_USER_ERROR',
  LOGOUT_USER_REQUEST: 'USER/LOGOUT_USER_REQUEST',
  LOGOUT_USER_SUCCESS: 'USER/LOGOUT_USER_SUCCESS',
  GET_EDITABLE_USER_SPECIALISTS_SUCCESS: 'USER/GET_EDITABLE_USER_SPECIALISTS_SUCCESS',
  GET_USER_PRACTICE_SUCCESS: 'USER/GET_USER_PRACTICE_SUCCESS',
  GET_USER_SPECIALIST_SUCCESS: 'USER/GET_USER_SPECIALIST_SUCCESS',
  ROUTER_LOCATION_CHANGE_SUCCESS: '@@router/LOCATION_CHANGE',
};

export type UserLoginForm = {|
  email: string,
  password: string,
|};

export type UserLoginError = {};

const fetchUserRequest = (id: number) => ({
  type: types.FETCH_USER_REQUEST,
  payload: id,
});

const loginUserRequest = (payload: UserLoginForm) => ({
  type: types.LOGIN_USER_REQUEST,
  payload,
});

const loginUserSuccess = (responseData: UserStoreState) => ({
  type: types.LOGIN_USER_SUCCESS,
  payload: responseData,
});

const loginUserError = (userLoginError: UserLoginError) => ({
  type: types.LOGIN_USER_ERROR,
  payload: userLoginError,
});

const logoutUserRequest = () => ({
  type: types.LOGOUT_USER_REQUEST,
});

const logoutUserSuccess = () => ({
  type: types.LOGOUT_USER_SUCCESS,
});

export type FetchUserRequestAction = ExtractReturn<typeof fetchUserRequest>;
export type LoginUserRequestAction = ExtractReturn<typeof loginUserRequest>;
export type LoginUserSuccessAction = ExtractReturn<typeof loginUserSuccess>;
export type LoginUserErrorAction = ExtractReturn<typeof loginUserError>;
export type LogoutUserRequestAction = ExtractReturn<typeof logoutUserRequest>;
export type LogoutUserSuccessAction = ExtractReturn<typeof logoutUserSuccess>;

export type UserActions =
  | FetchUserRequestAction
  | LoginUserRequestAction
  | LoginUserSuccessAction
  | LoginUserErrorAction
  | LogoutUserRequestAction
  | LogoutUserSuccessAction;

export const actions = {
  fetchUserRequest,
  loginUserRequest,
  loginUserSuccess,
  loginUserError,
  logoutUserRequest,
  logoutUserSuccess,
};
