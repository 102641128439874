import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './LoadingSpinner.scss';

const LoadingSpinner = ({ spinners, track, fixed }) =>
  spinners.indexOf(track) !== -1 && (
    <div className={styles.wrapper} style={{ position: fixed ? 'fixed' : 'absolute' }}>
      <div className={styles.spinner}>
        <div className={styles.cube1} />
        <div className={styles.cube2} />
      </div>
    </div>
  );

LoadingSpinner.defaultProps = {
  fixed: false,
};

LoadingSpinner.propTypes = {
  spinners: PropTypes.arrayOf(PropTypes.string),
  track: PropTypes.string,
  fixed: PropTypes.bool,
};

const mapStateToProps = appState => ({
  spinners: appState.common.spinners,
});

export default connect(mapStateToProps)(LoadingSpinner);
