import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import * as api from 'client/api/api';
import ProfileLink from 'components/link/ProfileLink';
import { toDashed } from 'utils/string';
import { createValidator, url, required, email } from 'utils/validator';
import InputText from 'components/form/inputText';
import SelectField from 'components/form/selectField';
import WastonSelectField from 'components/form/WastonSelectField';
import * as constants from 'modules/specialist/constants';
import { actions as specializationActions } from 'modules/specializations/actions';
import { actions } from 'modules/admin/actions';
import PracticesFieldArray from './PracticesFieldArray';

const calculator = createDecorator(
  {
    field: 'firstName',
    updates: {
      slug: (firstName, formValues) => toDashed(`${firstName || ''} ${formValues.lastName || ''}`),
    },
  },
  {
    field: 'lastName',
    updates: {
      slug: (lastName, formValues) => toDashed(`${formValues.firstName || ''} ${formValues.lastName || ''}`),
    },
  }
);

class SpecialistForm extends React.Component {
  constructor(props) {
    super(props);
    this.slugValidateTimeout = 0;
    this.userTimeout = 0;
    this.practiceTimeout = 0;

    props.dispatch(specializationActions.fetchSpecializationsRequest());
  }

  submitForm = (formObj, formAPI) => {
    const successCallback = formAPI.reset;
    this.props.dispatch(this.props.onSubmit(formObj, successCallback));
  };

  handleUserSearch = (input, cb) => {
    if (this.userTimeout) {
      clearTimeout(this.userTimeout);
    }
    this.userTimeout = setTimeout(() => {
      this.props.dispatch(actions.searchSpecialistUsers(input, cb));
    }, 500);
  };

  handlePracticeSearch = (input, cb) => {
    if (this.practiceTimeout) clearTimeout(this.practiceTimeout);
    this.practiceTimeout = setTimeout(() => {
      this.props.dispatch(actions.searchSpecialistPractices(input, cb));
    }, 500);
  };

  slugAsyncValidation = slug => {
    if (!slug || !this.props.isCreate) return '';
    const filter = {
      include: [
        {
          relation: 'practices',
          scope: {
            fields: ['town'],
          },
        },
      ],
      where: {
        slug: { like: encodeURIComponent(slug) },
        level: { gt: -1 },
      },
    };

    return api.get(`specialists?filter=${JSON.stringify(filter)}`).then(response => {
      const { data } = response;
      if (data.length > 0) {
        return data.map(item => (
          <React.Fragment key={item.id}>
            {item.practices && item.practices.length > 0 ? (
              <ProfileLink
                profileType="specialist"
                town={item.practices[0].town}
                speciality={item.specialization}
                slug={item.slug}
              >
                {item.firstName} {item.lastName} ({item.slug}) {item.specialization}
              </ProfileLink>
            ) : (
              <React.Fragment>
                {item.firstName} {item.lastName} ({item.slug}) {item.specialization}
              </React.Fragment>
            )}
            <br />
          </React.Fragment>
        ));
      }
      return '';
    });
  };

  render() {
    const { specialistDetail, specializations, isCreate } = this.props;
    const decorator = isCreate ? calculator : createDecorator();
    return (
      <Form
        onSubmit={this.submitForm}
        decorators={[decorator]}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          gender: specialistDetail.gender ? specialistDetail.gender : 'M',
          ...specialistDetail,
        }}
        validateOnBlur
        validate={createValidator({
          firstName: [required],
          lastName: [required],
          gender: [required],
          specialization: [required],
          slug: [required],
          website: [url],
          email: [email],
        })}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="pb-25">
              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    First Name *
                    <Field
                      component={InputText}
                      name="firstName"
                      label="First Name*"
                      onChange={form.mutators.setSlug}
                    />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Last Name *
                    <Field
                      component={InputText}
                      name="lastName"
                      label="Last Name*"
                      onChange={this.handleLastNameChange}
                    />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Gender *
                    <Field component={SelectField} className="input" name="gender">
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Field>
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Email
                    <Field component={InputText} name="email" label="" />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Specialization *
                    <Field component={SelectField} disabled={!isCreate} className="input" name="specialization">
                      <option value="" disabled>
                        --Select Specialization--
                      </option>
                      {specializations.map(item => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Slug *
                    <Field
                      component={InputText}
                      validate={this.slugAsyncValidation}
                      name="slug"
                      label="Slug*"
                      readonly={!isCreate}
                    />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Level
                    <Field component={SelectField} className="input w-100" name="level">
                      <option value={constants.DISABLED}>Disabled</option>
                      <option value={constants.FREE_PROFILE}>Free Profile</option>
                      <option value={constants.FULL_PROFILE}>Full Profile</option>
                    </Field>
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Phone Number
                    <Field component={InputText} name="phoneNumber" label="Phone Number" />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Mobile Number
                    <Field component={InputText} name="mobilePhoneNumber" label="Mobile Number" />
                  </label>
                </div>
              </div>

              <div className="column">
                <label className="label">
                  WebsiteLink
                  <Field component={InputText} name="website" label="Website" />
                </label>
              </div>

              <div className="column">
                <label className="label">
                  Title - fill/edit only if necessary. Title is generated automatically.
                  <Field component={InputText} name="title" label="Title" />
                </label>
              </div>

              <div className="column">
                <label className="label">
                  User
                  <Field
                    component={WastonSelectField}
                    fetchAction={this.handleUserSearch}
                    className="input"
                    name="userId"
                    placeholder="Search user by email..."
                  />
                </label>
              </div>

              <div className="column">
                <label className="label">
                  Practices
                  <FieldArray
                    name="specialistPractice"
                    component={PracticesFieldArray}
                    fetchAction={this.handlePracticeSearch}
                  />
                </label>
              </div>
            </div>
            <button className="button is-primary is-fullwidth fontweight-700">{isCreate ? 'Create' : 'Update'}</button>
          </form>
        )}
      />
    );
  }
}

SpecialistForm.defaultProps = {
  isCreate: false,
};

SpecialistForm.propTypes = {
  specializations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isCreate: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  specialistDetail: PropTypes.shape({}),
};

export default SpecialistForm;
