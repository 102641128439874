// @flow

import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { FORM_ERROR } from 'final-form';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import type {
  GetUsersRequestActionFlowType,
  CreateUserRequestActionFlowType,
  UpdateUserRequestActionFlowType,
} from 'modules/admin/actions';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import { actions as adminActions, types as adminTypes } from 'modules/admin/actions';

export function* getUsersSagaRequest({ payload }: GetUsersRequestActionFlowType): Saga<void> {
  const { page, pageSize, sorted, filtered } = payload;

  yield put(commonActions.resetSpinner());
  if (!filtered.length && !sorted) {
    yield put(commonActions.showSpinner());
  }

  try {
    const {
      data: { count },
    } = yield call(api.get, 'users/count');

    const filter: {|
      skip: number,
      limit: number,
      order?: string,
      where?: {
        and?: {
          [number]: string,
        },
      },
    |} = {
      skip: page > 0 ? page * pageSize : 0,
      limit: pageSize,
    };
    if (sorted) {
      filter.order = `${sorted.id} ${sorted.desc ? 'DESC' : 'ASC'}`;
    }

    if (filtered && filtered.length) {
      filter.where = {};
      const andWhere: { [number]: string } = filtered.map(item => ({
        [item.id]: { like: `${encodeURIComponent(item.value)}%25` },
      }));
      filter.where = { and: andWhere };
    }

    const response = yield call(api.get, `users?filter=${JSON.stringify(filter)}`);
    yield put({
      type: adminTypes.ADMIN_GET_USERS_SUCCESS,
      payload: {
        rows: response.data,
        pages: Math.ceil(count / pageSize),
      },
    });
  } catch (error) {
    logException(error, `getUsersRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* createUserSagaRequest(action: CreateUserRequestActionFlowType): Saga<void> {
  try {
    yield call(api.post, 'users', action.payload);
    yield put(adminActions.createUserSuccess({}));
    yield put(commonActions.toastSuccess(i18n._(t`User has been created successfully.`)));
  } catch (err) {
    const response = err.response || {};
    const error = response.data ? response.data.error : {};
    if (error.details && error.details.codes) {
      yield put(adminActions.createUserSuccess(error.details.messages));
    } else {
      yield put(
        adminActions.createUserError({
          [FORM_ERROR]: 'Creat  adding a new user has failed.',
        })
      );
      if (window.Smooch) {
        window.Smooch.open();
      }
      logException(response, `createUserRequest failed ${JSON.stringify(error)}`);
    }
  }
}

export function* updateUserSagaRequest({ payload }: UpdateUserRequestActionFlowType): Saga<void> {
  const { userId, formObj } = payload;
  try {
    const res = yield call(api.patch, `users/${userId}`, formObj);
    yield put(modalActions.hideModal());
    yield put({
      type: adminTypes.ADMIN_UPDATE_USER_SUCCESS,
      payload: res.data,
    });
    yield put(commonActions.toastSuccess(i18n._(t`User has been updated successfully.`)));
  } catch (error) {
    logException(error, `update user request failed ${error}`);
    yield put(
      commonActions.toastDanger(
        (error.response.data && error.response.data.error.message) || i18n._(t`Updating user has failed.`)
      )
    );
  }
}

// Saga Helper
export default function* watchAdminActionRequests(): Saga<void> {
  yield takeLatest(adminTypes.ADMIN_GET_USERS_REQUEST, getUsersSagaRequest);
  yield takeLatest(adminTypes.ADMIN_CREATE_USER_REQUEST, createUserSagaRequest);
  yield takeEvery(adminTypes.ADMIN_UPDATE_USER_REQUEST, updateUserSagaRequest);
}
