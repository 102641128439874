// @flow

/* global window */
import { takeLatest, call, put, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { push } from 'react-router-redux';
import Raven from 'raven-js';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import logException from 'utils/logException';
import { toDashed } from 'utils/string';
import trackEvent from 'utils/trackEvent';
import type { FetchUserRequestAction, LoginUserRequestAction } from './actions';
import { actions as userActions, roles, types } from './actions';
import { selectors } from './reducer';

const USER_TOKEN_TTL_DAYS = 30;
// token ttl is in seconds, conversion from days to the seconds
const DEFAULT_USER_TOKEN_TTL = USER_TOKEN_TTL_DAYS * 24 * 60 * 60;
export const API_USER_TOKEN_TTL = (USER_TOKEN_TTL_DAYS + 5) * 24 * 60 * 60;

export function* fetchUserRequest(action: FetchUserRequestAction): Saga<void> {
  yield put(commonActions.showSpinner());

  try {
    const response = yield call(api.get, `users/${action.payload}`);
    yield put({ type: types.FETCH_USER_SUCCESS, payload: response.data });
  } catch (error) {
    logException(error, `fetchUserRequest failed ${error}`);
  }
}

export function* loginUserRequest({ payload }: LoginUserRequestAction): Saga<void> {
  try {
    const { email, password } = payload;
    const body = {
      email,
      password,
      ttl: API_USER_TOKEN_TTL,
    };

    const response = yield call(api.post, 'users/login', body);
    const { id, userId, isAdminRole, hasCalendar, created } = response.data;

    yield put(modalActions.hideModal());

    Raven.setUserContext({
      id: userId,
      email,
    });

    yield put(
      userActions.loginUserSuccess({
        token: id,
        tokenTtl: DEFAULT_USER_TOKEN_TTL,
        tokenCreatedAt: created,
        userId,
        isAdminRole,
        role: isAdminRole ? roles.ADMIN : roles.EMPTY,
        hasCalendar,
      })
    );

    const editableSpecialists = yield call(api.get, `practices/getEditableSpecialistsByUserId/${userId}`);

    if (editableSpecialists && editableSpecialists.data && editableSpecialists.data.length > 0) {
      yield put({
        type: types.GET_EDITABLE_USER_SPECIALISTS_SUCCESS,
        payload: editableSpecialists.data,
      });
    }

    const [specialist, practice] = yield call(axios.all, [
      api.get(`specialists?filter[where][userId]=${userId}&filter[include]=practices`),
      api.get(`practices?filter[where][userId]=${userId}`),
    ]);

    let userUrl;
    let userName;

    if (practice && practice.data && practice.data.length > 0) {
      const firstPractice = practice.data[0];
      const practiceId = firstPractice.id;

      userName = firstPractice.name;
      userUrl = `/practice/${toDashed(firstPractice.town)}/${toDashed(firstPractice.borough)}/${firstPractice.slug}`;

      yield put({
        type: types.GET_USER_PRACTICE_SUCCESS,
        payload: {
          userName,
          practiceId,
          userUrl,
        },
      });
    } else if (specialist && specialist.data && specialist.data.length > 0) {
      const firstSpecialist = specialist.data[0];
      const specialistId = firstSpecialist.id;

      userName = `${firstSpecialist.firstName} ${firstSpecialist.lastName}`;
      userUrl = `/specialist/${toDashed(firstSpecialist.practices[0].town)}/${toDashed(
        firstSpecialist.specialization
      )}/${firstSpecialist.slug}`;

      yield put({
        type: types.GET_USER_SPECIALIST_SUCCESS,
        payload: {
          userName,
          specialistId,
          userUrl,
        },
      });
    }

    trackEvent('User', 'Login', `user (${userId}) ${userName || ''}`);

    yield put(push(userUrl));
  } catch (error) {
    const { response } = error;
    if (response && response.data.error.message) {
      yield put(
        userActions.loginUserError({
          [FORM_ERROR]: response.data.error.message,
        })
      );
    } else {
      yield put(
        userActions.loginUserError({
          [FORM_ERROR]: i18n._(t`Login has failed. Please contact our support.`),
        })
      );
    }
    if (window.Smooch) {
      window.Smooch.open();
    }
    logException(error, `loginUserRequest failed ${error}`);
  }
}

export function* logoutUserRequest(): Saga<void> {
  try {
    const token = yield select(selectors.getToken);

    yield call(api.post, 'users/logout', { access_token: token });
  } catch (error) {
    logException(error, `logoutUserRequest failed ${error}`);
  }

  yield put({ type: types.LOGOUT_USER_SUCCESS });
  window.location.replace('/');
}

export function* checkUserToken(): Saga<void> {
  const user = yield select(selectors.getUser);
  // check for user token exist
  if (user.token) {
    // check for user without tokenExpiredAt or tokenTtl fields
    if (!user.tokenCreatedAt || !user.tokenTtl) {
      yield put({ type: types.LOGOUT_USER_SUCCESS });
    } else {
      const dateCrated = new Date(user.tokenCreatedAt).getTime();
      const tokenExpiredAt = dateCrated + user.tokenTtl * 1000;
      const tokenExpired = new Date().getTime() > tokenExpiredAt;
      if (tokenExpired) {
        yield put({ type: types.LOGOUT_USER_SUCCESS });
      } else {
        yield put({ type: 'JEST_VALID_TOKEN' });
      }
    }
  }
}

// Saga Helper
export default function* watchSpecialistActionRequests(): Saga<void> {
  yield takeLatest(types.FETCH_USER_REQUEST, fetchUserRequest);
  yield takeLatest(types.LOGIN_USER_REQUEST, loginUserRequest);
  yield takeLatest(types.LOGOUT_USER_REQUEST, logoutUserRequest);
  yield takeLatest(types.ROUTER_LOCATION_CHANGE_SUCCESS, checkUserToken);
}
