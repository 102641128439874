// @flow

import { connect } from 'react-redux';
import { getUser } from '../selectors/viewSelectors';

import Header from './Header';

const mapStateToProps = state => ({
  isLessThanMobileBreakpoint: state.browser.lessThan.mobileBreakpoint,
  user: getUser(state),
});

export default connect(mapStateToProps)(Header);
