// @flow

import { all, fork, call, select, takeLatest, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions, types } from 'modules/patient/actions';
import { selectors as userSelectors } from 'modules/user/reducer';
import { selectors as patientSelectors } from 'modules/patient/reducer';

import watchIdentificationActionRequests from './identification/saga';
import watchPatientInfoRequests from './patientInfo/saga';
import watchPatientAccountRequests from './patientAccount/saga';

function* fetchRelativesRequest(): Saga<void> {
  try {
    const relatives = yield select(patientSelectors.getRelatives);
    if (relatives.length === 0) {
      const patientId = yield select(userSelectors.getUserId);
      const res = yield call(api.get, `patients/${patientId}/relatives`);

      yield put(actions.fetchRelativesSuccess(res.data));
    } else {
      yield put(actions.fetchRelativesSuccess(relatives));
    }
  } catch (error) {
    logException(error, `fetch patient relatives request failed ${error}`);
  }
}

function* fetchPatientPartnersRequest(): Saga<void> {
  try {
    const patientPractices = yield select(patientSelectors.getPatientPractices);
    if (patientPractices.length === 0) {
      const patientId = yield select(userSelectors.getUserId);
      const res = yield call(api.get, `patients/${patientId}/patientPractices`);

      yield put(actions.fetchPatientPartnersSuccess(res.data));
    } else {
      yield put(actions.fetchPatientPartnersSuccess(patientPractices));
    }
  } catch (error) {
    logException(error, `fetch patient partners request failed ${error}`);
  }
}

function* watchPatientRequests() {
  yield takeLatest(types.PATIENT_FETCH_RELATIVES_REQUEST, fetchRelativesRequest);
  yield takeLatest(types.PATIENT_FETCH_PATIENT_PRACTICES_REQUEST, fetchPatientPartnersRequest);
}

export default function* rootSaga(): Saga<void> {
  yield all([
    fork(watchIdentificationActionRequests),
    fork(watchPatientInfoRequests),
    fork(watchPatientAccountRequests),
    fork(watchPatientRequests),
  ]);
}
