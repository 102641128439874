import universal from 'react-universal-component';

const onError = () => {
  if (typeof window !== 'undefined') {
    return window.location.reload();
  }
  return {};
};

const UniversalComponent = universal(props => import(`async/${props.page}`), {
  onError,
  ignoreBabelRename: true,
});

export default UniversalComponent;
