// @flow

import type { ExtractReturn } from 'types';

export const types = {
  AVAILABLE_TIME_SLOTS_FETCH: 'AVAILABLE_TIME_SLOTS_FETCH',
  AVAILABLE_TIME_SLOTS_FETCHED: 'AVAILABLE_TIME_SLOTS_FETCHED',
  SPECIALIST_BOOKING_REASON_LIST_FETCH: 'SPECIALIST_BOOKING_REASON_LIST_FETCH',
  SPECIALIST_BOOKING_REASON_LIST_FETCHED: 'SPECIALIST_BOOKING_REASON_LIST_FETCHED',
};

const fetchAvailableTimeSlots = (
  apiType: string,
  groupId: number,
  calendarIds: Array<number>,
  interventionId: ?string = '',
  startDate: ?Date = new Date(),
  spinnerId: ?string = ''
) => ({
  type: types.AVAILABLE_TIME_SLOTS_FETCH,
  apiType,
  groupId,
  calendarIds,
  startDate,
  interventionId,
  spinnerId,
});

const fetchAvailableBookingReasonList = (
  apiType: string,
  groupId: number,
  calendarIds: Array<number>,
  spinnerId: string = ''
) => ({
  type: types.SPECIALIST_BOOKING_REASON_LIST_FETCH,
  apiType,
  groupId,
  calendarIds,
  spinnerId,
});

export type FetchAvailableTimeSlotsActionFlowType = ExtractReturn<typeof fetchAvailableTimeSlots>;
export type FetchAvailableBookingReasonListActionFlowType = ExtractReturn<typeof fetchAvailableBookingReasonList>;

export type BookingCalendarActions =
  | FetchAvailableTimeSlotsActionFlowType
  | FetchAvailableBookingReasonListActionFlowType;

export const actions = {
  fetchAvailableTimeSlots,
  fetchAvailableBookingReasonList,
};
