import { connect } from 'react-redux';

import { selectors } from 'modules/common/reducer';
import ToastPanel from './ToastPanel';

const mapStateToProps = state => ({
  toast: selectors.getToast(state),
});

export default connect(mapStateToProps)(ToastPanel);
