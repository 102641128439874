// @flow

export default {
  url: 'https://www.findoc.co.uk',
  name: 'Findoc',
  analytics: {
    google: {},
  },
  email: {
    contact: 'contact@findoc.co.uk',
  },
  language: {
    default: 'en',
  },
  currency: {
    code: 'GBP',
    minimumFractionDigits: 2,
  },
  format: {
    phoneNumber: 'NNN NNNN NNNN',
  },
};
