import { connect } from 'react-redux';

import { selectors } from 'modules/modal/reducer';
import Modal from './Modal';

const mapStateToProps = state => ({
  modal: selectors.getModal(state),
});

export default connect(mapStateToProps)(Modal);
