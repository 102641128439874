// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import type { AdminSpecialistFlowType } from 'modules/admin/reducer';
import { AdminSpecialistPropType } from 'modules/admin/reducer';
import ModalCard from 'components/modalCard';
import type { GetSpecialistDetailRequest } from 'modules/admin/actions';
import { actions } from 'modules/admin/actions';
import SpecialistForm from '../SpecialistForm';

type Props = {
  specialistRow: AdminSpecialistFlowType,
  dispatch: Dispatch<GetSpecialistDetailRequest>,
};

class UpdateSpecialistModal extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch(actions.getSpecialistDetailRequest(this.props.specialistRow));
  }

  render() {
    const { specialistRow } = this.props;
    return (
      <ModalCard title={`Edit specialist ${specialistRow.firstName} ${specialistRow.lastName}`}>
        <SpecialistForm onSubmit={actions.updateSpecialistRequest} />
      </ModalCard>
    );
  }
}

UpdateSpecialistModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  specialistRow: AdminSpecialistPropType,
};

export default connect()(UpdateSpecialistModal);
