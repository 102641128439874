// @flow

import React from 'react';
import type { Node } from 'react';
import type { FieldRenderProps } from 'react-final-form';

type Props = {
  disabled?: boolean,
  className?: string,
  children: Node,
  onChange: (event: SyntheticInputEvent<*>, value: string) => void,
} & FieldRenderProps;

class SelectField extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    className: '',
  };

  onChange = (event: SyntheticInputEvent<*>) => {
    // call default onChange to update the form
    this.props.input.onChange(event);
    if (this.props.onChange) {
      this.props.onChange(event, event.target.value);
    }
  };

  render() {
    const { children, input, className, meta, disabled } = this.props;

    const touched = meta ? meta.touched : false;
    const error = meta ? meta.error : false;

    return (
      <div>
        <div className="select is-fullwidth">
          <select
            {...input}
            className={`${className || ''} ${touched && error ? 'is-danger' : ''}`}
            disabled={disabled}
            onChange={this.onChange}
          >
            {children}
          </select>
        </div>
        {touched && error && <span className="has-text-danger has-text-weight-normal">{error}</span>}
      </div>
    );
  }
}

export default SelectField;
