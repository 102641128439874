import { takeLatest, call, put } from 'redux-saga/effects';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { types } from 'modules/admin/actions';

export function* getSpecialistReviewsRequest({ page, pageSize, sorted, filtered }) {
  yield put(commonActions.resetSpinner());
  if (!filtered.length && !sorted) {
    yield put(commonActions.showSpinner());
  }

  try {
    const {
      data: { count },
    } = yield call(api.get, 'specialistReviews/count');

    const filter = {
      skip: page > 0 ? page * pageSize : 0,
      limit: pageSize,
    };
    if (sorted) {
      filter.order = `${sorted.id} ${sorted.desc ? 'DESC' : 'ASC'}`;
    }

    if (filtered && filtered.length) {
      filter.where = {};
      filtered.forEach(item => {
        filter.where[item.id] = `${encodeURIComponent(item.value)}%25`;
      });
    }

    const response = yield call(api.get, `specialistReviews/adminSearch?filter=${JSON.stringify(filter)}`);
    yield put({
      type: types.ADMIN_SPECIALIST_REVIEW_GET,
      payload: {
        rows: response.data,
        pages: Math.ceil(count / pageSize),
      },
    });
  } catch (error) {
    logException(error, `getSpecialistReviewsRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* updateSpecialistReviewRequest({ id, approved }) {
  try {
    yield call(api.patch, `specialistReviews/${id}`, { approved });
    yield put({
      type: types.ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE,
      payload: {
        id,
        approved,
      },
    });
    yield put(commonActions.toastSuccess(i18n._(t`Review has been successfully updated.`)));
  } catch (error) {
    logException(error, `updating review approve request failed ${error}`);
    yield put(commonActions.toastDanger(i18n._(t`Sorry, updating review has failed.`)));
  }
}

// Saga Helper
export default function* watchAdminSpecialistActionRequests() {
  yield takeLatest(`${types.ADMIN_SPECIALIST_REVIEW_GET}_REQUEST`, getSpecialistReviewsRequest);
  yield takeLatest(`${types.ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE}_REQUEST`, updateSpecialistReviewRequest);
}
