import axios from 'axios';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import queryString from 'query-string';

import { actions as commonActions } from 'modules/common/actions';
import logException from 'utils/logException';
import { isoDate } from 'utils/datetime';
import { types } from './actions';

export function* fetchAvailableTimeSlotsRequest({
  apiType,
  groupId,
  calendarIds,
  startDate,
  interventionId,
  spinnerId,
}) {
  yield put(commonActions.showSpinner(`calendar_${spinnerId}`));

  let res = {
    firstSlot: '',
    availabletimeslots: [],
  };
  // Don't do any request if no apiType
  if (!apiType) {
    const timeslotsObj = {};
    calendarIds.forEach(reqId => {
      timeslotsObj[reqId] = {
        firstSlot: '',
        availabilities: [],
      };
    });
    yield put({
      type: types.AVAILABLE_TIME_SLOTS_FETCHED,
      payload: timeslotsObj,
    });
  } else {
    try {
      const url = `/partners/${apiType}/groups/${groupId}/availabletimeslots.json?`;
      const params = {
        'calendar_ids[]': calendarIds,
        start: isoDate(startDate),
        nDays: 90,
      };

      if (interventionId) {
        params['intervention_ids[]'] = interventionId;
      }
      const { data } = yield call(axios.get, url + queryString.stringify(params));
      res = data;
    } catch (error) {
      logException(error, `fetch available timeslots request failed ${error}`);
    }

    const timeslotsObj = {};
    calendarIds.forEach(calendarId => {
      const timeslotsWithReqId = res.availabletimeslots.filter(slot => slot.calendar_ids.indexOf(calendarId) !== -1);
      const availabilitiesWithId = timeslotsWithReqId.map(item => item.start);
      timeslotsObj[calendarId] = {
        interventionId,
        firstSlot: res.firstSlot,
        availabilities: availabilitiesWithId,
      };
    });

    yield put({
      type: types.AVAILABLE_TIME_SLOTS_FETCHED,
      payload: timeslotsObj,
    });
  }
  yield put(commonActions.hideSpinner(`calendar_${spinnerId}`));
}

export function* fetchAvailableBookingReasonListRequest({ apiType, groupId, calendarIds, spinnerId }) {
  try {
    // last check for practices or specialist without any calendar (array with first element === null)
    if (apiType && calendarIds && calendarIds.length > 0 && calendarIds[0] !== null) {
      yield put(commonActions.resetSpinner(`calendar_${spinnerId}`));
      yield put(commonActions.showSpinner(`calendar_${spinnerId}`));

      const request = calendarIds.map(calendarId =>
        call(
          axios.get,
          `/partners/${apiType}/groups/${groupId}/interventions.json?calendar_ids[]=${calendarId}&results=all`
        )
      );

      const response = yield all(request);

      const reasons = response.map((item, index) => {
        const { records } = item.data;
        const data = {};
        data[calendarIds[index]] = records.map(record => ({
          id: record.id,
          publicname: record.publicname,
        }));

        return data;
      });
      const reasonsObj = Object.assign({}, ...reasons);
      yield put({
        type: types.SPECIALIST_BOOKING_REASON_LIST_FETCHED,
        payload: { ...reasonsObj },
      });
    }
  } catch (error) {
    logException(error, `fetch available reasons request failed ${error}`);
  }
  yield put(commonActions.hideSpinner(`calendar_${spinnerId}`));
}

// Saga Helper
export default function* watchSpecialistActionRequests() {
  yield takeEvery(types.AVAILABLE_TIME_SLOTS_FETCH, fetchAvailableTimeSlotsRequest);
  yield takeEvery(types.SPECIALIST_BOOKING_REASON_LIST_FETCH, fetchAvailableBookingReasonListRequest);
}
