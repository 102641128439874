import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { t, Trans } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import { reduxPromiseListener } from 'redux/buildStore';
import { createValidator, required } from 'utils/validator';
import { actions as specializationActions, types } from 'modules/specializations/actions';
import WastonSelectCreatableField from 'components/form/WastonSelectCreatableField';

class SpecializationForm extends React.Component {
  constructor(props) {
    super(props);

    this.specializationTimeout = null;
  }

  handleSpecializationsSearch = (input, cb) => {
    if (this.specializationTimeout) {
      clearTimeout(this.specializationTimeout);
    }

    this.specializationTimeout = setTimeout(() => {
      this.props.dispatch(specializationActions.searchSpecializations(input, cb));
    }, 500);
  };

  render() {
    return (
      <MakeAsyncFunction
        listener={reduxPromiseListener}
        start={types.CREATE_SPECIALIZATION_REQUEST}
        resolve={types.CREATE_SPECIALIZATION_SUCCESS}
        reject={types.CREATE_SPECIALIZATION_ERROR}
      >
        {asyncFunc => (
          <Form
            onSubmit={asyncFunc}
            validate={createValidator({
              specialization: [required],
            })}
            render={({ handleSubmit, submitError, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                {submitError && <div className="has-text-centered has-text-danger mb-20">{submitError}</div>}
                <div className="field">
                  <label className="label" htmlFor="specialization">
                    <Trans>Specialization</Trans>
                  </label>
                  <div className="control">
                    <Field
                      component={WastonSelectCreatableField}
                      fetchAction={this.handleSpecializationsSearch}
                      className="input is-small"
                      name="specialization"
                      id="specialization"
                      placeholder={i18n._(t`Search specialization`)}
                    />
                  </div>
                </div>
                <button
                  className="button is-primary is-fullwidth fontweight-700"
                  value={submitting ? i18n._(t`SUBMITTING`) : i18n._(t`CONTINUE`)}
                  disabled={pristine || submitting}
                >
                  <Trans>Create</Trans>
                </button>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    );
  }
}

SpecializationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default SpecializationForm;
