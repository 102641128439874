function trackGoogleAnalytics(...params) {
  // return if GoogleAnalytics tracking is disabled
  if (!window.ga) return;

  window.ga(() => {
    const trackerName = window.ga.getAll()[0].get('name');
    window.ga(`${trackerName}.send`, 'event', ...params);
  });
}

function trackFacebookPixel(...params) {
  // return if FacebookPixel tracking is disabled
  if (!window.fbq) return;

  window.fbq('track', params[1]);
}

export default (...params) => {
  trackGoogleAnalytics(...params);
  trackFacebookPixel(...params);
};
