import { types } from './actions';

export const initialState = {
  ID: 0,
  comments: [],
};

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.BLOG_DETAIL_FETCH:
      return {
        ...initialState,
        ...payload,
      };

    case types.BLOG_DETAIL_POST_COMMENT:
      return {
        ...appState,
        comments: [payload, ...appState.comments],
      };

    default:
      return appState;
  }
};
