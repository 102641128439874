/* eslint jsx-a11y/label-has-for: 0 */
/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { createValidator, required, email } from 'utils/validator';
import { actions } from 'modules/admin/actions';
import ModalCard from 'components/modalCard';
import InputText from 'components/form/inputText';
import Tooltip from 'components/tooltip';
import InputCheckbox from 'components/form/inputCheckbox';

class UpdateUserModal extends React.Component {
  submitUpdateUserModal = form => this.props.dispatch(actions.updateUser(form.id, form));

  render() {
    const { error, userRow } = this.props;

    return (
      <ModalCard title={`Edit user ${userRow.email}`}>
        <Form
          onSubmit={this.submitUpdateUserModal}
          initialValues={userRow}
          validate={createValidator({
            email: [required, email],
            emailClicrdv: [email],
          })}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {error && <div className="has-text-centered has-text-danger mb-20">{error}</div>}
              <div className="pb-25">
                <div className="field">
                  <label className="label">
                    Email
                    <Field component={InputText} name="email" label="Email*" />
                  </label>
                </div>

                <div className="field">
                  <label className="label">
                    Clicrdv email
                    <Field component={InputText} name="emailClicrdv" label="" />
                  </label>
                </div>

                <div className="field">
                  <label className="label">
                    Clicrdv Password
                    <span className="pull-right">
                      <Tooltip
                        position="left"
                        text="This is hashed password. In case you need to add a new password, enter the new password and check hash password checkbox"
                      >
                        ?
                      </Tooltip>
                    </span>
                    <Field component={InputText} name="clicrdv" label="" />
                  </label>
                </div>

                <div className="field">
                  <div className="control">
                    <Field type="checkbox" component={InputCheckbox} name="is_hash">
                      Hash password
                    </Field>
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    Cliniko Key
                    <Field component={InputText} name="clinikoKey" label="" />
                  </label>
                </div>
              </div>
              <button className="button is-primary is-fullwidth fontweight-700">Update</button>
            </form>
          )}
        />
      </ModalCard>
    );
  }
}

UpdateUserModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  userRow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    emailClicrdv: PropTypes.string,
    clicrdv: PropTypes.string,
    clinikoKey: PropTypes.string,
  }),
};

export default connect()(UpdateUserModal);
