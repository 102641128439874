import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';

export const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return i18n._(t`Invalid email address`);
  }
  return '';
}

export function price(value) {
  if (!isEmpty(value)) {
    if (value.length >= 11) {
      return i18n._(t`Maximum allowed price is 99999999.99`);
    }
    if (/^\d+,\d+$/.test(value)) {
      return i18n._(t`Use point instead of comma as a decimal point`);
    }
    if (!/^\d+\.?\d*$/.test(value)) {
      return i18n._(t`Price must be digits`);
    }
    if (!/^\d+\.?\d?\d?$/.test(value)) {
      return i18n._(t`Price may have only two digits after a decimal point`);
    }
  }
  return '';
}

export function url(str) {
  if (!str) {
    return '';
  }
  // disable eslint no-irregular-whitespace for regex doesn't work
  // eslint-disable-next-line no-irregular-whitespace
  const pattern = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9]{2,256}\.[a-z‌A-Z​]{2,6}(\.[a-z‌A-Z​]{2,6})?/g;
  return pattern.test(str) ? '' : i18n._(t`Invalid Url`);
}

export function required(value) {
  if (isEmpty(value)) {
    return i18n._(t`Required`);
  }
  return '';
}

// used to validate checkbox
export function checked(value) {
  if (isEmpty(value) || value === 0) {
    return i18n._(t`Required`);
  }
  return '';
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return i18n._(t`Must be at least ${min} characters`);
    }
    return '';
  };
}

export function equalWith(field, label) {
  return (value, data) => {
    if (value !== data[field]) {
      return i18n._(t`Must be matched with ${label || field}`);
    }
    return '';
  };
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return i18n._(t`Must be no more than ${max} characters`);
    }
    return '';
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return i18n._(t`Must be number`);
  }
  return '';
}

export function oneOf(enumeration) {
  return value => {
    // Originally if (!~enumeration.indexOf(value)) {
    if (enumeration.indexOf(value) === -1) {
      return i18n._(t`Must be one of: ${enumeration.join(', ')}`);
    }
    return '';
  };
}

export function match(field) {
  return (value, data) => {
    if (data && value !== data[field]) {
      return i18n._(t`Do not match`);
    }
    return '';
  };
}

export function regx(pattern, options, error) {
  return value => {
    const exp = new RegExp(pattern, options);
    if (!exp.test(value)) {
      return error || i18n._(t`Pattern do not match.`);
    }
    return '';
  };
}

function validate(data, rules) {
  const errors = {};
  Object.keys(rules).forEach(key => {
    const val = get(data, key);
    const rule = rules[key];
    if (isFunction(rule) && rule.name === 'validator') {
      errors[key] = rule(val);
    } else {
      const v = join([].concat(rule));
      const error = v(val, data);
      if (error) {
        errors[key] = error;
      }
    }
  });
  return errors;
}

export function createValidator(rules) {
  function validator(data) {
    return validate(data, rules);
  }
  return validator;
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
