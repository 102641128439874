// @flow

import type { ReduxStateFlowType } from 'types';
import type { SpecializationActionsFlowType } from './actions';
import { types } from './actions';

export const initialState = {
  specializations: [],
};

export type SpecializationFlowType = {|
  id: number,
  name: string,
|};

export type SpecializationsFlowType = {|
  specializations: Array<SpecializationFlowType>,
|};

export type SpecializationsStoreFlowType = {
  ...SpecializationsFlowType,
};

export default function reducer(
  appState: SpecializationsStoreFlowType = initialState,
  action: SpecializationActionsFlowType
) {
  switch (action.type) {
    case types.FETCH_SPECIALIZATIONS_SUCCESS: {
      return {
        ...appState,
        specializations: action.payload,
      };
    }

    case types.CREATE_SPECIALIZATION_SUCCESS:
      return {
        ...appState,
        specializations: [action.payload, ...appState.specializations],
      };

    default:
      return appState;
  }
}

export const selectors = {
  getSpecializations: (store: ReduxStateFlowType) => store.specialization.specializations,
};
