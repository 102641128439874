import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './Footer.scss';

const Footer = () => (
  <footer className={`${styles.footer} footer pb-20 pt-20`}>
    <div className={classNames('container', styles.container)}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftFooter}>
          <Link to="/" className={classNames('is-hidden-mobile', styles.footerLogo)}>
            <img src="/images/logo/findoc_logo_blue.svg" alt="footer logo" />
          </Link>
          <h6 className="pl-10 has-text-primary-dark">
            <Trans>
              <a href="/pdf/privacy_policy_and_data_security.pdf" className="has-text-primary-dark">
                Privacy Policy & Data Security
              </a>{' '}
              and{' '}
              <a href="/pdf/terms_of_use.pdf" className="has-text-primary-dark">
                Terms of Use
              </a>
            </Trans>{' '}
            © {new Date().getFullYear()}
          </h6>
        </div>
        <div className={styles.rightFooter}>
          <div className={styles.star}>
            <img src="/images/partner/GDPR@1x.png" alt="GDPR logo" />
          </div>
          <div className={styles.star}>
            <img src="/images/partner/Trustpilot@1x.png" alt="Trustpilot Logo" />
          </div>
          <div className={styles.social}>
            <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/FindocUK">
              <img src="/images/social/facebook.svg" alt="Facebook logo" />
            </a>
            <a target="_blank" rel="noreferrer noopener" href="https://instagram.com/findocuk">
              <img src="/images/social/instagram.svg" alt="Instragram logo" />
            </a>
            <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/findocuk">
              <img src="/images/social/twitter.svg" alt="Twitter logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
