import { takeLatest, call, put, select } from 'redux-saga/effects';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import logException from 'utils/logException';
import { types } from './actions';
import { selectors } from './reducer';

export function* fetchSpecialistRequest({ slug, specialization }) {
  yield put(commonActions.resetSpinner());

  const cachedSpecialist = yield select(selectors.getSpecialist);
  if (
    cachedSpecialist &&
    cachedSpecialist.slug === slug &&
    cachedSpecialist.specialization.toLowerCase() === specialization
  ) {
    return;
  }
  yield put(commonActions.showSpinner());
  try {
    const query = {
      include: [
        'practices',
        'languages',
        {
          specialistPractice: ['services'],
        },
        {
          relation: 'reviews',
          scope: {
            where: { approved: 1 },
          },
        },
      ],
      where: {
        and: [{ slug }],
      },
    };

    // add condition if speciality is composed of two words
    if (specialization && specialization.indexOf('-') === -1) {
      query.where.and.push({
        specialization: encodeURIComponent(specialization),
      });
    } else {
      query.where.and.push({
        or: [
          { specialization: encodeURIComponent(specialization) },
          { specialization: encodeURIComponent(specialization.replace(/-/g, ' ')) },
        ],
      });
    }

    const response = yield call(api.get, `specialists?filter=${JSON.stringify(query)}`);
    const specialist = response.data[0];

    // merge specialistPractice's calendar id into practices
    if (specialist) {
      specialist.practices = specialist.practices.map(practice => {
        const { clicrdvCalendarId } = specialist.specialistPractice.find(item => item.practiceId === practice.id) || {};
        return {
          ...practice,
          clicrdvCalendarId,
        };
      });
    }
    yield put({ type: types.SPECIALIST_FETCH, payload: specialist });
  } catch (error) {
    logException(error, `fetchSpecialistRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* updateSpecialistProfileRequest({ id, value }) {
  try {
    const url = `specialists/${id}`;
    const body = { profile: value };
    const response = yield call(api.patch, url, body);
    yield put({ type: types.SPECIALIST_PROFILE_SAVE, payload: response.data });
  } catch (error) {
    logException(error, `updateSpecialistProfile failed ${error}`);
  }
}

export function* loadLanguagesRequest() {
  const cachedLanguageList = yield select(selectors.getLanguageList);
  if (cachedLanguageList && cachedLanguageList.length) return;

  try {
    const response = yield call(api.get, 'languages');
    yield put({
      type: types.SPECIALIST_LANGUAGES_FETCH,
      payload: response.data,
    });
  } catch (error) {
    logException(error, `loadLanguagesRequest failed ${error}`);
  }
}

export function* updateSpecialistLanguagesRequest({ id, languages, resolve, reject }) {
  try {
    const url = `specialists/${id}/updateLanguages`;
    const response = yield call(api.patch, url, languages);

    yield put(modalActions.hideModal());
    yield put({
      type: types.SPECIALIST_LANGUAGES_SAVE,
      payload: response.data,
    });
    if (resolve) resolve();
  } catch (error) {
    logException(error, `updateSpecialistLanguagesRequest failed ${error}`);
    if (reject) reject(error);
  }
}

export function* updateSpecialistServicesRequest({ specialistId, specialistPracticeId, formObj }) {
  try {
    const url = `specialists/${specialistId}/updateServices/${specialistPracticeId}`;
    const response = yield call(api.patch, url, formObj.services);

    yield put({ type: types.SPECIALIST_SERVICES_SAVE, payload: response.data });
    yield put(modalActions.hideModal());
  } catch (error) {
    logException(error, `updateSpecialistServicesRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* updateProfilePictureRequest({ specialistId, pictureData }) {
  try {
    const url = `specialists/${specialistId}/updateProfilePicture`;
    yield call(api.patch, url, {
      pictureData,
    });

    yield put(modalActions.hideModal());
    // refresh page to clear cache
    document.location.reload();
  } catch (error) {
    logException(error, `updateProfilePictureRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* submitReviewRequest({ specialistId, formObj }) {
  try {
    yield call(api.post, `specialists/${specialistId}/reviews`, formObj);

    yield put(modalActions.hideModal());
    yield put(commonActions.toastSuccess(i18n._(t`The review was successfully submitted.`)));
  } catch (error) {
    logException(error, `write review request failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

// Saga Helper
export default function* watchSpecialistActionRequests() {
  yield takeLatest(`${types.SPECIALIST_FETCH}_REQUEST`, fetchSpecialistRequest);
  yield takeLatest(`${types.SPECIALIST_PROFILE_SAVE}_REQUEST`, updateSpecialistProfileRequest);
  yield takeLatest(`${types.SPECIALIST_LANGUAGES_FETCH}_REQUEST`, loadLanguagesRequest);
  yield takeLatest(`${types.SPECIALIST_LANGUAGES_SAVE}_REQUEST`, updateSpecialistLanguagesRequest);
  yield takeLatest(`${types.SPECIALIST_SERVICES_SAVE}_REQUEST`, updateSpecialistServicesRequest);
  yield takeLatest(`${types.SPECIALIST_UPDATE_PROFILE_PICTURE}_REQUEST`, updateProfilePictureRequest);
  yield takeLatest(`${types.SPECIALIST_SUBMIT_REVIEW}_REQUEST`, submitReviewRequest);
}
