// @flow

import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import type { ExtractReturn } from 'types';

export const types = {
  STATUS_CHANGE: 'PAGE/STATUS_CHANGE',

  TOAST_SHOW: 'TOAST_SHOW',
  TOAST_SUCCESS: 'primary',
  TOAST_WARNING: 'warning',
  TOAST_ERROR: 'danger',

  API_STARTED: 'API/STARTED',
  API_FINISHED: 'API/FINISHED',
  API_RESETED: 'API/RESETED',
};

const onPageSetStatus = (status: number) => ({
  type: types.STATUS_CHANGE,
  payload: status,
});

const showSpinner = (trackId: string | number = 'page') => ({
  type: types.API_STARTED,
  payload: trackId,
});

const hideSpinner = (trackId: string | number = 'page') => ({
  type: types.API_FINISHED,
  payload: trackId,
});

const resetSpinner = () => ({
  type: types.API_RESETED,
});

const showToast = (content: string, level: string) => ({
  type: types.TOAST_SHOW,
  payload: {
    content,
    level,
  },
});

const toastSuccess = (content: string = i18n._(t`The action has been successfully processed.`)) =>
  showToast(content, types.TOAST_SUCCESS);
const toastWarning = (content: string) => showToast(content, types.TOAST_WARNING);
const toastDanger = (content: string = i18n._(t`Sorry, an error has occurred while contacting the server.`)) =>
  showToast(content, types.TOAST_ERROR);

export type OnPageSetStatusAction = ExtractReturn<typeof onPageSetStatus>;
export type ShowSpinnerAction = ExtractReturn<typeof showSpinner>;
export type HideSpinnerAction = ExtractReturn<typeof hideSpinner>;
export type ShowToastAction = ExtractReturn<typeof showToast>;
export type ToastSuccessAction = ExtractReturn<typeof toastSuccess>;
export type ToastWarningAction = ExtractReturn<typeof toastWarning>;
export type ToastDangerAction = ExtractReturn<typeof toastDanger>;

export type CommonActionsFlowType =
  | OnPageSetStatusAction
  | ShowSpinnerAction
  | HideSpinnerAction
  | ShowToastAction
  | ToastSuccessAction
  | ToastWarningAction
  | ToastDangerAction;

export const actions = {
  showToast,
  toastSuccess,
  toastWarning,
  toastDanger,
  showSpinner,
  hideSpinner,
  resetSpinner,
  onPageSetStatus,
};
