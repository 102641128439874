// @flow

import type { UserSchemaFlowType, PracticeState, SpecialistState, ExtractReturn } from 'types';
import type { AdminPracticeFlowType, AdminSpecialistFlowType } from 'modules/admin/reducer';

export const types = {
  ADMIN_GET_USERS_REQUEST: 'ADMIN_GET_USERS_REQUEST',
  ADMIN_GET_USERS_SUCCESS: 'ADMIN_GET_USERS_SUCCESS',
  ADMIN_CREATE_USER_REQUEST: 'ADMIN_CREATE_USER_REQUEST',
  ADMIN_CREATE_USER_SUCCESS: 'ADMIN_CREATE_USER_SUCCESS',
  ADMIN_CREATE_USER_ERROR: 'ADMIN_CREATE_USER_ERROR',
  ADMIN_UPDATE_USER_REQUEST: 'ADMIN_UPDATE_USER_REQUEST',
  ADMIN_UPDATE_USER_SUCCESS: 'ADMIN_UPDATE_USER_SUCCESS',
  ADMIN_SPECIALIST_GET_REQUEST: 'ADMIN_SPECIALIST_GET_REQUEST',
  ADMIN_SPECIALIST_GET_SUCCESS: 'ADMIN_SPECIALIST_GET_SUCCESS',
  ADMIN_SPECIALIST_GET_DETAIL_REQUEST: 'ADMIN_SPECIALIST_GET_DETAIL_REQUEST',
  ADMIN_SPECIALIST_GET_DETAIL_SUCCESS: 'ADMIN_SPECIALIST_GET_DETAIL_SUCCESS',
  ADMIN_SPECIALIST_CREATE_REQUEST: 'ADMIN_SPECIALIST_CREATE_REQUEST',
  ADMIN_SPECIALIST_CREATE_SUCCESS: 'ADMIN_SPECIALIST_CREATE_SUCCESS',
  ADMIN_SPECIALIST_UPDATE_REQUEST: 'ADMIN_SPECIALIST_UPDATE_REQUEST',
  ADMIN_SPECIALIST_UPDATE_SUCCESS: 'ADMIN_SPECIALIST_UPDATE_SUCCESS',
  ADMIN_SPECIALIST_REVIEW_GET: 'ADMIN_SPECIALIST_REVIEW_GET',
  ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE: 'ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE',
  ADMIN_SPECIALIST_SEARCH_USERS: 'ADMIN_SPECIALIST_SEARCH_USERS',
  ADMIN_SPECIALIST_SEARCH_PRACTICES: 'ADMIN_SPECIALIST_SEARCH_PRACTICES',
  ADMIN_GET_PRACTICES_REQUEST: 'ADMIN_GET_PRACTICES_REQUEST',
  ADMIN_GET_PRACTICES_SUCCESS: 'ADMIN_GET_PRACTICES_SUCCESS',
  ADMIN_CREATE_PRACTICE_REQUEST: 'ADMIN_CREATE_PRACTICE_REQUEST',
  ADMIN_UPDATE_PRACTICE_SUCCESS: 'ADMIN_UPDATE_PRACTICE_SUCCESS',
  ADMIN_UPDATE_PRACTICE_REQUEST: 'ADMIN_UPDATE_PRACTICE_REQUEST',
  ADMIN_SEARCH_PRACTICE_SPECIALISTS_REQUEST: 'ADMIN_SEARCH_PRACTICE_SPECIALISTS_REQUEST',
  ADMIN_GET_PRACTICE_DETAIL_REQUEST: 'ADMIN_GET_PRACTICE_DETAIL_REQUEST',
  ADMIN_GET_PRACTICE_DETAIL_SUCCESS: 'ADMIN_GET_PRACTICE_DETAIL_SUCCESS',
};

export type AdminPracticeForm = {} & PracticeState;
export type AdminSpecialistFormType = {} & SpecialistState;
export type AdminUserFormFlowType = {
  email: string,
  emailClicrdv: ?string,
  clicrdv: ?string,
  clinikoKey: ?string,
  password: string,
};
export type AdminFormResponseType = $Shape<
  {|
    FORM_ERROR: ?string,
  |} & UserSchemaFlowType
>;

const getUsers = (page: number = 0, pageSize: number = 20, sorted: boolean = false, filtered: Array<{}> = []) => ({
  type: types.ADMIN_GET_USERS_REQUEST,
  payload: {
    page,
    pageSize,
    sorted,
    filtered,
  },
});

const createUserRequest = (formObj: AdminUserFormFlowType) => ({
  type: types.ADMIN_CREATE_USER_REQUEST,
  payload: formObj,
});

const createUserSuccess = (responseData: AdminFormResponseType) => ({
  type: types.ADMIN_CREATE_USER_SUCCESS,
  payload: responseData,
});

const createUserError = (responseData: AdminFormResponseType) => ({
  type: types.ADMIN_CREATE_USER_ERROR,
  payload: responseData,
});

const updateUser = (userId: number, formObj: AdminUserFormFlowType) => ({
  type: types.ADMIN_UPDATE_USER_REQUEST,
  payload: {
    userId,
    formObj,
  },
});

const getSpecialists = (
  page: number = 0,
  pageSize: number = 20,
  sorted: boolean = false,
  filtered: Array<{}> = []
) => ({
  type: types.ADMIN_SPECIALIST_GET_REQUEST,
  page,
  pageSize,
  sorted,
  filtered,
});

const getSpecialistDetailRequest = (row: AdminSpecialistFlowType) => ({
  type: types.ADMIN_SPECIALIST_GET_DETAIL_REQUEST,
  row,
});

const getSpecialistDetailSuccess = (specialist: AdminSpecialistFormType) => ({
  type: types.ADMIN_SPECIALIST_GET_DETAIL_SUCCESS,
  payload: specialist,
});

const createSpecialist = (formObj: AdminSpecialistFormType, successCallback: () => void) => ({
  type: types.ADMIN_SPECIALIST_CREATE_REQUEST,
  formObj,
  successCallback,
});

const updateSpecialistRequest = (formObj: AdminSpecialistFormType) => ({
  type: types.ADMIN_SPECIALIST_UPDATE_REQUEST,
  formObj,
});

const getPracticesRequest = (
  page: number = 0,
  pageSize: number = 20,
  sorted: boolean = false,
  filtered: Array<{}> = []
) => ({
  type: types.ADMIN_GET_PRACTICES_REQUEST,
  payload: {
    page,
    pageSize,
    sorted,
    filtered,
  },
});

const createPracticeRequest = (formObj: AdminPracticeForm, successCallback: () => void) => ({
  type: types.ADMIN_CREATE_PRACTICE_REQUEST,
  payload: {
    formObj,
    successCallback,
  },
});

const updatePracticeRequest = (formObj: AdminPracticeForm, successCallback: () => void) => ({
  type: types.ADMIN_UPDATE_PRACTICE_REQUEST,
  payload: {
    formObj,
    successCallback,
  },
});

const getPracticeDetailRequest = (row: AdminPracticeFlowType) => ({
  type: types.ADMIN_GET_PRACTICE_DETAIL_REQUEST,
  payload: {
    row,
  },
});

const getPracticeDetailSuccess = (practice: PracticeState) => ({
  type: types.ADMIN_GET_PRACTICE_DETAIL_SUCCESS,
  payload: practice,
});

// $FlowFixMe
const searchPracticeSpecialistsRequest = (key, resolve) => ({
  type: types.ADMIN_SEARCH_PRACTICE_SPECIALISTS_REQUEST,
  payload: {
    key,
    resolve,
  },
});

// $FlowFixMe
const getSpecialistReviews = (page = 0, pageSize = 20, sorted = false, filtered = []) => ({
  type: `${types.ADMIN_SPECIALIST_REVIEW_GET}_REQUEST`,
  page,
  pageSize,
  sorted,
  filtered,
});

// $FlowFixMe
const updateSpecialistReviewApprove = (id, approved) => ({
  type: `${types.ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE}_REQUEST`,
  id,
  approved,
});

const searchSpecialistUsers = (key: string, resolve: () => void) => ({
  type: `${types.ADMIN_SPECIALIST_SEARCH_USERS}_REQUEST`,
  key,
  resolve,
});

// $FlowFixMe
const searchSpecialistPractices = (key, resolve) => ({
  type: `${types.ADMIN_SPECIALIST_SEARCH_PRACTICES}_REQUEST`,
  key,
  resolve,
});

export type GetUsersRequestActionFlowType = ExtractReturn<typeof getUsers>;
export type CreateUserRequestActionFlowType = ExtractReturn<typeof createUserRequest>;
export type CreateUserSuccessActionFlowType = ExtractReturn<typeof createUserSuccess>;
export type CreateUserErrorActionFlowType = ExtractReturn<typeof createUserError>;
export type UpdateUserRequestActionFlowType = ExtractReturn<typeof updateUser>;
export type CreatePracticeRequestActionFlowType = ExtractReturn<typeof createPracticeRequest>;
export type UpdatePracticeRequestActionFlowType = ExtractReturn<typeof updatePracticeRequest>;
export type UpdateSpecialistRequestActionFlowType = ExtractReturn<typeof updateSpecialistRequest>;
export type GetPracticesRequestActionFlowType = ExtractReturn<typeof getPracticesRequest>;
export type GetPracticeDetailRequestActionFlowType = ExtractReturn<typeof getPracticeDetailRequest>;
export type GetPracticeDetailSuccess = ExtractReturn<typeof getPracticeDetailSuccess>;
export type GetSpecialistDetailRequest = ExtractReturn<typeof getSpecialistDetailRequest>;
export type GetSpecialistDetailSuccess = ExtractReturn<typeof getSpecialistDetailSuccess>;
export type SearchPracticeSpecialistsRequestActionFlowType = ExtractReturn<typeof searchPracticeSpecialistsRequest>;

export type AdminActionsType =
  | GetUsersRequestActionFlowType
  | CreateUserRequestActionFlowType
  | CreateUserSuccessActionFlowType
  | UpdateUserRequestActionFlowType
  | CreatePracticeRequestActionFlowType
  | UpdatePracticeRequestActionFlowType
  | UpdateSpecialistRequestActionFlowType
  | GetPracticesRequestActionFlowType
  | GetPracticeDetailRequestActionFlowType
  | GetPracticeDetailSuccess
  | GetSpecialistDetailRequest
  | GetSpecialistDetailSuccess;

export const actions = {
  getUsers,
  createUserRequest,
  createUserSuccess,
  createUserError,
  updateUser,

  getSpecialists,
  createSpecialist,
  updateSpecialistRequest,
  searchSpecialistUsers,
  searchSpecialistPractices,
  getSpecialistDetailRequest,
  getSpecialistDetailSuccess,

  getPracticesRequest,
  createPracticeRequest,
  updatePracticeRequest,
  searchPracticeSpecialistsRequest,
  getPracticeDetailRequest,
  getPracticeDetailSuccess,

  getSpecialistReviews,
  updateSpecialistReviewApprove,
};
