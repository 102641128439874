// @flow

import type { ExtractReturn } from 'types';

export const types = {
  PRACTICE_FETCH: 'PRACTICE_FETCH',
  PRACTICE_FETCH_REQUEST: 'PRACTICE_FETCH_REQUEST',
  PRACTICE_ABOUT_SAVE: 'PRACTICE/ABOUT/SAVE',
  PRACTICE_PAYMENTS_METHODS_FETCH: 'PRACTICE/PAYMENTS_METHODS/FETCH',
  PRACTICE_PAYMENTS_METHODS_SAVE: 'PRACTICE/PAYMENTS_METHODS/SAVE',
  PRACTICE_TRANSPORT_SAVE: 'PRACTICE/TRANSPORT/SAVE',
  PRACTICE_UPDATE_PICTURE: 'PRACTICE_UPDATE_PICTURE',
};

const fetchPractice = (slug: string) => ({
  type: types.PRACTICE_FETCH_REQUEST,
  slug,
});

// $FlowFixMe
const updatePracticeAbout = (id, value) => ({
  type: `${types.PRACTICE_ABOUT_SAVE}_REQUEST`,
  id,
  value,
});

const fetchPaymentMethods = () => ({
  type: `${types.PRACTICE_PAYMENTS_METHODS_FETCH}_REQUEST`,
});

// $FlowFixMe
const updatePracticePaymentMethods = (id, paymentMethods) => ({
  type: `${types.PRACTICE_PAYMENTS_METHODS_SAVE}_REQUEST`,
  id,
  paymentMethods,
});

// $FlowFixMe
const updatePracticeTransport = (id, transport) => ({
  type: `${types.PRACTICE_TRANSPORT_SAVE}_REQUEST`,
  id,
  transport,
});

// $FlowFixMe
const updatePracticePicture = (practiceId, pictureData) => ({
  type: `${types.PRACTICE_UPDATE_PICTURE}_REQUEST`,
  practiceId,
  pictureData,
});

export type FetchPracticeActionFlowType = ExtractReturn<typeof fetchPractice>;

export type PracticeActionsFlowType = FetchPracticeActionFlowType;

export const actions = {
  fetchPractice,
  updatePracticeAbout,
  fetchPaymentMethods,
  updatePracticePaymentMethods,
  updatePracticeTransport,
  updatePracticePicture,
};
