import axios from 'axios';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import queryString from 'query-string';

import { BLOG_URL } from 'client/api/api';
import { actions as commonActions } from 'modules/common/actions';
import logException from 'utils/logException';
import { types } from './actions';
import { selectors } from './reducer';

export function* fetchBlogRequest({ keyword, page }) {
  yield put(commonActions.resetSpinner());
  yield put(commonActions.showSpinner());
  try {
    const pageSize = yield select(selectors.getPageSize);
    const params = queryString.stringify({
      offset: page > 1 ? (page - 1) * pageSize : 0,
      posts_per_page: pageSize,
      keyword,
    });
    const res = yield call(axios.get, `${BLOG_URL}/wp-json/api/v1/blogs?${params}`);

    yield put({
      type: types.BLOG_FETCH,
      payload: {
        blogs: res.data.blogs,
        total: res.data.total * 1,
      },
    });
  } catch (error) {
    logException(error, `fetch blogs failed ${error}`);
    yield put({
      type: types.BLOG_FETCH,
      payload: {
        total: 0,
        blogs: [],
      },
    });
  }
  yield put(commonActions.hideSpinner());
}

// Saga Helper
export default function* watchBlogActionRequests() {
  yield takeEvery(`${types.BLOG_FETCH}_REQUEST`, fetchBlogRequest);
}
