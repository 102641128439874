// @flow

import * as React from 'react';
import PropTypes from 'prop-types';

type Props = {
  children: React.Node,
};

class SimpleModal extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return children;
  }
}

SimpleModal.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SimpleModal;
