// @flow

import type { PatientStoreState, ReduxStateFlowType, RelativeState, PatientPracticeState } from 'types';
import type { PatientActions } from './actions';

import { types } from './actions';

export const initialState = {
  patientLoginError: '',
  patientPractices: [],
  relatives: [],
};

export default (appState: PatientStoreState = initialState, action: PatientActions): PatientStoreState => {
  switch (action.type) {
    case types.PATIENT_LOGIN_ERROR:
      return {
        ...appState,
        patientLoginError: action.payload,
      };

    case types.PATIENT_FETCH_RELATIVES_SUCCESS:
      return {
        ...appState,
        relatives: action.payload,
      };

    case types.PATIENT_CREATE_RELATIVE_SUCCESS:
      return {
        ...appState,
        relatives: [action.payload, ...appState.relatives],
      };

    case types.PATIENT_DELETE_RELATIVE_SUCCESS:
      return {
        ...appState,
        // $FlowFixMe
        relatives: appState.relatives.filter(item => item.id !== action.payload),
      };

    case types.PATIENT_FETCH_PATIENT_PRACTICES_SUCCESS:
      return {
        ...appState,
        patientPractices: action.payload,
      };

    default:
      return appState;
  }
};

export const selectors = {
  getRelative: (store: ReduxStateFlowType, relativeId: number) =>
    store.patient.relatives.find(relative => relative.id === relativeId) || {},
  getRelatives: (store: ReduxStateFlowType): Array<RelativeState> => store.patient.relatives,
  getPatientPractices: (store: ReduxStateFlowType): Array<PatientPracticeState> => store.patient.patientPractices,
  getPatientPracticePatient: (store: ReduxStateFlowType, patientId: number, apiType: string, practiceId: number) =>
    store.patient.patientPractices.find(
      patientPartner =>
        patientPartner.patientId === patientId &&
        patientPartner.partnerName === apiType &&
        patientPartner.practiceId === practiceId
    ) || {},
  getPatientPracticeRelative: (store: ReduxStateFlowType, relativeId: number, apiType: string, practiceId: number) =>
    store.patient.patientPractices.find(
      patientPartner =>
        patientPartner.relativeId === relativeId &&
        patientPartner.partnerName === apiType &&
        patientPartner.practiceId === practiceId
    ) || {},
};
