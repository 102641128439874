// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import 'isomorphic-fetch';
import Raven from 'raven-js';
import type { Catalog } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { i18n } from 'utils/i18n';
import Layout from 'modules/layout';
import routes from 'client/routing/routes';
import * as storage from 'utils/storage';
import buildStore from 'redux/buildStore';
import rootSaga from 'redux/rootSaga';

import '../styles/bulma.scss';
import '../styles/helpers.scss';

const history = createHistory();
// eslint-disable-next-line no-underscore-dangle
const initialState = window.__INITIAL_STATE__;
const user = storage.getUser();
const store = buildStore(history, {
  ...initialState,
  user: {
    ...initialState.user,
    ...user,
  },
});
// $FlowFixMe
store.runSaga(rootSaga);

if (user.userId) {
  Raven.setUserContext({
    id: user.userId,
  });
}

const renderApp = TheLayout => {
  const appDOM = document.getElementById('app');
  if (appDOM) {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <I18nProvider i18n={i18n}>
            <ConnectedRouter history={history}>
              <TheLayout routes={routes} initialState={initialState} />
            </ConnectedRouter>
          </I18nProvider>
        </Provider>
      </AppContainer>,
      appDOM
    );
  } else {
    throw new Error('no app element');
  }
};

export default function(catalog: Catalog, locale: string) {
  i18n.load({ [locale]: catalog });
  i18n.activate(locale);

  renderApp(Layout);
}

// $FlowFixMe
if (module.hot) {
  /* eslint-disable global-require */
  module.hot.accept('modules/layout', () => {
    const newLayout = require('modules/layout').default;
    renderApp(newLayout);
  });
}

// $FlowFixMe
if (module.hot) {
  // not sure if all modules shouldn't be watched for, instead of just rootSaga
  module.hot.accept('redux/rootSaga', () => {
    // Dumm hot module replacement of sagas (running sagas are just cancelled)
    // $FlowFixMe
    store.closeSagas();
    // $FlowFixMe
    store.runSaga(require('redux/rootSaga').default);
  });
  /* eslint-enable global-require */
}
