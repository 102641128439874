import axios from 'axios';
import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';

import { BLOG_URL } from 'client/api/api';
import { savePoster } from 'utils/storage';
import { actions as commonActions } from 'modules/common/actions';
import logException from 'utils/logException';
import { types } from './actions';

export function* fetchBlogDetailRequest({ slug }) {
  yield put(commonActions.resetSpinner());
  yield put(commonActions.showSpinner());
  try {
    const res = yield call(axios.get, `${BLOG_URL}/wp-json/api/v1/blog-detail?slug=${slug}`);

    yield put({
      type: types.BLOG_DETAIL_FETCH,
      payload: res.data,
    });
  } catch (error) {
    logException(error, `fetch blogs failed ${error}`);
    yield put({
      type: types.BLOG_DETAIL_FETCH,
      payload: {
        ID: -1,
      },
    });
  }
  yield put(commonActions.hideSpinner());
}

export function* postCommentRequest({ postId, parentId, formObj }) {
  try {
    const res = yield call(axios.post, `${BLOG_URL}/wp-json/api/v1/post-comment`, {
      postId,
      parentId,
      ...formObj,
    });

    // save detail
    savePoster({
      email: formObj.email,
      name: formObj.name,
    });

    yield put({
      type: types.BLOG_DETAIL_POST_COMMENT,
      payload: res.data,
    });
  } catch (error) {
    logException(error, `fetch blogs failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

// Saga Helper
export default function* watchBlogDetailActionRequests() {
  yield takeEvery(`${types.BLOG_DETAIL_FETCH}_REQUEST`, fetchBlogDetailRequest);
  yield takeLatest(`${types.BLOG_DETAIL_POST_COMMENT}_REQUEST`, postCommentRequest);
}
