/**
 * SpecialistTitleHelper class describes a helper methods for getting of specialist title.
 */
export default class SpecialistTitleHelper {
  /**
   * This method returns the specialist title;
   *
   * @param {*} specialist
   */
  static getTitle(specialist) {
    return (specialist && (specialist.title || this.getDefaultTitle(specialist))) || '';
  }

  /**
   * This method returns a town.
   *
   * @param {*} specialist
   */
  static getTown(specialist) {
    return (
      (specialist &&
        (specialist.town ||
          (specialist.practices && specialist.practices.length > 0 && specialist.practices[0].town))) ||
      ''
    );
  }

  /**
   * This method returns a borough.
   *
   * @param {*} specialist
   */
  static getBorough(specialist) {
    return (
      (specialist &&
        (specialist.borough ||
          (specialist.practices && specialist.practices.length > 0 && specialist.practices[0].borough))) ||
      ''
    );
  }

  /**
   * This method returns a default title.
   *
   * @param {*} specialist
   */
  static getDefaultTitle(specialist) {
    if (!specialist) {
      return '';
    }

    const { firstName, lastName, specialization } = specialist;
    const town = this.getTown(specialist);
    const borough = this.getBorough(specialist);

    let title = '';

    title += firstName || lastName ? `${firstName} ${lastName}, ` : '';

    if (specialization) {
      title += `${specialization}`;

      if (borough && town) title += ` in ${borough}, ${town}`;
      else if (borough) title += ` in ${borough}`;
      else if (town) title += ` in ${town}`;
    }

    return title;
  }
}
