// @flow

import React from 'react';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import type { Dispatch } from 'redux';
import type { FormApi } from 'final-form';

import * as api from 'client/api/api';
import type { AdminPracticeDetailFlowType } from 'modules/admin/reducer';
import ProfileLink from 'components/link/ProfileLink';
import { toDashed } from 'utils/string';
import { createValidator, required, url, email } from 'utils/validator';
import InputText from 'components/form/inputText';
import SelectField from 'components/form/selectField';
import InputCheckbox from 'components/form/inputCheckbox';
import WastonSelectField from 'components/form/WastonSelectField';
import Tooltip from 'components/tooltip';
import { actions as specializationActions } from 'modules/specializations/actions';
import type { SpecializationActionsFlowType } from 'modules/specializations/actions';
import { actions as adminActions } from 'modules/admin/actions';
import type {
  AdminActionsType,
  AdminPracticeForm,
  CreatePracticeRequestActionFlowType,
  UpdatePracticeRequestActionFlowType,
} from 'modules/admin/actions';
import * as constants from 'modules/practice/constants';
import MapWithASearchBox from './MapWithASearchBox';
import SpecialistsFieldArray from './SpecialistsFieldArray';
import styles from './PracticeForm.scss';

type Specialization = {
  id: number,
  name: string,
};

type Props = {
  specializations: Array<Specialization>,
  isCreate: boolean,
  dispatch: Dispatch<SpecializationActionsFlowType | AdminActionsType>,
  onSubmit: CreatePracticeRequestActionFlowType | UpdatePracticeRequestActionFlowType,
  practiceDetail: AdminPracticeDetailFlowType,
};

const calculator = createDecorator({
  field: 'name',
  updates: {
    slug: name => toDashed(name || ''),
  },
});

class PracticeForm extends React.Component<Props> {
  slugTimeout: TimeoutID;

  userTimeout: TimeoutID;

  specialistTimeout: TimeoutID;

  static defaultProps = {
    isCreate: false,
  };

  constructor(props: Props) {
    super(props);

    props.dispatch(specializationActions.fetchSpecializationsRequest());
  }

  submitForm = (formObj: AdminPracticeForm, formAPI: FormApi) => {
    const successCallback = formAPI.reset;
    this.props.dispatch(this.props.onSubmit(formObj, successCallback));
  };

  handleUserSearch = (input: string, cb: () => void) => {
    // do validation when user stops typing
    if (this.userTimeout) {
      clearTimeout(this.userTimeout);
    }
    this.userTimeout = setTimeout(() => {
      this.props.dispatch(adminActions.searchSpecialistUsers(input, cb));
    }, 500);
  };

  handleSpecialistSearch = (input: string, cb: () => void) => {
    // do validation when user stops typing
    if (this.specialistTimeout) clearTimeout(this.specialistTimeout);
    this.specialistTimeout = setTimeout(() => {
      this.props.dispatch(adminActions.searchPracticeSpecialistsRequest(input, cb));
    }, 500);
  };

  slugAsyncValidation = (slug: ?string) => {
    if (!slug || !this.props.isCreate) return '';
    const filter = {
      where: {
        slug: { like: encodeURIComponent(slug) },
        level: { gt: -1 },
      },
    };
    return api.get(`practices?filter=${JSON.stringify(filter)}`).then(response => {
      const { data } = response;
      if (data.length > 0) {
        return data.map(item => (
          <div key={item.id}>
            <ProfileLink profileType="practice" town={item.town} borough={item.borough} slug={item.slug}>
              {item.name} ({item.slug}){item.specialization}
            </ProfileLink>
            <br />
          </div>
        ));
      }
      return '';
    });
  };

  render() {
    const { specializations, isCreate, practiceDetail } = this.props;
    const coordinations = {
      latitude: practiceDetail ? practiceDetail.latitude : '',
      longitude: practiceDetail ? practiceDetail.longitude : '',
    };
    const decorator = isCreate ? calculator : createDecorator();

    return (
      <Form
        onSubmit={this.submitForm}
        decorators={[decorator]}
        initialValues={this.props.practiceDetail}
        mutators={{
          setPracticeCoordinations: (args, state, utils) => {
            const coords = args[0];
            utils.changeValue(state, 'latitude', () => coords.lat());
            utils.changeValue(state, 'longitude', () => coords.lng());
          },
          setPracticeLocation: (args, state, utils) => {
            const detail = args[0];
            const town = detail.town ? detail.town : '';
            const borough = detail.town ? detail.borough : '';
            utils.changeValue(state, 'town', () => town);
            utils.changeValue(state, 'borough', () => borough);
          },
          ...arrayMutators,
        }}
        validateOnBlur
        validate={createValidator({
          name: [required],
          slug: [required],
          town: [required],
          latitude: [required],
          longitude: [required],
          borough: [required],
          website: [url],
          email: [email],
        })}
        render={({ handleSubmit, form, errors, submitFailed }) => (
          <form className={styles.container} onSubmit={handleSubmit}>
            {submitFailed}
            {submitFailed &&
              (errors && (errors.latitude || errors.longitude)) && (
                <div className="has-text-danger">Please select a location on map to set latitude and longitude.</div>
              )}
            <div className="hidden">
              <Field type="hidden" component="input" name="latitude" />
              <Field type="hidden" component="input" name="longitude" />
            </div>

            <div className="pb-25">
              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Name * <Field component={InputText} name="name" label="Name *" />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Slug *{' '}
                    <Tooltip
                      position="left"
                      text={
                        'A URL slug is the exact address of a specific page on our site. ' +
                        'It cannot be changed after the practice is created.'
                      }
                      className="is-pulled-right"
                    >
                      ?
                    </Tooltip>
                    <Field
                      component={InputText}
                      validate={this.slugAsyncValidation}
                      name="slug"
                      label="Slug*"
                      readonly={!isCreate}
                      disabled={!isCreate}
                    />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Specialization
                    <Field component={SelectField} name="specialization">
                      <option value="" disabled>
                        --Select Specialization--
                      </option>
                      {specializations.map(item => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Level
                    <Field component={SelectField} className="input w-100" name="level">
                      <option value={constants.DISABLED}>Disabled</option>
                      <option value={constants.FREE_PROFILE}>Free Profile</option>
                      <option value={constants.FULL_PROFILE}>Full Profile</option>
                    </Field>
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column col-md-4">
                  <label className="label">
                    Phone Number
                    <Field component={InputText} name="phoneNumber" />
                  </label>
                </div>

                <div className="column col-md-4">
                  <label className="label">
                    Email
                    <Field component={InputText} name="email" />
                  </label>
                </div>

                <div className="column col-md-4">
                  <label className="label">
                    Website
                    <Field component={InputText} name="website" />
                  </label>
                </div>
              </div>

              <div className="column">
                <MapWithASearchBox
                  onCoordsChange={form.mutators.setPracticeCoordinations}
                  onLocationChange={form.mutators.setPracticeLocation}
                  defaultCoords={coordinations}
                />
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Address
                    <Field component={InputText} name="address" />
                  </label>
                </div>

                <div className="column  is-half">
                  <label className="label">
                    Post Code
                    <Field component={InputText} name="postCode" />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Borough*
                    <Field component={InputText} className="input w-100" name="borough" />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Town*
                    <Field component={InputText} name="town" />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    API Type
                    <Field component={InputText} name="apiType" />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Our BookingCalendarMultiSpec
                    <Field component={InputCheckbox} name="hasOurBooking" />
                  </label>
                </div>
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label className="label">
                    Clicrdv GroupId
                    <Field component={InputText} name="clicrdvGroupId" />
                  </label>
                </div>

                <div className="column is-half">
                  <label className="label">
                    Clicrdv GroupName
                    <Field component={InputText} name="clicrdvGroupName" />
                  </label>
                </div>
              </div>

              <div className="column">
                <label className="label">
                  User
                  <Field
                    component={WastonSelectField}
                    fetchAction={this.handleUserSearch}
                    className="input"
                    name="userId"
                    placeholder="Search user by email..."
                  />
                </label>
              </div>

              <div className="column">
                <label className="label">
                  Specialists
                  <FieldArray
                    name="specialistPractice"
                    component={SpecialistsFieldArray}
                    fetchAction={this.handleSpecialistSearch}
                  />
                </label>
              </div>
            </div>

            <div className="column">
              <button className="button is-primary is-fullwidth fontweight-700">
                {isCreate ? 'Create' : 'Update'}
              </button>
            </div>
          </form>
        )}
      />
    );
  }
}

export default PracticeForm;
