import { types } from './actions';

export const initialState = {
  blogs: [],
  total: 0,
  pageSize: 9,
};

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.BLOG_FETCH:
      return {
        ...appState,
        ...payload,
      };

    default:
      return appState;
  }
};

export const selectors = {
  getPageSize: state => state.blog.pageSize,
};
