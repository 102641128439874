export const types = {
  PAGE_HAS_CHANGED: 'PAGE_HAS_CHANGED',
  GENDER_HAS_CHANGED: 'GENDER_HAS_CHANGED',
  LANGUAGE_HAS_CHANGED: 'LANGUAGE_HAS_CHANGED',
  NAME_HAS_CHANGED: 'NAME_HAS_CHANGED',
  SPECIALIZATION_HAS_CHANGED: 'SPECIALIZATION_HAS_CHANGED',
  LOCATION_HAS_CHANGED: 'LOCATION_HAS_CHANGED',
  LANGUAGES_FETCHED: 'LANGUAGES_FETCHED',
  GENDERS_FETCHED: 'GENDERS_FETCHED',
  SEARCH_COUNT_FETCHED: 'SEARCH_COUNT_FETCHED',
  SEARCH_RESULT_FETCHED: 'SEARCH_RESULT_FETCHED',
  SEARCH_RESULT_DETAILS_FETCHED: 'SEARCH_RESULT_DETAILS_FETCHED',
  SEARCH_FETCH_RESULT_LOCATION: 'SEARCH_FETCH_RESULT_LOCATION',
  SEARCH_FETCH_RESULT_DATA: 'SEARCH_FETCH_RESULT_DATA',
  AVAILABLE_TIME_SLOTS_FETCHED: 'AVAILABLE_TIME_SLOTS_FETCHED',
};

const fetchLanguages = routerParams => ({
  type: `${types.LANGUAGES_FETCHED}_REQUEST`,
  routerParams,
});

const fetchGenders = routerParams => ({
  type: `${types.GENDERS_FETCHED}_REQUEST`,
  routerParams,
});

const fetchSearchResultDetails = routerParams => ({
  type: `${types.SEARCH_RESULT_DETAILS_FETCHED}_REQUEST`,
  routerParams,
});

const fetchCount = routerParams => ({
  type: `${types.SEARCH_COUNT_FETCHED}_REQUEST`,
  routerParams,
});

const fetchResults = (routerParams, latitude, longitude) => ({
  type: `${types.SEARCH_RESULT_FETCHED}_REQUEST`,
  routerParams,
  latitude,
  longitude,
});

const fetchResultsAndLocation = routerParams => ({
  type: `${types.SEARCH_FETCH_RESULT_LOCATION}_REQUEST`,
  routerParams,
});

const fetchSearchResultsData = routerParams => ({
  type: `${types.SEARCH_FETCH_RESULT_DATA}_REQUEST`,
  routerParams,
});

export const actions = {
  fetchLanguages,
  fetchGenders,
  fetchSearchResultDetails,
  fetchCount,
  fetchResults,
  fetchResultsAndLocation,
  fetchSearchResultsData,
};
