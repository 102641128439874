// @flow

import React from 'react';
import type { Dispatch } from 'redux';

import type { ModalTypesFlowType, ModalActionFlowType } from 'modules/modal/actions';
import { modalTypes, modalActions } from 'modules/modal/actions';

import ListPracticeModal from 'modules/visitor/practice/ListPracticeModal';
import LoginModal from 'modules/user/LoginModal';
import LanguageModal from 'modules/specialist/components/languages/languageModal';
import PriceServiceModal from 'modules/specialist/components/priceList/priceServiceModal';
import ReviewModal from 'modules/specialist/components/reviews/reviewModal';
import PictureUploadModal from 'components/pictureUploadModal';
import EnquireModal from 'components/enquireButton/enquireModal';
import PracticeSelectModal from 'modules/specialist/specialistFull/practiceSelectModal';
import TransportationModal from 'components/profile/practice/location/transportation/transportationModal';
import SpecialistFreeEditModal from 'modules/specialist/specialistFree/specialistFreeEditModal';
import PaymentMethodsModal from 'modules/practice/paymentMethods/paymentMethodsModal';
import UpdatePracticeModal from 'modules/admin/practices/updatePracticeModal';
import UpdateSpecialistModal from 'modules/admin/specialists/updateSpecialistModal';
import UpdateUserModal from 'modules/admin/users/updateUserModal';
import CreateSpecializationModal from 'modules/specializations/createSpecializationModal';
import SimpleModal from './simpleModal';

import styles from './Modal.scss';

export const MODAL_COMPONENTS = {
  [modalTypes.SIMPLE_MODAL]: SimpleModal,
  [modalTypes.LOGIN_MODAL]: LoginModal,
  [modalTypes.LIST_PRACTICE_MODAL]: ListPracticeModal,
  [modalTypes.LANGUAGE_MODAL]: LanguageModal,
  [modalTypes.PRICE_LIST_MODAL]: PriceServiceModal,
  [modalTypes.REVIEW_MODAL]: ReviewModal,
  [modalTypes.PICTURE_UPLOAD_MODAL]: PictureUploadModal,
  [modalTypes.ENQUIRE_MODAL]: EnquireModal,
  [modalTypes.PRACTICE_SELECT_MODAL]: PracticeSelectModal,
  [modalTypes.TRANSPORTATION_MODAL]: TransportationModal,
  [modalTypes.SPECIALIST_FREE_EDIT_MODAL]: SpecialistFreeEditModal,
  [modalTypes.PAYMENT_METHODS_MODAL]: PaymentMethodsModal,
  [modalTypes.UPDATE_PRACTICE_MODAL]: UpdatePracticeModal,
  [modalTypes.UPDATE_SPECIALIST_MODAL]: UpdateSpecialistModal,
  [modalTypes.UPDATE_USER_MODAL]: UpdateUserModal,
  [modalTypes.CREATE_SPECIALIZATION_MODAL]: CreateSpecializationModal,
};

type Props = {
  modal: {
    modalType: ModalTypesFlowType,
    modalProps: any,
  },
  dispatch: Dispatch<ModalActionFlowType>,
};

class Modal extends React.Component<Props> {
  enableScrolling = () => {
    if (typeof document !== 'undefined') {
      const html = document.getElementsByTagName('html')[0];
      html.classList.remove(styles.modalOpen);
    }
  };

  disableScrolling = () => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add(styles.modalOpen);
  };

  hideModal = () => {
    this.props.dispatch(modalActions.hideModal(this.props.modal.modalType));
  };

  render() {
    const {
      modal: { modalType, modalProps },
    } = this.props;

    if (!modalType) {
      this.enableScrolling();

      return null;
    }

    const SpecificModal = MODAL_COMPONENTS[modalType];
    this.disableScrolling();
    return (
      <div className="modal is-active">
        <div role="button" tabIndex={-1} className="modal-background" onClick={this.hideModal} />
        <button className="modal-close" aria-label="close" onClick={this.hideModal} />
        <section className="modal-content">
          <SpecificModal {...modalProps} />
        </section>
      </div>
    );
  }
}

export default Modal;
