import { types } from './actions';

const initialState = {
  searching: false,
  practices: [],
  specialists: [],
  doctorOrPractice: '',
  locationSearch: '',
  nameFocused: false,
};

export default function addresses(appState = initialState, { type, payload }) {
  switch (type) {
    case types.SEARCH_FETCH_PRACTICES:
      return {
        ...appState,
        practices: payload,
      };

    case types.SEARCH_FETCH_SPECIALISTS:
      return {
        ...appState,
        specialists: payload,
      };

    case types.CHANGE_DOCTOR_OR_PRACTICE:
      return {
        ...appState,
        searching: true,
        doctorOrPractice: payload,
      };

    case types.CHANGE_NAME_FOCUS:
      return {
        ...appState,
        nameFocused: payload,
      };

    case types.SET_PRACTICE:
      return {
        ...appState,
        searching: false,
        doctorOrPractice: payload.name,
      };

    case types.SET_SPECIALIZATION:
      return {
        ...appState,
        specializationSearch: payload,
      };

    case types.SET_LOCATION:
      return {
        ...appState,
        locationSearch: payload,
      };

    case types.RESET_SEARCH:
      return {
        ...appState,
        searching: false,
      };

    case types.RESET_FETCHING_STATE:
      return {
        ...appState,
        specialistsFetched: false,
      };

    default:
      return appState;
  }
}
