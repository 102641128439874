import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import UniversalComponent from 'utils/universalComponent';
import LoadingSpinner from 'components/loadingSpinner';

import Modal from 'components/modal';
import ToastPanel from './toastPanel';
import Header from './header';
import LinkSection from './linkSection';
import Footer from './footer';
import ScrollToTop from './scrollToTop';
import styles from './Layout.scss';

class Layout extends PureComponent {
  render() {
    const {
      routes,
      location: { pathname },
    } = this.props;

    const isLinkSectionShown = !pathname.startsWith('/calendar') && !pathname.startsWith('/admin');

    return (
      <ScrollToTop>
        <div className={styles.layout}>
          <LoadingSpinner track="page" fixed />
          <Header pathname={pathname} />

          <main>
            <Switch>
              {routes.map(route => (
                <Route
                  key={route.component}
                  path={route.path}
                  render={routeProps => <UniversalComponent {...routeProps} page={route.component} />}
                  exact
                />
              ))}
            </Switch>
          </main>
          {isLinkSectionShown && <LinkSection />}
          <Footer />
          <ToastPanel />
          <Modal />
        </div>
      </ScrollToTop>
    );
  }
}

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      component: PropTypes.string,
    })
  ),
};

export default withRouter(Layout);
