// @flow

import type { UserStoreState, ReduxStateFlowType } from 'types';
import * as storage from 'utils/storage';
import type { UserActions } from './actions';
import { roles, types } from './actions';

export const initialState = {
  id: null,
  userId: null,
  user: null,
  role: roles.EMPTY,
  isAdminRole: false,
  hasCalendar: false,
  userName: '',
  userUrl: '',
  userPracticeId: null,
  userSpecialistId: null,
  editableSpecialists: [],
  profileMenuOpened: false,
  token: undefined,
  tokenTtl: undefined,
  tokenCreatedAt: undefined,
};

export default (appState: UserStoreState = initialState, action: UserActions) => {
  switch (action.type) {
    case types.FETCH_USER_SUCCESS:
      return {
        ...appState,
        user: action.payload,
      };

    case types.LOGIN_USER_SUCCESS: {
      const newState = {
        ...appState,
        ...action.payload,
      };

      // save to storage
      storage.setUser(newState);

      return newState;
    }

    case types.LOGOUT_USER_SUCCESS:
      // remove from storage
      storage.deleteUser();

      return {
        ...appState,
        userId: null,
        isAdminRole: false,
        hasCalendar: false,
        userUrl: '',
        userName: '',
        userPracticeId: null,
        userSpecialistId: null,
        user: null,
      };

    case types.GET_EDITABLE_USER_SPECIALISTS_SUCCESS:
      return {
        ...appState,
        editableSpecialists: action.payload,
      };

    case types.GET_USER_PRACTICE_SUCCESS: {
      const newState = {
        ...appState,
        userName: action.payload.userName,
        userPracticeId: action.payload.practiceId,
        userUrl: action.payload.userUrl,
        role: appState.role || roles.PRACTICE,
      };

      // save to storage
      storage.setUser(newState);

      return newState;
    }

    case types.GET_USER_SPECIALIST_SUCCESS: {
      const newState = {
        ...appState,
        userName: action.payload.userName,
        userSpecialistId: action.payload.specialistId,
        userUrl: action.payload.userUrl,
        role: appState.role || roles.SPECIALIST,
      };

      // save to storage
      storage.setUser(newState);

      return newState;
    }

    default:
      return appState;
  }
};

export const selectors = {
  getUser: (store: ReduxStateFlowType) => store.user,
  getToken: (store: ReduxStateFlowType) => store.user.token,
  getRole: (store: ReduxStateFlowType) => store.user.role,
  getUserId: (store: ReduxStateFlowType) => store.user.userId,
  getId: (store: ReduxStateFlowType) => store.user.id,
};
