import { all, fork } from 'redux-saga/effects';
import watchAdminSpecializationsActionRequests from 'modules/specializations/saga';
import watchAdminUserActions from './users/saga';
import watchAdminSpecialistActions from './specialists/saga';
import watchAdminSpecialistReviewsActions from './specialistReviews/saga';
import watchAdminPracticeActions from './practices/saga';

export default function* rootSaga() {
  yield all([
    fork(watchAdminUserActions),
    fork(watchAdminSpecialistActions),
    fork(watchAdminPracticeActions),
    fork(watchAdminSpecialistReviewsActions),
    fork(watchAdminSpecializationsActionRequests),
  ]);
}
