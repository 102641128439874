import React from 'react';
import { Link } from 'react-router-dom';
import Menu from 'react-burger-menu/lib/menus/slide';
import { Trans } from '@lingui/macro';

import config from 'config';
import styles from './SlideMenu.scss';

const SlideMenu = () => (
  <Menu
    customBurgerIcon={<img src="/images/icons/menu.png" alt="menu" />}
    customCrossIcon={<img src="/images/icons/close.png" alt="" />}
    burgerButtonClassName={styles.burgerButton}
    crossButtonClassName={styles.crossButton}
    morphShapeClassName={styles.morphShape}
    itemListClassName={styles.itemList}
    overlayClassName={styles.overlay}
    styles={{ height: '0' }}
  >
    <ul className={styles.menu}>
      <li className="desk_hide">
        <span>{config.name}</span>
      </li>
      <li>
        <Link to="/">
          <Trans>Home</Trans>
        </Link>
      </li>
      <li>
        <Link to="/about-us">
          <Trans>About us</Trans>
        </Link>
      </li>
      <li>
        <Link to="/join-us">
          <Trans>Join us</Trans>
        </Link>
      </li>
      <li>
        <Link to="/press">
          <Trans>Press</Trans>
        </Link>
      </li>
      <li>
        <Link to="/contact-us">
          <Trans>Contact Us</Trans>
        </Link>
      </li>
      <li>
        <Link to="/blog">
          <Trans>Blog</Trans>
        </Link>
      </li>
    </ul>
  </Menu>
);

export default SlideMenu;
