// @flow

import type {
  ReduxStateFlowType,
  SpecialistState,
  PracticeState,
  LanguageListType,
  CalendarAvailableTimeSlots,
  SpecialistPracticeState,
} from 'types';
import type { SpecialistActions } from 'modules/specialist/actions';
import { types } from './actions';
import replaceByIndex from '../../utils/replaceByIndex';

export const initialState = {
  specialist: {
    practices: [
      {
        apiType: '',
      },
    ],
  },
  languageList: [],
  availableTimeSlots: {},
  availableReasons: {},
  activeTab: 0,
};

export type SpecialistViewFlowType = {
  ...SpecialistState,
  practices: Array<$Shape<PracticeState>>,
  specialistPractice: SpecialistPracticeState,
};

export type SpecialistStoreFlowType = {
  specialist: $Shape<SpecialistViewFlowType>,
  languageList: LanguageListType,
  availableTimeSlots: $Shape<CalendarAvailableTimeSlots>,
  availableReasons: {},
  activeTab: number,
};

export default (state: SpecialistStoreFlowType = initialState, action: SpecialistActions) => {
  switch (action.type) {
    /* eslint-disable function-paren-newline */
    // eslint disable: https://github.com/eslint/eslint/issues/6903
    case types.SPECIALIST_FETCH:
      return {
        ...state,
        specialist: {
          // $FlowFixMe
          ...action.payload,
          practices:
            action.payload &&
            action.payload.practices
              .map(practice => ({
                ...practice,
                // $FlowFixMe
                position: action.payload.specialistPractice.filter(
                  specialistPracticeMetadata => specialistPracticeMetadata.practiceId === practice.id
                )[0].practicePosition,
              }))
              .sort((practice1, practice2) => practice1.position - practice2.position || practice1.id - practice2.id),
        },
      };
    /* eslint-enable function-paren-newline */

    case types.SPECIALIST_PROFILE_EDIT:
      return {
        ...state,
        editingProfile: true,
      };

    case types.SPECIALIST_PROFILE_SAVE:
      return {
        ...state,
        specialist: Object.assign({}, state.specialist, {
          // $FlowFixMe
          profile: action.payload.profile,
        }),
        editingProfile: false,
      };

    case types.SPECIALIST_REVIEW_FETCH:
      return {
        ...state,
        specialist: {
          ...state.specialist,
          // $FlowFixMe
          reviews: action.payload,
        },
      };

    case types.SPECIALIST_LANGUAGES_FETCH:
      return {
        ...state,
        // $FlowFixMe
        languageList: action.payload,
      };

    case types.SPECIALIST_LANGUAGES_SAVE:
      return {
        ...state,
        specialist: Object.assign({}, state.specialist, {
          // $FlowFixMe
          languages: action.payload,
        }),
      };

    case types.SPECIALIST_SERVICES_SAVE:
      return {
        ...state,
        specialist: {
          ...state.specialist,
          specialistPractice: replaceByIndex(
            {
              ...state.specialist.specialistPractice[state.activeTab],
              // $FlowFixMe
              services: action.payload,
            },
            state.activeTab,
            state.specialist.specialistPractice
          ),
        },
      };

    case types.SPECIALIST_PRACTICE_CHANGE_ACTIVE_TAB:
      return {
        ...state,
        // $FlowFixMe
        activeTab: action.payload.activeTab,
      };

    default:
      return state;
  }
};

export const selectors = {
  getActiveTab: (state: ReduxStateFlowType) => state.profileView.activeTab,
  getSpecialist: (state: ReduxStateFlowType) => state.profileView.specialist,
  getLanguageList: (state: ReduxStateFlowType) => state.profileView.languageList,
  getAvailableTimeslots: (state: ReduxStateFlowType) => state.profileView.availableTimeSlots,
  getAvailableReasons: (state: ReduxStateFlowType) => state.profileView.availableReasons,
};
