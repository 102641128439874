// @flow

import { call, select, takeLatest, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { reset as resetForm, initialize as initializeForm } from 'redux-form';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import type { ChangePatientPasswordRequest, DeleteRelativeRequestActionFlowType } from 'modules/patient/actions';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as patientActions, types } from 'modules/patient/actions';
import { selectors } from 'modules/user/reducer';

export function* getProfileSagaRequest(): Saga<void> {
  try {
    const patientId = yield select(selectors.getUserId);
    const res = yield call(api.get, `patients/${patientId}`);
    yield put(initializeForm('patientProfileForm', res.data));
    yield put({
      type: types.PATIENT_GET_PROFILE,
    });
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, fetching your profile has failed.`)));
    logException(error, `fetch profile request failed ${error}`);
  }
}

// $FlowFixMe
export function* updateProfileSagaRequest({ formObj }): Saga<void> {
  try {
    const patientId = yield select(selectors.getUserId);
    yield call(api.patch, `patients/${patientId}`, formObj);

    yield put(commonActions.toastSuccess(i18n._(t`You profile has been updated.`)));
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, updating your profile has failed.`)));
    logException(error, `update profile has failed ${error}`);
  }
}

export function* changePatientPasswordSagaRequest({ payload }: ChangePatientPasswordRequest): Saga<void> {
  try {
    // const patient = yield select(selectors.getUser);
    // yield call(api.patch, `patient/${patient.userId}`, formObj);

    yield call(payload.successCallback);
    yield put(modalActions.hideModal());
    yield put(commonActions.toastSuccess(i18n._(t`You password has been changed.`)));
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, changing your password has failed.`)));
    logException(error, `change password request has failed ${error}`);
  }
}

// $FlowFixMe
export function* createRelativeSagaRequest({ formObj }): Saga<void> {
  try {
    const patientId = yield select(selectors.getUserId);
    const res = yield call(api.post, `patients/${patientId}/relatives`, formObj);

    yield put(patientActions.createRelativeSuccess(res.data));
    yield put(resetForm('myRelativesForm'));
    yield put(commonActions.toastSuccess(i18n._(t`Your relative has been added`)));
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, creating your relative has failed.`)));
    logException(error, `add patient relative failed ${error}`);
  }
}

export function* deleteRelativeSagaRequest({ payload }: DeleteRelativeRequestActionFlowType): Saga<void> {
  const { relativeId } = payload;
  try {
    const patientId = yield select(selectors.getUserId);
    yield call(api.del, `patients/${patientId}/relatives/${relativeId}`);

    yield put(patientActions.deleteRelativeSuccess(relativeId));
    yield put(commonActions.toastSuccess(i18n._(t`Your relative has been deleted.`)));
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, deleting your relative has failed.`)));
    logException(error, `delete patient relative failed ${error}`);
  }
}

export default function* watchPatientAccountRequests(): Saga<void> {
  yield takeLatest(`${types.PATIENT_GET_PROFILE}_REQUEST`, getProfileSagaRequest);
  yield takeLatest(`${types.PATIENT_UPDATE_PROFILE}_REQUEST`, updateProfileSagaRequest);
  yield takeLatest(types.PATIENT_CHANGE_PASSWORD_REQUEST, changePatientPasswordSagaRequest);
  yield takeLatest(types.PATIENT_CREATE_RELATIVE_REQUEST, createRelativeSagaRequest);
  yield takeLatest(types.PATIENT_DELETE_RELATIVE_REQUEST, deleteRelativeSagaRequest);
}
