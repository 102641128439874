import { types } from './actions';

export const initialAppState = {
  practice: {},
  paymentMethodsList: [],
};

export default (appState = initialAppState, { type, payload }) => {
  switch (type) {
    case types.PRACTICE_FETCH:
      return {
        ...appState,
        practice: payload,
      };

    case types.PRACTICE_ABOUT_SAVE:
      return {
        ...appState,
        practice: Object.assign({}, appState.practice, {
          profile: payload.profile,
        }),
      };

    case types.PRACTICE_PAYMENTS_METHODS_FETCH:
      return {
        ...appState,
        paymentMethodsList: payload,
      };

    case types.PRACTICE_PAYMENTS_METHODS_SAVE:
      return {
        ...appState,
        practice: Object.assign({}, appState.practice, {
          paymentMethods: payload,
        }),
      };

    case types.PRACTICE_TRANSPORT_SAVE:
      return {
        ...appState,
        practice: {
          ...appState.practice,
          underground: payload.underground,
          bus: payload.bus,
        },
      };

    default:
      return appState;
  }
};

export const selectors = {
  getPaymentMethodsList: state => state.practiceView.paymentMethodsList,
  getPractice: state => state.practiceView.practice,
};
