// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import type {
  GetPracticesRequestActionFlowType,
  GetPracticeDetailRequestActionFlowType,
  CreatePracticeRequestActionFlowType,
  SearchPracticeSpecialistsRequestActionFlowType,
  UpdatePracticeRequestActionFlowType,
} from 'modules/admin/actions';
import { actions, types } from 'modules/admin/actions';

export function* getPracticesSagaRequest({ payload }: GetPracticesRequestActionFlowType): Saga<void> {
  yield put(commonActions.resetSpinner());
  const { page, pageSize, sorted, filtered } = payload;
  if (!filtered.length && !sorted) {
    yield put(commonActions.showSpinner());
  }

  try {
    const {
      data: { count },
    } = yield call(api.get, 'practices/count');

    const filter: {|
      skip: number,
      limit: number,
      order?: string,
      where?: {
        [number]: string,
      },
    |} = {
      skip: page > 0 ? page * pageSize : 0,
      limit: pageSize,
    };

    if (sorted) {
      filter.order = `${sorted.id} ${sorted.desc ? 'DESC' : 'ASC'}`;
    }

    if (filtered && filtered.length) {
      const where = {};
      filtered.forEach(item => {
        where[item.id] = `${encodeURIComponent(item.value)}%25`;
      });
      filter.where = where;
    }

    const response = yield call(api.get, `practices/adminSearch?filter=${JSON.stringify(filter)}`);
    yield put({
      type: types.ADMIN_GET_PRACTICES_SUCCESS,
      payload: {
        rows: response.data,
        pages: Math.ceil(count / pageSize),
      },
    });
  } catch (error) {
    logException(error, `getPracticeRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* getPracticeDetailSagaRequest({ payload }: GetPracticeDetailRequestActionFlowType): Saga<void> {
  yield put(commonActions.showSpinner());

  try {
    const { row } = payload;
    const filter = {
      fields: { id: true },
      include: [
        'specialistPractice',
        {
          relation: 'specialists',
          scope: {
            fields: ['firstName', 'lastName'],
            where: {
              level: { gt: -1 },
            },
          },
        },
      ],
      where: {
        id: row.id,
        level: { gt: -1 },
      },
    };

    const res = yield call(api.get, `practices?filter=${JSON.stringify(filter)}`);
    const { specialistPractice, specialists } = res.data[0];
    specialists.forEach(specialist => {
      const detail = specialistPractice.find(item => item.specialistId === specialist.id);
      if (detail) {
        detail.name = `${specialist.firstName} ${specialist.lastName}`;
      }
    });
    yield put(actions.getPracticeDetailSuccess({ ...row, specialistPractice }));
  } catch (error) {
    logException(error, `getPracticeDetailRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* createPracticeSagaRequest({ payload }: CreatePracticeRequestActionFlowType): Saga<void> {
  const { formObj, successCallback } = payload;
  try {
    yield call(api.post, 'practices/createPractice', {
      ...formObj,
      profile: '',
    });
    yield call(successCallback);
    yield put(commonActions.toastSuccess(i18n._(t`Practice has been successfully created.`)));
  } catch (error) {
    logException(error, `creating practice Request failed ${error}`);
    yield put(
      commonActions.toastDanger(
        (error.response.data &&
          ((error.response.data.error.details && error.response.data.error.details.messages.email[0]) ||
            error.response.data.error)) ||
          'Sorry, creating a new practice has failed.'
      )
    );
  }
}

export function* updatePracticeSagaRequest({ payload }: UpdatePracticeRequestActionFlowType): Saga<void> {
  const { formObj, successCallback } = payload;
  try {
    const res = yield call(api.patch, `practices/${formObj.id}/updatePractice`, formObj);
    yield put(modalActions.hideModal());

    yield put({
      type: types.ADMIN_UPDATE_PRACTICE_SUCCESS,
      payload: res.data,
    });
    yield call(successCallback);
    yield put(commonActions.toastSuccess(i18n._(t`Practice has been successfully updated.`)));
  } catch (error) {
    logException(error, `update practice request failed ${error}`);
    yield put(
      commonActions.toastDanger(
        (error.response.data &&
          ((error.response.data.error.details && error.response.data.error.details.messages.email[0]) ||
            error.response.data.error)) ||
          i18n._(t`Sorry, updating practice has failed.`)
      )
    );
  }
}

export function* searchPracticeSpecialistsSagaRequest({
  payload,
}: SearchPracticeSpecialistsRequestActionFlowType): Saga<void> {
  const { key, resolve } = payload;
  // doesn't fetch if key is empty
  if (!key) {
    resolve({ options: [] });
    return;
  }

  try {
    const filter = {
      fields: { id: true, firstName: true, lastName: true },
      where: {
        or: [
          {
            firstName: { like: `${key}%25` },
          },
          {
            lastName: { like: `${key}%25` },
          },
        ],
      },
    };
    const { data } = yield call(api.get, `specialists?filter=${JSON.stringify(filter)}`);
    const options = data.map(item => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`,
    }));

    resolve({ options });
  } catch (error) {
    logException(error, `searchPracticeSpecialistsRequest failed ${error}`);
  }
}

// Saga Helper
export default function* watchAdminPracticeActionRequests(): Saga<void> {
  yield takeLatest(types.ADMIN_GET_PRACTICES_REQUEST, getPracticesSagaRequest);
  yield takeLatest(types.ADMIN_GET_PRACTICE_DETAIL_REQUEST, getPracticeDetailSagaRequest);
  yield takeLatest(types.ADMIN_CREATE_PRACTICE_REQUEST, createPracticeSagaRequest);
  yield takeLatest(types.ADMIN_UPDATE_PRACTICE_REQUEST, updatePracticeSagaRequest);
  yield takeLatest(types.ADMIN_SEARCH_PRACTICE_SPECIALISTS_REQUEST, searchPracticeSpecialistsSagaRequest);
}
