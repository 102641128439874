import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import { types } from './actions';
import { selectors } from './reducer';

export function* fetchPracticeRequest({ slug }) {
  yield put(commonActions.showSpinner());

  try {
    const query = {
      where: {
        slug,
      },
      include: [
        {
          relation: 'specialists',
          scope: {
            include: ['specialistPractice'],
          },
        },
        'paymentMethods',
      ],
    };

    const { data } = yield call(api.get, `practices?filter=${JSON.stringify(query)}`);

    const practice = data[0];

    // assign clicrdvCalendarId detail
    if (practice) {
      for (let i = 0; i < practice.specialists.length; i += 1) {
        const specialist = practice.specialists[i];
        specialist.specialistPractice = specialist.specialistPractice.find(item => item.practiceId === practice.id);
        specialist.clicrdvCalendarId = specialist.specialistPractice.clicrdvCalendarId;
      }
    }

    yield put({
      type: types.PRACTICE_FETCH,
      payload: practice,
    });
  } catch (error) {
    logException(error, `fetch practice request failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* updatePracticeAboutRequest({ id, value }) {
  try {
    const body = {
      profile: value,
    };
    const response = yield call(api.patch, `practices/${id}`, body);
    yield put({
      type: types.PRACTICE_ABOUT_SAVE,
      payload: response.data,
    });
  } catch (error) {
    logException(error, `fetch practice request failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* fetchPaymentMethodsRequest() {
  try {
    const paymentMethodsList = yield select(selectors.getPaymentMethodsList);
    if (paymentMethodsList.length) return;

    const response = yield call(api.get, 'paymentMethods');
    yield put({
      type: types.PRACTICE_PAYMENTS_METHODS_FETCH,
      payload: response.data,
    });
  } catch (error) {
    logException(error, `fetchPaymentMethodsRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* updatePracticePaymentMethodsRequest({ id, paymentMethods }) {
  try {
    const url = `practices/${id}/updatePaymentMethods`;
    const response = yield call(api.patch, url, paymentMethods);
    yield put({
      type: types.PRACTICE_PAYMENTS_METHODS_SAVE,
      payload: response.data,
    });
    yield put(modalActions.hideModal());
  } catch (error) {
    logException(error, `updatePracticePaymentMethodsRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* updatePracticeTransportRequest({ id, transport }) {
  try {
    const url = `practices/${id}`;
    const body = {
      underground: transport.underground,
      bus: transport.bus,
    };

    const response = yield call(api.patch, url, body);
    yield put({
      type: types.PRACTICE_TRANSPORT_SAVE,
      payload: response.data,
    });

    yield put(modalActions.hideModal());
  } catch (error) {
    logException(error, `updatePracticeTransportRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

export function* updatePracticePictureRequest({ practiceId, pictureData }) {
  try {
    const url = `practices/${practiceId}/updateProfilePicture`;
    yield call(api.patch, url, {
      pictureData,
    });

    yield put(modalActions.hideModal());
    // refresh page to clear cache
    document.location.reload();
  } catch (error) {
    logException(error, `updatePracticePictureRequest failed ${error}`);
    yield put(commonActions.toastDanger());
  }
}

// Saga Helper
export default function* watchSpecialistActionRequests() {
  yield takeLatest(`${types.PRACTICE_FETCH}_REQUEST`, fetchPracticeRequest);
  yield takeLatest(`${types.PRACTICE_ABOUT_SAVE}_REQUEST`, updatePracticeAboutRequest);
  yield takeLatest(`${types.PRACTICE_PAYMENTS_METHODS_FETCH}_REQUEST`, fetchPaymentMethodsRequest);
  yield takeLatest(`${types.PRACTICE_PAYMENTS_METHODS_SAVE}_REQUEST`, updatePracticePaymentMethodsRequest);
  yield takeLatest(`${types.PRACTICE_TRANSPORT_SAVE}_REQUEST`, updatePracticeTransportRequest);
  yield takeLatest(`${types.PRACTICE_UPDATE_PICTURE}_REQUEST`, updatePracticePictureRequest);
}
