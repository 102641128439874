import React from 'react';
import PropTypes from 'prop-types';
import { Async } from 'react-select';
import 'react-select/dist/react-select.css';

class SelectField extends React.Component {
  getOptions = input =>
    new Promise((resolve, reject) => {
      this.props.fetchAction(input, resolve, reject);
    });

  handleChange = selectedOption => {
    if (selectedOption) {
      this.props.input.onChange(selectedOption.value);
    } else {
      this.props.input.onChange('');
    }
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error },
    } = this.props;
    return (
      <React.Fragment>
        <Async
          name={input.name}
          value={input.value}
          loadOptions={this.getOptions}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
        {touched && error && <span className="has-text-danger has-text-weight-normal">{error}</span>}
      </React.Fragment>
    );
  }
}

SelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  fetchAction: PropTypes.func.isRequired,
};

export default SelectField;
