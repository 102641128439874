// @flow

import type { PatientPracticeState, RelativeState, ExtractReturn } from 'types';

export const types = {
  PATIENT_SIGNUP_REQUEST: 'PATIENT_SIGNUP_REQUEST',
  PATIENT_SIGNUP_SUCCESS: 'PATIENT_SIGNUP_SUCCESS',
  PATIENT_SIGNUP_ERROR: 'PATIENT_SIGNUP_ERROR',
  PATIENT_LOGIN_REQUEST: 'PATIENT_LOGIN_REQUEST',
  PATIENT_LOGIN_ERROR: 'PATIENT_LOGIN_ERROR',
  PATIENT_BOOK_APPOINTMENT: 'PATIENT_BOOK_APPOINTMENT',
  PATIENT_BOOK_APPOINTMENT_REQUEST: 'PATIENT_BOOK_APPOINTMENT_REQUEST',
  PATIENT_GET_PROFILE: 'PATIENT_GET_PROFILE',
  PATIENT_UPDATE_PROFILE: 'PATIENT_UPDATE_PROFILE',
  PATIENT_CHANGE_PASSWORD_REQUEST: 'PATIENT_CHANGE_PASSWORD_REQUEST',
  PATIENT_FETCH_RELATIVES_REQUEST: 'PATIENT_FETCH_RELATIVES_REQUEST',
  PATIENT_FETCH_RELATIVES_SUCCESS: 'PATIENT_FETCH_RELATIVES_SUCCESS',
  PATIENT_CREATE_RELATIVE_REQUEST: 'PATIENT_CREATE_RELATIVE_REQUEST',
  PATIENT_CREATE_RELATIVE_SUCCESS: 'PATIENT_CREATE_RELATIVE_SUCCESS',
  PATIENT_DELETE_RELATIVE_REQUEST: 'PATIENT_DELETE_RELATIVE_REQUEST',
  PATIENT_DELETE_RELATIVE_SUCCESS: 'PATIENT_DELETE_RELATIVE_SUCCESS',
  PATIENT_FETCH_PATIENT_PRACTICES_SUCCESS: 'PATIENT_FETCH_PATIENT_PRACTICES_SUCCESS',
  PATIENT_FETCH_PATIENT_PRACTICES_REQUEST: 'PATIENT_FETCH_PATIENT_PRACTICES_REQUEST',
};

export type BookAppointmentForm = {|
  person: string,
  firstName?: string,
  lastName?: string,
  birthName?: string,
  gender?: string,
  dateOfBirth?: string,
  relative?: string,
|};

export type PatientSignupForm = {|
  email: string,
  password: string,
  confirmPassword: string,
  firstName: string,
  lastName: string,
  birthName?: string,
  dateOfBirth: string,
  gender: string,
|};

type PatientSignupFormResponse = $Shape<{|
  FORM_ERROR: string,
  email?: string | Array<string>,
  password?: string | Array<string>,
  confirmPassword?: string | Array<string>,
  firstName?: string | Array<string>,
  lastName?: string | Array<string>,
  birthName?: string | Array<string>,
  dateOfBirth?: string | Array<string>,
  gender?: string | Array<string>,
|}>;

export type PatientLoginForm = {|
  email: string,
  password: string,
|};

export type PatientPasswordChangeFormType = {|
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
|};

const signupPatientRequest = (formObj: PatientSignupForm) => ({
  type: types.PATIENT_SIGNUP_REQUEST,
  payload: formObj,
});

const signupPatientSuccess = (responseData: PatientSignupFormResponse) => ({
  type: types.PATIENT_SIGNUP_SUCCESS,
  payload: responseData,
});

const signupPatientError = (responseData: PatientSignupFormResponse) => ({
  type: types.PATIENT_SIGNUP_ERROR,
  payload: responseData,
});

const loginPatientRequest = (payload: PatientLoginForm) => ({
  type: types.PATIENT_LOGIN_REQUEST,
  payload,
});

const loginPatientError = (error: string) => ({
  type: types.PATIENT_LOGIN_ERROR,
  payload: error,
});

const bookAppointmentRequest = (formObj: BookAppointmentForm) => ({
  type: types.PATIENT_BOOK_APPOINTMENT_REQUEST,
  payload: formObj,
});

const getProfileRequest = () => ({
  type: `${types.PATIENT_GET_PROFILE}_REQUEST`,
});

// $FlowFixMe
const updateProfileRequest = formObj => ({
  type: `${types.PATIENT_UPDATE_PROFILE}_REQUEST`,
  formObj,
});

const changePatientPasswordRequest = (formObj: PatientPasswordChangeFormType, successCallback: () => void) => ({
  type: types.PATIENT_CHANGE_PASSWORD_REQUEST,
  payload: {
    formObj,
    successCallback,
  },
});

const fetchRelativesRequest = () => ({
  type: types.PATIENT_FETCH_RELATIVES_REQUEST,
});

const fetchRelativesSuccess = (relatives: Array<RelativeState>) => ({
  type: types.PATIENT_FETCH_RELATIVES_SUCCESS,
  payload: relatives,
});

// $FlowFixMe
const createRelativeRequest = formObj => ({
  type: types.PATIENT_CREATE_RELATIVE_REQUEST,
  formObj,
});

// $FlowFixMe
const createRelativeSuccess = formObj => ({
  type: types.PATIENT_CREATE_RELATIVE_SUCCESS,
  payload: formObj,
});

const deleteRelativeRequest = (relativeId: number) => ({
  type: types.PATIENT_DELETE_RELATIVE_REQUEST,
  payload: {
    relativeId,
  },
});

const deleteRelativeSuccess = (relativeId: number) => ({
  type: types.PATIENT_DELETE_RELATIVE_SUCCESS,
  payload: relativeId,
});

const fetchPatientPartnersRequest = () => ({
  type: types.PATIENT_FETCH_PATIENT_PRACTICES_REQUEST,
});

const fetchPatientPartnersSuccess = (patientPractices: Array<PatientPracticeState>) => ({
  type: types.PATIENT_FETCH_PATIENT_PRACTICES_SUCCESS,
  payload: patientPractices,
});

export type SignupPatientRequestAction = ExtractReturn<typeof signupPatientRequest>;
export type SignupPatientSuccessAction = ExtractReturn<typeof signupPatientSuccess>;
export type SignupPatientErrorAction = ExtractReturn<typeof signupPatientError>;
export type LoginPatientRequestAction = ExtractReturn<typeof loginPatientRequest>;
export type LoginPatientErrorAction = ExtractReturn<typeof loginPatientError>;
export type ChangePatientPasswordRequest = ExtractReturn<typeof changePatientPasswordRequest>;
export type BookAppointmentRequestAction = ExtractReturn<typeof bookAppointmentRequest>;
export type DeleteRelativeRequestActionFlowType = ExtractReturn<typeof deleteRelativeRequest>;

export type PatientActions =
  | SignupPatientRequestAction
  | SignupPatientSuccessAction
  | SignupPatientErrorAction
  | LoginPatientRequestAction
  | LoginPatientErrorAction
  | BookAppointmentRequestAction
  | ChangePatientPasswordRequest
  | ExtractReturn<typeof fetchRelativesRequest>
  | ExtractReturn<typeof fetchRelativesSuccess>
  | ExtractReturn<typeof createRelativeRequest>
  | ExtractReturn<typeof createRelativeSuccess>
  | DeleteRelativeRequestActionFlowType
  | ExtractReturn<typeof deleteRelativeSuccess>
  | ExtractReturn<typeof fetchPatientPartnersRequest>
  | ExtractReturn<typeof fetchPatientPartnersSuccess>;

export const actions = {
  signupPatientRequest,
  signupPatientSuccess,
  signupPatientError,
  loginPatientRequest,
  loginPatientError,
  bookAppointmentRequest,
  getProfileRequest,
  updateProfileRequest,
  changePatientPasswordRequest,
  fetchRelativesRequest,
  fetchRelativesSuccess,
  createRelativeRequest,
  createRelativeSuccess,
  deleteRelativeRequest,
  deleteRelativeSuccess,
  fetchPatientPartnersRequest,
  fetchPatientPartnersSuccess,
};
