/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from 'react-google-maps';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';

class MapWithASearchBox extends React.Component {
  constructor(props) {
    super(props);

    const { defaultCoords } = props;
    const center =
      defaultCoords.latitude && defaultCoords.longitude
        ? {
            lat: defaultCoords.latitude,
            lng: defaultCoords.longitude,
          }
        : {
            // london
            lat: 51.5073509,
            lng: -0.12775829999998223,
          };

    this.state = {
      center,
      marker: center,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { defaultCoords } = nextProps;
    if (defaultCoords.latitude && defaultCoords.longitude) {
      const center = {
        lat: defaultCoords.latitude * 1,
        lng: defaultCoords.longitude * 1,
      };

      this.setState({
        center,
        marker: center,
      });
    }
  }

  onMapMounted = ref => {
    this.map = ref;
  };

  onBoundsChanged = () => {
    this.setState({
      center: this.map.getCenter(),
    });
  };

  onSearchBoxMounted = ref => {
    this.searchBox = ref;
  };

  onPlacesChanged = () => {
    const places = this.searchBox.getPlaces();
    const bounds = new google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map(place => place.geometry.location);
    const nextCenter = nextMarkers[0] || this.state.center;
    this.props.onCoordsChange(nextCenter);
    this.fetchLocationDetail(nextCenter);
  };

  fetchLocationDetail = latLng => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (data, status) => {
      if (status === google.maps.GeocoderStatus.OK && data.length) {
        let town;
        town = data[0].address_components.find(item => item.types.indexOf('locality') !== -1);
        if (!town) {
          town = data[0].address_components.find(item => item.types.indexOf('administrative_area_level_1') !== -1);
        }

        let borough;
        for (let i = 0; i < data.length; i += 1) {
          borough = data[i].address_components.find(item => item.types.indexOf('administrative_area_level_2') !== -1);
          // we want more precise borough for london
          if (borough && borough.long_name === 'Greater London') {
            borough = data[i].address_components.find(item => item.types.indexOf('administrative_area_level_3') !== -1);
          }
          if (borough) break;
        }

        this.props.onLocationChange({
          town: town ? town.long_name : '',
          borough: borough
            ? borough.long_name
                .replace('London Borough of ', '')
                .replace('The City of ', '')
                .replace('Borough of ', '')
            : '',
        });
      }
    });
  };

  handleMarkDragEnd = ({ latLng }) => {
    this.props.onCoordsChange(latLng);

    this.fetchLocationDetail(latLng);
  };

  render() {
    const { center, marker, draggable } = this.state;

    return (
      <GoogleMap
        ref={this.onMapMounted}
        defaultZoom={17}
        center={center}
        draggable={draggable}
        onBoundsChanged={this.onBoundsChanged}
      >
        <SearchBox
          ref={this.onSearchBoxMounted}
          bounds={this.bounds}
          controlPosition={google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={this.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search Google Maps"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '240px',
              height: '30px',
              marginTop: '10px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '12px',
              outline: 'none',
              textOverflow: 'ellipses',
            }}
          />
        </SearchBox>
        <Marker position={marker} onDragEnd={this.handleMarkDragEnd} draggable />
      </GoogleMap>
    );
  }
}

MapWithASearchBox.propTypes = {
  onCoordsChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  defaultCoords: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default MapWithASearchBox;
