// @flow

import type { PracticeState, PaymentMethods, PriceListType, LanguagesType, ExtractReturn } from 'types';

import type { AdminUserFormFlowType } from 'modules/admin/actions';
import type { AdminPracticeFlowType, AdminSpecialistFlowType } from 'modules/admin/reducer';
import type { SpecialistViewFlowType } from 'modules/specialist/reducer';

export const modalTypes = {
  LOGIN_MODAL: 'LOGIN_MODAL',
  LIST_PRACTICE_MODAL: 'LIST_PRACTICE_MODAL',
  LANGUAGE_MODAL: 'LANGUAGE_MODAL',
  PRICE_LIST_MODAL: 'PRICE_LIST_MODAL',
  REVIEW_MODAL: 'REVIEW_MODAL',
  PICTURE_UPLOAD_MODAL: 'PICTURE_UPLOAD_MODAL',
  ENQUIRE_MODAL: 'ENQUIRE_MODAL',
  PRACTICE_SELECT_MODAL: 'PRACTICE_SELECT_MODAL',
  TRANSPORTATION_MODAL: 'TRANSPORTATION_MODAL',
  SPECIALIST_FREE_EDIT_MODAL: 'SPECIALIST_FREE_EDIT_MODAL',
  PAYMENT_METHODS_MODAL: 'PAYMENT_METHODS_MODAL',
  UPDATE_PRACTICE_MODAL: 'UPDATE_PRACTICE_MODAL',
  UPDATE_SPECIALIST_MODAL: 'UPDATE_SPECIALIST_MODAL',
  UPDATE_USER_MODAL: 'UPDATE_USER_MODAL',
  CREATE_SPECIALIZATION_MODAL: 'CREATE_SPECIALIZATION_MODAL',
  SIMPLE_MODAL: 'SIMPLE_MODAL',
  SHOW_MODAL: 'SHOW_MODAL',
  MODAL_HIDE: 'MODAL_HIDE',
};

export type ModalTypesFlowType =
  | 'LOGIN_MODAL'
  | 'LIST_PRACTICE_MODAL'
  | 'LANGUAGE_MODAL'
  | 'PRICE_LIST_MODAL'
  | 'REVIEW_MODAL'
  | 'PICTURE_UPLOAD_MODAL'
  | 'ENQUIRE_MODAL'
  | 'PRACTICE_SELECT_MODAL'
  | 'SPECIALIST_FREE_EDIT_MODAL'
  | 'TRANSPORTATION_MODAL'
  | 'PAYMENT_METHODS_MODAL'
  | 'UPDATE_PRACTICE_MODAL'
  | 'UPDATE_SPECIALIST_MODAL'
  | 'UPDATE_USER_MODAL'
  | 'CREATE_SPECIALIZATION_MODAL';

const showSimpleModal = (children: React.Node) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.SIMPLE_MODAL,
    modalProps: {
      children,
    },
  },
});

const showLoginModal = () => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.LOGIN_MODAL,
    modalProps: {},
  },
});

const showListPracticeModal = () => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.LIST_PRACTICE_MODAL,
    modalProps: {},
  },
});

const showLanguageModal = (specialistId: number, languages: LanguagesType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.LANGUAGE_MODAL,
    modalProps: {
      specialistId,
      languages,
    },
  },
});

const showPriceListModal = (specialistId: number, specialistPracticeId: number, services: PriceListType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.PRICE_LIST_MODAL,
    modalProps: {
      specialistId,
      specialistPracticeId,
      services,
    },
  },
});

const showReviewModal = (specialistId: number) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.REVIEW_MODAL,
    modalProps: {
      specialistId,
    },
  },
});

const showPictureUploadModal = (userId: number, imageUrl: string, onSave: () => void, isSpecialist: boolean) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.PICTURE_UPLOAD_MODAL,
    modalProps: {
      userId,
      imageUrl,
      onSave,
      isSpecialist,
    },
  },
});

const showEnquireModal = (profileType: string, profileTypeId: number, profileTypeName: string) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.ENQUIRE_MODAL,
    modalProps: {
      profileType,
      profileTypeId,
      profileTypeName,
    },
  },
});

const showPracticeSelectModal = (practices: Array<PracticeState>, selectedPracticeId: number) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.PRACTICE_SELECT_MODAL,
    modalProps: {
      practices,
      selectedPracticeId,
    },
  },
});

const showTransportationModal = (practiceId: number, bus: string, underground: string) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.TRANSPORTATION_MODAL,
    modalProps: {
      practiceId,
      bus,
      underground,
    },
  },
});

const showSpecialistFreeModal = (specialist: SpecialistViewFlowType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.SPECIALIST_FREE_EDIT_MODAL,
    modalProps: {
      specialist,
    },
  },
});

const showPaymentMethodsModal = (practiceId: number, paymentMethods: PaymentMethods) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.PAYMENT_METHODS_MODAL,
    modalProps: {
      practiceId,
      paymentMethods,
    },
  },
});

const showUpdatePracticeModal = (practiceRow: AdminPracticeFlowType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.UPDATE_PRACTICE_MODAL,
    modalProps: {
      practiceRow,
    },
  },
});

const showUpdateSpecialistModal = (specialistRow: AdminSpecialistFlowType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.UPDATE_SPECIALIST_MODAL,
    modalProps: {
      specialistRow,
    },
  },
});

const showUpdateUserModal = (userRow: AdminUserFormFlowType) => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.UPDATE_USER_MODAL,
    modalProps: {
      userRow,
    },
  },
});

const showCreateSpecializationModal = () => ({
  type: modalTypes.SHOW_MODAL,
  payload: {
    modalType: modalTypes.CREATE_SPECIALIZATION_MODAL,
    modalProps: {},
  },
});

const hideModal = (modalType: ?ModalTypesFlowType) => ({
  type: modalTypes.MODAL_HIDE,
  payload: {
    modalType,
  },
});

export type ShowLoginModalAction = ExtractReturn<typeof showLoginModal>;
export type ShowListPracticeModal = ExtractReturn<typeof showListPracticeModal>;
export type ShowLanguageModal = ExtractReturn<typeof showLanguageModal>;
export type ShowPriceListModal = ExtractReturn<typeof showPriceListModal>;
export type ShowReviewModal = ExtractReturn<typeof showReviewModal>;
export type ShowPictureUploadModal = ExtractReturn<typeof showPictureUploadModal>;
export type ShowEnquireModal = ExtractReturn<typeof showEnquireModal>;
export type ShowPracticeSelectModal = ExtractReturn<typeof showPracticeSelectModal>;
export type ShowTransportationModal = ExtractReturn<typeof showTransportationModal>;
export type ShowSpecialistFreeModal = ExtractReturn<typeof showSpecialistFreeModal>;
export type ShowPaymentMethodsModal = ExtractReturn<typeof showPaymentMethodsModal>;
export type ShowUpdatePracticeModal = ExtractReturn<typeof showUpdatePracticeModal>;
export type ShowUpdateSpecialistModal = ExtractReturn<typeof showUpdateSpecialistModal>;
export type ShowUpdateUserModal = ExtractReturn<typeof showUpdateUserModal>;
export type ShowCreateSpecializationModal = ExtractReturn<typeof showCreateSpecializationModal>;
export type ShowSimpleModal = ExtractReturn<typeof showSimpleModal>;
export type HideModalAction = ExtractReturn<typeof hideModal>;

export type ModalActionFlowType =
  | ShowLoginModalAction
  | ShowListPracticeModal
  | ShowLanguageModal
  | ShowPriceListModal
  | ShowReviewModal
  | ShowPictureUploadModal
  | ShowEnquireModal
  | ShowPracticeSelectModal
  | ShowTransportationModal
  | ShowSpecialistFreeModal
  | ShowPaymentMethodsModal
  | ShowUpdatePracticeModal
  | ShowUpdateSpecialistModal
  | ShowUpdateUserModal
  | ShowCreateSpecializationModal
  | ShowSimpleModal
  | HideModalAction;

export const modalActions = {
  showLoginModal,
  showListPracticeModal,
  showLanguageModal,
  showPriceListModal,
  showReviewModal,
  showPictureUploadModal,
  showEnquireModal,
  showPracticeSelectModal,
  showTransportationModal,
  showSpecialistFreeModal,
  showPaymentMethodsModal,
  showUpdatePracticeModal,
  showUpdateSpecialistModal,
  showUpdateUserModal,
  showCreateSpecializationModal,
  showSimpleModal,
  hideModal,
};
