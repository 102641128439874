import { types } from './actions';

const initialAppState = {
  count: 0,
  specialistCount: 0,
  practiceCount: 0,
  pages: 0,
  pageSize: 10,
  searchResults: {
    specialists: [],
    practices: [],
  },
  languages: [],
  genders: [],
};

export default (appState = initialAppState, { type, payload }) => {
  switch (type) {
    case types.SEARCH_COUNT_FETCHED:
      return {
        ...appState,
        count: payload.practiceCount + payload.specialistCount,
        specialistCount: payload.specialistCount,
        practiceCount: payload.practiceCount,
        pages: Math.ceil((payload.practiceCount + payload.specialistCount) / appState.pageSize),
      };

    case types.RESET_FETCHING_STATE:
      return {
        ...appState,
        searchResultsFetched: false,
      };

    case types.SEARCH_RESULT_FETCHED:
      return {
        ...appState,
        searchResultsFetched: true,
        searchResults: payload,
      };

    case types.LANGUAGES_FETCHED:
      return {
        ...appState,
        languages: payload,
      };

    case types.GENDERS_FETCHED:
      return {
        ...appState,
        genders: payload,
      };

    case types.SEARCH_RESULT_DETAILS_FETCHED:
      return {
        ...appState,
        searchResultDetails: payload,
      };

    default:
      return appState;
  }
};

export const selectors = {
  getSearchResults: state => state.searchResults,
};
