// @flow

import type { ExtractReturn } from 'types';
import type { SpecializationsFlowType } from './reducer';

export const types = {
  FETCH_SPECIALIZATIONS_REQUEST: 'FETCH_SPECIALIZATIONS_REQUEST',
  FETCH_SPECIALIZATIONS_SUCCESS: 'FETCH_SPECIALIZATIONS_SUCCESS',
  CREATE_SPECIALIZATION_REQUEST: 'CREATE_SPECIALIZATION_REQUEST',
  CREATE_SPECIALIZATION_SUCCESS: 'CREATE_SPECIALIZATION_SUCCESS',
  CREATE_SPECIALIZATION_ERROR: 'CREATE_SPECIALIZATION_ERROR',
  SEARCH_SPECIALIZATION_REQUEST: 'SEARCH_SPECIALIZATION_REQUEST',
  SEARCH_SPECIALIZATION_SUCCESS: 'SEARCH_SPECIALIZATION_SUCCESS',
};

export type CreateSpecializationFormFlowType = {|
  specialization: string,
|};

const fetchSpecializationsRequest = () => ({
  type: types.FETCH_SPECIALIZATIONS_REQUEST,
});

const fetchSpecializationsSuccess = (specializations: SpecializationsFlowType) => ({
  type: types.FETCH_SPECIALIZATIONS_SUCCESS,
  payload: specializations,
});

// $FlowFixMe
const searchSpecializations = (key, resolve) => ({
  type: types.SEARCH_SPECIALIZATION_REQUEST,
  key,
  resolve,
});

const createSpecializationRequest = (formObj: CreateSpecializationFormFlowType) => ({
  type: types.CREATE_SPECIALIZATION_REQUEST,
  payload: formObj,
});

const createSpecializationSuccess = (specialization: string) => ({
  type: types.CREATE_SPECIALIZATION_SUCCESS,
  payload: specialization,
});

export type FetchSpecializationsRequestActionFlowType = ExtractReturn<typeof fetchSpecializationsRequest>;
export type FetchSpecializationsSuccessActionFlowType = ExtractReturn<typeof fetchSpecializationsSuccess>;
export type CreateSpecializationRequestActionFlowType = ExtractReturn<typeof createSpecializationRequest>;
export type CreateSpecializationSuccessActionFlowType = ExtractReturn<typeof createSpecializationSuccess>;

export type SpecializationActionsFlowType =
  | FetchSpecializationsRequestActionFlowType
  | FetchSpecializationsSuccessActionFlowType
  | CreateSpecializationRequestActionFlowType
  | CreateSpecializationSuccessActionFlowType;

export const actions = {
  fetchSpecializationsRequest,
  fetchSpecializationsSuccess,
  searchSpecializations,
  createSpecializationRequest,
  createSpecializationSuccess,
};
