import { takeLatest, call, put } from 'redux-saga/effects';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as commonActions } from 'modules/common/actions';
import { modalActions } from 'modules/modal/actions';
import { actions, types } from 'modules/admin/actions';
import SpecialistTitleHelper from './specialist.title.helper';

export function* getSpecialistsRequest({ page, pageSize, sorted, filtered }) {
  yield put(commonActions.resetSpinner());
  if (!filtered.length && !sorted) {
    yield put(commonActions.showSpinner());
  }

  try {
    const {
      data: { count },
    } = yield call(api.get, 'specialists/count');

    const filter = {
      skip: page > 0 ? page * pageSize : 0,
      limit: pageSize,
    };
    if (sorted) {
      filter.order = `${sorted.id} ${sorted.desc ? 'DESC' : 'ASC'}`;
    }

    if (filtered && filtered.length) {
      filter.where = {};
      filtered.forEach(item => {
        filter.where[item.id] = `${encodeURIComponent(item.value)}%25`;
      });
    }

    const response = yield call(api.get, `specialists/adminSearch?filter=${JSON.stringify(filter)}`);
    yield put({
      type: types.ADMIN_SPECIALIST_GET_SUCCESS,
      payload: {
        rows: response.data,
        pages: Math.ceil(count / pageSize),
      },
    });
  } catch (error) {
    logException(error, `getSpecialistsRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* getSpecialistDetailRequest({ row }) {
  yield put(commonActions.showSpinner());

  try {
    const filter = {
      fields: { id: true },
      include: [
        {
          relation: 'specialistPractice',
          scope: {
            order: ['practicePosition ASC', 'id ASC'],
          },
        },
        {
          relation: 'practices',
          scope: {
            fields: ['name'],
            where: {
              level: { gt: -1 },
            },
          },
        },
      ],
      where: {
        id: row.id,
        level: { gt: -1 },
      },
    };

    const res = yield call(api.get, `specialists?filter=${JSON.stringify(filter)}`);
    const { specialistPractice, practices } = res.data[0];

    // attach practice name
    practices.forEach(practice => {
      const detail = specialistPractice.find(item => item.practiceId === practice.id);
      if (detail) {
        detail.name = practice.name;
      }
    });
    yield put(actions.getSpecialistDetailSuccess({ ...row, specialistPractice }));
  } catch (error) {
    logException(error, `getSpecialistsRequest failed ${error}`);
  }

  yield put(commonActions.hideSpinner());
}

export function* createSpecialistRequest({ formObj, successCallback }) {
  try {
    yield call(api.post, 'specialists/createSpecialist', {
      ...formObj,
      profilePicture: false,
      rating: 0,
      votes: 0,
      profile: '',
    });

    yield call(successCallback);
    yield put(commonActions.toastSuccess(i18n._(t`Specialist has been successfully created.`)));
  } catch (error) {
    logException(error, `createSpecialistRequest failed ${error}`);
    yield put(
      commonActions.toastDanger(
        (error.response.data &&
          ((error.response.data.error.details && error.response.data.error.details.messages.email[0]) ||
            error.response.data.error)) ||
          i18n._(t`Sorry, creating a new specialist has failed.`)
      )
    );
  }
}

export function* updateSpecialistRequest({ formObj }) {
  try {
    if (formObj && formObj.title === SpecialistTitleHelper.getDefaultTitle(formObj)) {
      // eslint-disable-next-line no-param-reassign
      delete formObj.title;
    }

    const res = yield call(api.patch, `specialists/${formObj.id}/updateSpecialist`, formObj);
    yield put(modalActions.hideModal());

    // update specialist
    yield put({
      type: types.ADMIN_SPECIALIST_UPDATE_SUCCESS,
      payload: {
        ...res.data,
        'user.email': formObj['user.email'],
        title: formObj.title || '',
      },
    });
    yield put(commonActions.toastSuccess(i18n._(t`Specialist has been successfully updated.`)));
  } catch (error) {
    logException(error, `update specialist request failed ${error}`);
    yield put(
      commonActions.toastDanger(
        (error.response.data &&
          ((error.response.data.error.details && error.response.data.error.details.messages.email[0]) ||
            error.response.data.error)) ||
          i18n._(t`Sorry, updating a new specialist has failed.`)
      )
    );
  }
}

export function* searchSpecialistUsersRequest({ key, resolve }) {
  try {
    const filter = {
      fields: { id: true, email: true },
      where: {
        and: [{ email: { like: `${encodeURIComponent(key)}%25` } }],
      },
    };
    const { data } = yield call(api.get, `users?filter=${JSON.stringify(filter)}`);
    const options = data.map(item => ({
      value: item.id,
      label: item.email,
    }));

    resolve({ options });
  } catch (error) {
    logException(error, `searchUsersRequest failed ${error}`);
  }
}

export function* searchSpecialistPracticesRequest({ key, resolve }) {
  // doesn't fetch if key is empty
  if (!key) {
    resolve({ options: [] });
    return;
  }

  try {
    const filter = {
      fields: { id: true, name: true },
      where: {
        and: [{ name: { like: `${encodeURIComponent(key)}%25` } }],
      },
    };
    const { data } = yield call(api.get, `practices?filter=${JSON.stringify(filter)}`);
    const options = data.map(item => ({
      value: item.id,
      label: item.name,
    }));

    resolve({ options });
  } catch (error) {
    logException(error, `searchUsersRequest failed ${error}`);
  }
}

// Saga Helper
export default function* watchAdminSpecialistActionRequests() {
  yield takeLatest(types.ADMIN_SPECIALIST_GET_REQUEST, getSpecialistsRequest);
  yield takeLatest(types.ADMIN_SPECIALIST_GET_DETAIL_REQUEST, getSpecialistDetailRequest);
  yield takeLatest(types.ADMIN_SPECIALIST_CREATE_REQUEST, createSpecialistRequest);
  yield takeLatest(types.ADMIN_SPECIALIST_UPDATE_REQUEST, updateSpecialistRequest);
  yield takeLatest(`${types.ADMIN_SPECIALIST_SEARCH_USERS}_REQUEST`, searchSpecialistUsersRequest);
  yield takeLatest(`${types.ADMIN_SPECIALIST_SEARCH_PRACTICES}_REQUEST`, searchSpecialistPracticesRequest);
}
