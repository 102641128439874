// @flow

import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import type { ExtractReturn } from 'types';

export const types = {
  VISITOR_SEND_FORM: 'VISITOR_SEND_FORM',
};

const sendForm = (
  formObj: { [string]: string | number },
  formId: string,
  successCallback: () => void,
  successMsg: string = i18n._(t`Thank you for your enquiry. We will contact you soon.`)
) => ({
  type: `${types.VISITOR_SEND_FORM}_REQUEST`,
  formObj,
  formId,
  successMsg,
  successCallback,
});

const registerPractice = (formObj: { [string]: string | number }, successCallback: () => void) =>
  sendForm(
    { ...formObj, emailName: 'register-practice' },
    'listPractice',
    successCallback,
    i18n._(t`Thank you for submitting a new practice. We will contact you soon.`)
  );

const sendContact = (formObj: { [string]: string | number }, successCallback: () => void) =>
  sendForm(
    {
      ...formObj,
      emailName: 'contact-us',
    },
    'contactusForm',
    successCallback
  );

const freeProfileChange = (formObj: { [string]: string | number }, successCallback: () => void) =>
  sendForm(
    {
      ...formObj,
      emailName: 'specialist-firstlevel',
    },
    'freeProfileForm',
    successCallback
  );

const submitEnquiry = (formObj: { [string]: string | number }, successCallback: () => void) =>
  sendForm({ ...formObj, emailName: 'appointment' }, 'appointment', successCallback, i18n._(t`Your enquiry was sent.`));

export type RegisterPracticeActionFlowType = ExtractReturn<typeof registerPractice>;
export type SendContactActionFlowType = ExtractReturn<typeof sendContact>;
export type FreeProfileChangeFlowType = ExtractReturn<typeof freeProfileChange>;
export type SubmitEnquiryActionFlowType = ExtractReturn<typeof submitEnquiry>;

export type VisitorActionsFlowType =
  | RegisterPracticeActionFlowType
  | SendContactActionFlowType
  | FreeProfileChangeFlowType;

export const actions = {
  sendForm,
  registerPractice,
  freeProfileChange,
  sendContact,
  submitEnquiry,
};
