export const types = {
  SEARCH_FETCH_SPECIALISTS: 'SEARCH_FETCH_SPECIALISTS',
  SEARCH_FETCH_PRACTICES: 'SEARCH_FETCH_PRACTICES',
  RESET_SEARCH: 'RESET_SEARCH',
  RESET_FETCHING_STATE: 'RESET_FETCHING_STATE',
  SET_PRACTICE: 'SET_PRACTICE',
  SET_LOCATION: 'SET_LOCATION',
  SET_SPECIALIZATION: 'SET_SPECIALIZATION',
  CHANGE_DOCTOR_OR_PRACTICE: 'CHANGE_DOCTOR_OR_PRACTICE',
  CHANGE_NAME_FOCUS: 'CHANGE_NAME_FOCUS',
};

const fetchSpecialists = name => ({
  type: `${types.SEARCH_FETCH_SPECIALISTS}_REQUEST`,
  name,
});

const fetchPractices = name => ({
  type: `${types.SEARCH_FETCH_PRACTICES}_REQUEST`,
  name,
});

const resetSearch = () => ({
  type: types.RESET_SEARCH,
  payload: [],
});

const resetFetchingState = () => ({
  type: types.RESET_FETCHING_STATE,
});

const setPractice = (id, name) => ({
  type: types.SET_PRACTICE,
  payload: { id, name },
});

const setSpecialization = name => ({
  type: types.SET_SPECIALIZATION,
  payload: name,
});

const setLocation = value => ({
  type: types.SET_LOCATION,
  payload: value,
});

const changeDoctorOrPractice = value => ({
  type: types.CHANGE_DOCTOR_OR_PRACTICE,
  payload: value,
});

const changeNameFocus = value => ({
  type: types.CHANGE_NAME_FOCUS,
  payload: value,
});

export const actions = {
  fetchSpecialists,
  fetchPractices,
  resetSearch,
  resetFetchingState,
  setPractice,
  setSpecialization,
  setLocation,
  changeDoctorOrPractice,
  changeNameFocus,
};
