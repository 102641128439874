// @flow

import type { ExtractReturn } from 'types';

export const types = {
  BLOG_DETAIL_FETCH: 'BLOG_DETAIL_FETCH',
  BLOG_DETAIL_POST_COMMENT: 'BLOG_DETAIL_POST_COMMENT',
};

type CommentFormFlowType = {
  email: string,
  name: string,
  content: string,
};

const getBlogDetail = (slug: string) => ({
  type: `${types.BLOG_DETAIL_FETCH}_REQUEST`,
  slug,
});

const postComment = (postId: number, parentId: number, formObj: CommentFormFlowType) => ({
  type: `${types.BLOG_DETAIL_POST_COMMENT}_REQUEST`,
  postId,
  parentId,
  formObj,
});

export type GetBlogDetailActionFlowType = ExtractReturn<typeof getBlogDetail>;
export type PostCommentActionFlowType = ExtractReturn<typeof postComment>;

export type VisitorBlogDetailViewActionsFlowType = GetBlogDetailActionFlowType | PostCommentActionFlowType;

export const actions = {
  getBlogDetail,
  postComment,
};
