// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { replace } from 'react-router-redux';
import Raven from 'raven-js';
import { FORM_ERROR } from 'final-form';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { actions as patientActions, types } from 'modules/patient/actions';
import type { SignupPatientRequestAction, LoginPatientRequestAction } from 'modules/patient/actions';
import { actions as userActions, types as userTypes, roles } from 'modules/user/actions';
import { actions as commonActions } from 'modules/common/actions';

const USER_TOKEN_TTL_DAYS = 30;
// token ttl is in seconds, conversion from days to the seconds
const DEFAULT_USER_TOKEN_TTL = USER_TOKEN_TTL_DAYS * 24 * 60 * 60;

export function* signupPatientRequest(action: SignupPatientRequestAction): Saga<void> {
  try {
    yield call(api.post, 'patients', action.payload);
    yield put(patientActions.signupPatientSuccess({}));
    yield put(
      commonActions.toastSuccess(i18n._(t`We have sent you email with confirmation link to finish your registration.`))
    );
  } catch (err) {
    const response = err.response || {};
    const error = response.data ? response.data.error : {};
    if (error.details && error.details.codes) {
      yield put(patientActions.signupPatientSuccess(error.details.messages));
    } else {
      yield put(
        patientActions.signupPatientError({
          [FORM_ERROR]: i18n._(t`Patient registration has failed. Please contact our support.`),
        })
      );
      if (window.Smooch) {
        window.Smooch.open();
      }
      logException(response, `registerPatientRequest failed ${JSON.stringify(error)}`);
    }
  }
}

export function* loginPatientRequest({ payload }: LoginPatientRequestAction): Saga<void> {
  try {
    const { email, password } = payload;
    const body = {
      email,
      password,
    };

    const response = yield call(api.post, 'patients/login?include=user', body);
    if (!response || !response.data || response.status !== 200) {
      yield put({ type: userTypes.LOGOUT_USER_SUCCESS });
      return;
    }

    const {
      data: { id, userId, user, created },
    } = response;
    if (!id || !userId) {
      yield put({ type: userTypes.LOGOUT_USER_SUCCESS });
      return;
    }

    // save initial info
    yield put(
      userActions.loginUserSuccess({
        token: id,
        userId,
        tokenTtl: DEFAULT_USER_TOKEN_TTL,
        tokenCreatedAt: created,
        isAdminRole: false,
        userName: email,
        role: roles.PATIENT,
        userUrl: '/patient/info',
        hasCalendar: false,
        email: user.email,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        birthName: user.birthName,
        dateOfBirth: user.dateOfBirth,
      })
    );

    yield put(replace('/patient/info'));

    Raven.setUserContext({
      id: userId,
      email,
    });
  } catch (error) {
    const { response } = error;
    if (response && response.data.error.message) {
      yield put(patientActions.loginPatientError(response.data.error.message));
    } else {
      yield put(patientActions.loginPatientError(i18n._(t`Login has failed`)));
    }
    logException(response, `loginPatientRequest failed ${response}`);
  }
}

// Saga Helper
export default function* watchPatientActionRequests(): Saga<void> {
  yield takeLatest(types.PATIENT_SIGNUP_REQUEST, signupPatientRequest);
  yield takeLatest(types.PATIENT_LOGIN_REQUEST, loginPatientRequest);
}
