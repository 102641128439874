// @flow

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createResponsiveStateReducer } from 'redux-responsive';
import { routerReducer } from 'react-router-redux';

import lastActionReducer from './reducers/lastActionReducer';
import * as Reducers from './reducers';

const rootReducer = (initialMediaType: ?string = 'fullhdBreakpoint') =>
  combineReducers({
    browser: createResponsiveStateReducer(
      {
        belowMobileBreakpoint: 767,
        mobileBreakpoint: 768,
        aboveMobileBreakpoint: 769,
        belowTabletBreakpoint: 1023,
        tabletBreakpoint: 1024,
        aboveTabletBreakpoint: 1025,
        desktopBreakpoint: 1280,
        fullhdBreakpoint: 1408,
      },
      { initialMediaType }
    ),
    ...Reducers,
    form: formReducer,
    router: routerReducer,
    lastAction: lastActionReducer,
  });

export default rootReducer;
