// @flow

import type { ReduxStateFlowType } from 'types';

import { types } from './actions';
import type { CommonActionsFlowType } from './actions';

type SpinnersFlowType = Array<string>;
export type CommonStoreFlowType = {|
  toast: $Shape<{|
    visible: boolean,
    level: string,
  |}>,
  spinners: SpinnersFlowType,
  status: number,
|};

export const initialState = {
  toast: {},
  spinners: [],
  status: 200,
};

export default (state: CommonStoreFlowType = initialState, action: CommonActionsFlowType) => {
  switch (action.type) {
    case types.TOAST_SHOW:
      return {
        ...state,
        toast: {
          ...action.payload,
          visible: true,
        },
      };

    case types.API_RESETED: {
      return {
        ...state,
        spinners: [],
      };
    }

    case types.API_STARTED: {
      return {
        ...state,
        spinners: [...state.spinners, action.payload],
      };
    }

    case types.API_FINISHED: {
      const temp: SpinnersFlowType = state.spinners.splice(0);
      const index = temp.indexOf(action.payload);
      if (index > -1) temp.splice(index, 1);

      return {
        ...state,
        spinners: temp,
      };
    }

    case types.STATUS_CHANGE:
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
};

export const selectors = {
  getToast: (state: ReduxStateFlowType) => state.common.toast,
  getToastLevel: (state: ReduxStateFlowType) => state.common.toast.level,
};
