import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';

import { types as toastTypes } from 'modules/common/actions';

class ToastPanel extends React.PureComponent {
  componentWillReceiveProps(newProps) {
    if (!newProps.toast) return;
    const { content, level } = newProps.toast;
    if (level === toastTypes.TOAST_SUCCESS) {
      toast.success(content);
    } else if (level === toastTypes.TOAST_WARNING) {
      toast.warn(content);
    } else if (level === toastTypes.TOAST_ERROR) {
      toast.error(`${content} ${i18n._(t`Please refresh the page and repeat the action or contact the support.`)}`);
    }
  }

  render() {
    return <ToastContainer position="bottom-right" autoClose={8000} closeOnClick />;
  }
}

export default ToastPanel;
