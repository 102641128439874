// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import type { AdminPracticeFlowType } from 'modules/admin/reducer';
import { AdminPracticePropType } from 'modules/admin/reducer';
import ModalCard from 'components/modalCard';
import type { GetPracticeDetailRequestActionFlowType } from 'modules/admin/actions';
import { actions } from 'modules/admin/actions';
import PracticeForm from '../practiceForm';

type Props = {
  practiceRow: AdminPracticeFlowType,
  dispatch: Dispatch<GetPracticeDetailRequestActionFlowType>,
};

class UpdatePracticeModal extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch(actions.getPracticeDetailRequest(this.props.practiceRow));
  }

  render() {
    return (
      <ModalCard title={`Edit practice ${this.props.practiceRow.name}`}>
        <PracticeForm onSubmit={actions.updatePracticeRequest} />
      </ModalCard>
    );
  }
}

UpdatePracticeModal.propTypes = {
  practiceRow: AdminPracticePropType,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(UpdatePracticeModal);
