// @flow

import { call, select, take, takeLatest, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { reset as resetForm } from 'redux-form';
import { push } from 'react-router-redux';
import axios from 'axios';
import { t } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import * as api from 'client/api/api';
import logException from 'utils/logException';
import { getAppointment } from 'utils/storage';
import { actions as commonActions } from 'modules/common/actions';
import { actions as patientActions, types as patientTypes } from 'modules/patient/actions';
import type { BookAppointmentRequestAction } from 'modules/patient/actions';
import { selectors as userSelectors } from 'modules/user/reducer';
import { selectors as patientSelectors } from 'modules/patient/reducer';

type ClientInfo = {
  firstName: string,
  lastName: string,
  birthName?: string,
  email?: string,
  gender: string,
  dateOfBirth: string,
  partnerPatientId?: number,
};

export function* bookAppointmentRequest({ payload }: BookAppointmentRequestAction): Saga<void> {
  try {
    const appointment = getAppointment();
    const { practice } = appointment;

    let relativeId;
    if (payload.person === 'relative' && payload.relative) {
      relativeId = Number(payload.relative);
    }

    if (appointment && appointment.timeslot) {
      const patient = yield select(userSelectors.getUser);

      let patientPractice;
      let relative;
      if (payload.person === 'you' || relativeId) {
        yield put(patientActions.fetchPatientPartnersRequest());
        yield take(patientTypes.PATIENT_FETCH_PATIENT_PRACTICES_SUCCESS);
        if (payload.person === 'you') {
          if (practice.apiType && practice.id) {
            patientPractice = yield select(
              patientSelectors.getPatientPracticePatient,
              patient.userId,
              practice.apiType,
              practice.id
            );
          }
        }
        if (relativeId) {
          yield put(patientActions.fetchRelativesRequest());
          yield take(patientTypes.PATIENT_FETCH_RELATIVES_SUCCESS);
          relative = yield select(patientSelectors.getRelative, relativeId);
          if (practice.apiType && practice.id) {
            patientPractice = yield select(
              patientSelectors.getPatientPracticeRelative,
              relativeId,
              practice.apiType,
              practice.id
            );
          }
        }
      }

      let clientInfo: ClientInfo;
      if (payload.person === 'relative') {
        if (payload.relative && relative) {
          clientInfo = {
            ...relative,
          };
        } else {
          const response = yield call(api.post, `patients/${patient.userId}/relatives`, payload);
          relativeId = response.data.id;
          clientInfo = {
            ...payload,
          };
        }
      } else {
        clientInfo = {
          firstName: patient.firstName,
          lastName: patient.lastName,
          birthName: patient.birthName,
          gender: patient.gender,
          dateOfBirth: patient.dateOfBirth,
        };
      }

      if (clientInfo) {
        clientInfo = {
          ...clientInfo,
          email: patient.email,
          ...(patientPractice &&
            patientPractice.partnerPatientId && { partnerPatientId: patientPractice.partnerPatientId }),
        };
      }

      // call clicrdv api
      const body = {
        appointment: {
          date: appointment.timeslot.date,
          calendarId: appointment.timeslot.clicrdvCalendarId,
          reasonId: appointment.interventionId,
          client: {
            ...clientInfo,
          },
        },
      };
      if (practice.apiType && practice.clicrdvGroupId) {
        const { data } = yield call(
          axios.post,
          `/partners/${practice.apiType}/groups/${practice.clicrdvGroupId}/appointments`,
          body
        );

        if (data.partnerPatientId && clientInfo && !clientInfo.partnerPatientId) {
          if (payload.person === 'you') {
            yield call(api.post, `patients/${patient.userId}/patientPractices`, {
              partnerName: practice.apiType,
              partnerPatientId: data.partnerPatientId,
              practiceId: practice.id,
            });
          } else if (payload.person === 'relative') {
            yield call(api.post, `patients/${patient.userId}/patientPractices`, {
              partnerName: practice.apiType,
              partnerPatientId: data.partnerPatientId,
              practiceId: practice.id,
              relativeId,
            });
          }
        }
      }

      yield put(push('/patient/confirm'));
      // deleteAppointment();
    } else {
      yield put(commonActions.toastDanger(i18n._(t`Sorry, you have to choose a timeslot.`)));
      yield put(push('/'));
      return;
    }

    yield put(resetForm('patientInfoForm'));
    yield put(commonActions.toastSuccess(i18n._(t`Your appointment has been successfully booked`)));
  } catch (error) {
    yield put(commonActions.toastDanger(i18n._(t`Sorry, something went wrong.`)));
    logException(error, `fetch practice request failed ${error}`);
  }
}

export default function* watchPatientActionRequests(): Saga<void> {
  yield takeLatest(patientTypes.PATIENT_BOOK_APPOINTMENT_REQUEST, bookAppointmentRequest);
}
