// @flow

import capitalize from 'lodash/capitalize';

export const fromDashedLower = (str: string = ''): string => {
  const regx = new RegExp('-', 'g');
  return str.replace(regx, ' ');
};

export const toDashed = (str: string = ''): string => {
  const regx = new RegExp(' ', 'g');
  return str.toLowerCase().replace(regx, '-');
};

export const fromDashed = (str: string = ''): string => {
  const regx = new RegExp('-', 'g');
  return capitalize(str.replace(regx, ' '));
};
