import { connect } from 'react-redux';

import { selectors as specializationSelectors } from 'modules/specializations/reducer';
import { selectors as adminSelectors } from 'modules/admin/reducer';
import SpecialistForm from './SpecialistForm';

const mapStateToProps = state => ({
  specializations: specializationSelectors.getSpecializations(state),
  specialistDetail: adminSelectors.getSpecialistDetail(state),
});

export default connect(mapStateToProps)(SpecialistForm);
