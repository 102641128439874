import { connect } from 'react-redux';
import { selectors as specializationSelectors } from 'modules/specializations/reducer';
import { selectors as adminSelectors } from 'modules/admin/reducer';
import PracticeForm from './PracticeForm';

const mapStateToProps = state => ({
  specializations: specializationSelectors.getSpecializations(state),
  practiceDetail: adminSelectors.getPracticeDetail(state),
});

export default connect(mapStateToProps)(PracticeForm);
