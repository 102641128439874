// @flow

import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import type { Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import config from 'config';
import type { UserActions } from 'modules/user/actions';
import type { UserStoreState } from 'types';
import { actions as userActions } from 'modules/user/actions';
import { modalActions } from 'modules/modal/actions';
import SlideMenu from './SlideMenu';

import styles from './Header.scss';

type Props = {
  pathname: string,
  isLessThanMobileBreakpoint: boolean,
  dispatch: Dispatch<UserActions>,
} & UserStoreState;

type State = {
  menuOpened: boolean,
};

class Header extends PureComponent<Props, State> {
  state = {
    menuOpened: false,
  };

  onMenuToggle = () => {
    this.setState(prevState => ({
      menuOpened: !prevState.menuOpened,
    }));
  };

  onUserLogout = () => {
    this.props.dispatch(userActions.logoutUserRequest());
  };

  render() {
    const { pathname, isLessThanMobileBreakpoint, user } = this.props;

    if (!user) {
      return <div />;
    }
    const { userId, userSpecialistId, userPracticeId, userName, userUrl, isAdminRole, hasCalendar } = user;

    const isTransparent = pathname === '/';
    const isLogoShown = !pathname.startsWith('/specialists') || !isLessThanMobileBreakpoint;

    return (
      <Fragment>
        <SlideMenu />
        <nav
          className={classNames(
            styles.navbar,
            'navbar',
            {
              'is-transparent': isTransparent,
              'has-magic-gradient': !isTransparent,
            },
            'pt-15 pb-15 pl-60 pr-60'
          )}
        >
          {isLogoShown && (
            <div className={classNames('pl-25', styles.brand)}>
              <Link to="/" className={styles.logoWrapper}>
                <img className={styles.logoSvg} src="/images/logo/findoc_logo_white.svg" alt={`${config.name} Logo`} />
              </Link>
            </div>
          )}

          <div className={styles.navbarButtons}>
            {!userId &&
              !isLessThanMobileBreakpoint && (
                <div className="navbar-item ft-list-your-practice-button">
                  <button
                    className="button is-white is-outlined is-rounded has-text-weight-semibold is-list-practice"
                    onClick={() => this.props.dispatch(modalActions.showListPracticeModal())}
                  >
                    <Trans>LIST YOUR PRACTICE</Trans>
                  </button>
                </div>
              )}
            {!userId ? (
              <div
                className={classNames('dropdown', {
                  'navbar-item': !isLessThanMobileBreakpoint,
                  [styles.loginIconWrapper]: isLessThanMobileBreakpoint,
                })}
              >
                <div role="presentation" onClick={() => this.props.dispatch(modalActions.showLoginModal())}>
                  {isLessThanMobileBreakpoint ? (
                    <img alt="Login" className={styles.loginIcon} src="/images/icons/user-login.svg" />
                  ) : (
                    <button className={classNames('button is-info is-rounded has-text-weight-semibold ml-10')}>
                      <Trans>LOG IN</Trans>
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  'dropdown is-right',
                  {
                    'is-active': this.state.menuOpened,
                  },
                  styles.user
                )}
              >
                <span
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    {
                      [styles.loggedButton]: !isLessThanMobileBreakpoint,
                    },
                    styles.toggle
                  )}
                  onClick={this.onMenuToggle}
                >
                  {userPracticeId && (
                    <img
                      className={styles.profilePicture}
                      src={`/images/profile/Id${userPracticeId}-ConvertPic.png`}
                      alt=""
                    />
                  )}
                  {!userPracticeId &&
                    userSpecialistId && (
                      <img
                        className={classNames(styles.profilePicture, styles.specialistPicture)}
                        src={`/images/profile/Id${userSpecialistId}-ProfilPic.jpg`}
                        alt=""
                      />
                    )}
                  {!isLessThanMobileBreakpoint && (
                    <strong className="has-text-white pl-10">{userName || <Trans>Admin</Trans>}</strong>
                  )}
                </span>
                <ul
                  className={classNames('dropdown-menu', styles.dropdownMenu)}
                  role="menu"
                  onClick={this.onMenuToggle}
                >
                  <div className="dropdown-content">
                    {isAdminRole && (
                      <Fragment>
                        <Link to="/admin/users" className={classNames('dropdown-item', styles.dropdownItem)}>
                          <Trans>Admin area</Trans>
                        </Link>
                        <hr className="dropdown-divider" />
                      </Fragment>
                    )}
                    {!isAdminRole &&
                      userUrl && (
                        <Fragment>
                          <Link to={userUrl} className={classNames('dropdown-item', styles.dropdownItem)}>
                            <Trans>My profile</Trans>
                          </Link>
                          <hr className="dropdown-divider" />
                        </Fragment>
                      )}
                    {hasCalendar && (
                      <Fragment>
                        <Link to="/calendar" className={classNames('dropdown-item', styles.dropdownItem)}>
                          <Trans>Calendar</Trans>
                        </Link>
                        <hr className="dropdown-divider" />
                      </Fragment>
                    )}
                    <span
                      role="button"
                      tabIndex={-1}
                      className={classNames('dropdown-item', styles.dropdownItem)}
                      onClick={this.onUserLogout}
                    >
                      <Trans>Logout</Trans>
                    </span>
                  </div>
                </ul>
              </div>
            )}
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default Header;
