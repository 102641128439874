// @flow

import { compose, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware, { END } from 'redux-saga';
import createReduxPromiseListener from 'redux-promise-listener';
import type { BrowserHistory } from 'history';
import type { Store } from 'redux';

import type { ReduxStateFlowType } from 'types';
import rootReducer from 'redux/rootReducer';

import type { AdminActionsType } from 'modules/admin/actions';

// TODO add more actions
export type ReduxActionFlowType = AdminActionsType;
export type ReduxDispatchFlowType = (action: ReduxActionFlowType) => any;
export type ReduxStoreFlowType = Store<ReduxStateFlowType, ReduxActionFlowType, ReduxDispatchFlowType>;

const identity = v => v;

const getDevTools = () => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof window === 'object' && !!window.devToolsExtension) {
      return window.devToolsExtension();
    }
    return identity;
  }
  return identity;
};

Raven.config('https://a342c215de0d48e1a39ca013a744dd58@sentry.io/138953').install();
Raven.setTagsContext({
  environment: process.env.NODE_ENV,
});

if (typeof window === 'object') {
  window.onunhandledrejection = evt => {
    if (evt.data && evt.data.error) {
      Raven.captureException(evt.data.error, { level: 'warning' });
    } else {
      Raven.captureException(evt, { level: 'warning' });
    }
  };
}

export const reduxPromiseListener = createReduxPromiseListener();

export default (
  history: BrowserHistory,
  reduxState: ?ReduxStateFlowType,
  initialMediaType: ?string
): ReduxStoreFlowType => {
  const router = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();
  const store: ReduxStoreFlowType = createStore(
    rootReducer(initialMediaType),
    reduxState,
    // $FlowFixMe
    compose(
      responsiveStoreEnhancer,
      applyMiddleware(router, sagaMiddleware, reduxPromiseListener.middleware, createRavenMiddleware(Raven)),
      getDevTools()
    )
  );
  // $FlowFixMe extend store object & wasn't working
  store.runSaga = sagaMiddleware.run;
  // $FlowFixMe
  store.closeSagas = () => store.dispatch(END);

  return store;
};
