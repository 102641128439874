// @flow

import PropTypes from 'prop-types';

import type { ReduxStateFlowType, PracticeState, SpecialistState, ReviewFlowType } from 'types';
import type { AdminActionsType } from './actions';
import { types } from './actions';

export const AdminPracticePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  clicrdvEmail: PropTypes.string,
  clicrdv: PropTypes.string,
});

export type AdminPracticeFlowType = {
  id: number,
  name: string,
  email: string,
  clicrdvEmail: string,
  clicrdv: string,
};

export type AdminPracticesFlowType = {
  rows: Array<AdminPracticeFlowType>,
  pages: number,
};

export const AdminSpecialistPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export type AdminSpecialistFlowType = {
  id: number,
  firstName: string,
  lastName: string,
};

export type AdminSpecialistsFlowType = {
  rows: Array<AdminSpecialistFlowType>,
  pages: number,
};

export type AdminSpecialistDetailFlowType = $Shape<{} & SpecialistState>;

export type AdminPracticeDetailFlowType = $Shape<{} & PracticeState>;

export type AdminUserFlowType = {
  id: number,
  email: string,
  clicrdvEmail: string,
  password: string,
};

export type AdminUsersFlowType = {
  rows: Array<AdminUserFlowType>,
  pages: number,
};

export type AdminSpecialistReviewFlowType = {
  approved: boolean,
  slug: string,
  specialization: string,
  town: string,
} & ReviewFlowType;

export type AdminSpecialistReviewsFlowType = {
  rows: Array<AdminSpecialistReviewFlowType>,
  pages: number,
};

export type AdminStoreFlowType = {
  practiceDetail: AdminPracticeDetailFlowType,
  practices: AdminPracticesFlowType,
  specialistDetail: AdminSpecialistDetailFlowType,
  specialists: AdminSpecialistsFlowType,
  users: AdminUsersFlowType,
  specialistReviews: AdminSpecialistReviewsFlowType,
};

export const initialState: AdminStoreFlowType = {
  users: {
    rows: [],
    pages: 0,
  },
  specialists: {
    rows: [],
    pages: 0,
  },
  practices: {
    rows: [],
    pages: 0,
  },
  specialistReviews: {
    rows: [],
    pages: 0,
  },
  practiceDetail: {},
  specialistDetail: {},
};

export default (appState: AdminStoreFlowType = initialState, action: AdminActionsType) => {
  switch (action.type) {
    case types.ADMIN_GET_USERS_SUCCESS:
      return {
        ...appState,
        users: action.payload,
      };

    case types.ADMIN_UPDATE_USER_SUCCESS:
      return {
        ...appState,
        users: {
          ...appState.users,
          // $FlowFixMe https://github.com/facebook/flow/issues/6151
          rows: appState.users.rows.map(item => (item.id !== action.payload.id ? item : action.payload)),
        },
      };

    case types.ADMIN_SPECIALIST_GET_SUCCESS:
      return {
        ...appState,
        specialists: action.payload,
      };

    case types.ADMIN_SPECIALIST_GET_DETAIL_SUCCESS:
      return {
        ...appState,
        specialistDetail: action.payload,
      };

    case types.ADMIN_SPECIALIST_UPDATE_SUCCESS:
      return {
        ...appState,
        specialists: {
          ...appState.specialists,
          // $FlowFixMe https://github.com/facebook/flow/issues/6151
          rows: appState.specialists.rows.map(item => (item.id !== action.payload.id ? item : action.payload)),
        },
      };

    case types.ADMIN_GET_PRACTICES_SUCCESS:
      return {
        ...appState,
        practices: action.payload,
      };

    case types.ADMIN_GET_PRACTICE_DETAIL_SUCCESS:
      return {
        ...appState,
        practiceDetail: action.payload,
      };

    case types.ADMIN_UPDATE_PRACTICE_SUCCESS:
      return {
        ...appState,
        practices: {
          ...appState.practices,
          // $FlowFixMe https://github.com/facebook/flow/issues/6151
          rows: appState.practices.rows.map(item => (item.id !== action.payload.id ? item : action.payload)),
        },
      };

    case types.ADMIN_SPECIALIST_REVIEW_GET:
      return {
        ...appState,
        specialistReviews: action.payload,
      };

    case types.ADMIN_SPECIALIST_REVIEW_APPROVE_UPDATE:
      return {
        ...appState,
        specialistReviews: {
          ...appState.specialistReviews,
          // $FlowFixMe https://github.com/facebook/flow/issues/6151
          rows: appState.specialistReviews.rows.map(
            item =>
              // $FlowFixMe
              item.id === action.payload.id
                ? {
                    ...item,
                    // $FlowFixMe
                    approved: action.payload.approved,
                  }
                : item
          ),
        },
      };

    default:
      return appState;
  }
};

export const selectors = {
  getUsers: (store: ReduxStateFlowType) => store.admin.users,
  getSpecialists: (store: ReduxStateFlowType) => store.admin.specialists,
  getSpecialistDetail: (store: ReduxStateFlowType) => store.admin.specialistDetail,
  getSpecialistReviews: (store: ReduxStateFlowType) => store.admin.specialistReviews,
  getPractices: (store: ReduxStateFlowType) => store.admin.practices,
  getPracticeDetail: (store: ReduxStateFlowType) => store.admin.practiceDetail,
};
