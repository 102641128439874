import React from 'react';
import PropTypes from 'prop-types';
import { toDashed } from 'utils/string';
import { Link } from 'react-router-dom';

const ProfileLink = props =>
  props.profileType === 'specialist' ? (
    <Link
      className={props.className}
      to={`/specialist/${toDashed(props.town)}/${toDashed(props.speciality)}/${props.slug}${
        props.hash ? `#${props.hash}` : ''
      }`}
    >
      {props.children}
    </Link>
  ) : (
    <Link className={props.className} to={`/practice/${toDashed(props.town)}/${toDashed(props.borough)}/${props.slug}`}>
      {props.children}
    </Link>
  );

ProfileLink.defaultProps = {
  className: '',
  speciality: '',
  borough: '',
  hash: '',
};

ProfileLink.propTypes = {
  children: PropTypes.node.isRequired,
  profileType: PropTypes.string.isRequired,
  town: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  speciality: PropTypes.string,
  borough: PropTypes.string,
  className: PropTypes.string,
  hash: PropTypes.string,
};

export default ProfileLink;
